import { Link } from '../../Link';
import React from 'react';
import { CompletedShiftPaymentInfoLearnMoreLinkProps } from './interface';

export const CompletedShiftPaymentInfoLearnMoreLink = ({
  url,
}: CompletedShiftPaymentInfoLearnMoreLinkProps) => {
  return (
    <Link url={url} variant="text" size="m">
      Learn more.
    </Link>
  );
};
