/* eslint-disable deprecation/deprecation */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IAssignment,
  IAssignmentRequest,
  IJob,
  ILocation,
  IPayout,
  ITimecard,
  IProfessional,
  IResumeItem,
  ShiftType,
} from '@medely/types';
import moment, { MomentInput, tz } from 'moment-timezone';
// import { IconName } from 'core/components/Icon/iconNames';
import { centsToCurrency } from 'core/utils/currency';
// import renderExpirationDate from 'utils/renderExpirationDate';
import titleize from 'utils/titleize';
import { JobForIsOvernight, isOvernight } from './datetime';
import { pluralize } from 'core/utils/text';
import { JobStatusEnum as JobStatus } from '@medely/web-components/types';
import { MedelyDateTime } from '@medely/date-time-tools';

export const dateTimeLegacy = {
  date: (date: string, tz?: string, format = 'MM/DD/YY') => formatDateTime(date, format, tz),
  time: (date: string, tz?: string) => formatDateTime(date, 'HH:mm', tz),
  custom: (date: string, format: string, tz?: string) => formatDateTime(date, format, tz),
};

export const dateTime = {
  date: (date: string, tz?: string, format = 'M/D/YY') => formatDateTime(date, format, tz),
  time: (date: string, tz?: string) => formatDateTime(date, 'HH:mm', tz),
  custom: (date: string, format: string, tz?: string) => formatDateTime(date, format, tz),
};

function jobPatientPopulation(job: Partial<IJob>) {
  return titleize(job.patient_population || '–');
}

function jobSpecialties(job: Partial<IJob>) {
  return job.specialties?.map((s) => s.label).join(', ') || '–';
}

function jobProcedures(job: Partial<IJob>) {
  return job.surgery_types?.map((s) => s.display_name).join(', ') || '–';
}

function jobPositions(job: Partial<IJob>) {
  return job?.positions?.map((s) => s.display_name).join(', ') || '–';
}

const formatPhoneNumber = (str: string): string | null => {
  const cleaned = ('' + str).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

function payoutDates(payout: IPayout) {
  return payout.assignment_id === null
    ? dateTimeLegacy.date(payout.ends_time)
    : dateTimeLegacy.date(payout.starts_time) + ' - ' + dateTimeLegacy.date(payout.ends_time);
}

const cancelledStatuses = [JobStatus.Canceled, JobStatus.CanceledWithFee];

function displayTimecardStatus({
  status,
  shift_type,
}: Pick<IJob, 'status' | 'shift_type'>): string {
  if (status === JobStatus.MissedShift) {
    return 'Missed Shift';
  }
  if (cancelledStatuses.includes(status as JobStatus)) {
    return 'Cancelled by Facility';
  }
  if (shift_type === 'on_call') {
    return 'On Call Shift';
  }
  if (shift_type === 'call_back') {
    return 'Call Back Shift';
  }
  // shift_type === 'regular'
  return 'Regular Shift';
}

function timecardCustomDates(timecard: ITimecard) {
  return (
    dateTimeLegacy.custom(dateTimeLegacy.date(timecard.starts_time), 'MMMM DD') +
    ' - ' +
    dateTimeLegacy.custom(dateTimeLegacy.date(timecard.ends_time), 'MMMM DD')
  );
}

function jobDate(job: Partial<IJob>) {
  const date = job.starts_date || job.current_starts_time;
  if (!date) {
    return null;
  }
  return dateTimeLegacy.custom(date, 'ddd, MM/DD/YY', job.location?.timezone_lookup);
}

function jobFullDayDateLegacy(job: Partial<IJob>) {
  const date = job.starts_date || job.current_starts_time;
  if (!date) {
    return null;
  }
  return dateTimeLegacy.custom(date, 'dddd, MM/DD/YY', job.location?.timezone_lookup);
}

function jobFullDayDate(job: Partial<IJob>) {
  const date = job.starts_date || job.current_starts_time;
  if (!date) {
    return null;
  }
  return dateTimeLegacy.custom(date, 'dddd, M/D/YY', job.location?.timezone_lookup);
}

function jobStartTime({ current_starts_time, approved_clock_in, location }: Partial<IJob>) {
  const timezone = location?.timezone_lookup || tz.guess();
  const startsTime = approved_clock_in || current_starts_time;
  return tz(startsTime, timezone).format('HH:mm A');
}

function jobEndTime({ current_ends_time, approved_clock_out, location }: Partial<IJob>) {
  const timezone = location?.timezone_lookup || tz.guess();
  const endsTime = approved_clock_out || current_ends_time;
  return tz(endsTime, timezone).format('HH:mm A');
}

function jobDisputeScheduledTime(job: Partial<IJob>): string | null {
  const { starts_time, ends_time, location, guaranteed_minimum_hours, status } = job;
  if (!starts_time || !ends_time) {
    return null;
  }

  if (status === 'canceled_with_fee') {
    return ` ${pluralize(guaranteed_minimum_hours, 'hr')}`;
  }

  const timezone = location?.timezone_lookup || tz.guess();
  const start = tz(starts_time, timezone);
  const end = tz(ends_time, timezone);
  const hoursDiff = end.diff(start, 'hours', true).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const hours = Math.max(guaranteed_minimum_hours ?? 0, +hoursDiff);

  return ` ${pluralize(hours, 'hr')}`;
}

function jobTimeLegacy(
  job: Partial<IJob>,
  {
    withShiftLength = true,
    scheduledShiftStyle = false,
  }: { withShiftLength?: boolean; scheduledShiftStyle?: boolean } = {},
): string | null {
  const {
    current_starts_time,
    approved_clock_in,
    current_ends_time,
    approved_clock_out,
    location,
    guaranteed_minimum_hours,
    status,
  } = job;
  if (!current_starts_time || !current_ends_time) {
    return null;
  }

  const timezone = location?.timezone_lookup || tz.guess();

  const startsTime = approved_clock_in || current_starts_time;
  const endsTime = approved_clock_out || current_ends_time;

  const start = new MedelyDateTime(startsTime, {
    tz: timezone,
  });

  const end = new MedelyDateTime(endsTime, {
    tz: timezone,
  });

  const hoursDiff = start.difference(end, 'hours').toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const hours =
    status === 'canceled_with_fee'
      ? guaranteed_minimum_hours
      : Math.max(guaranteed_minimum_hours ?? 0, +hoursDiff);

  return scheduledShiftStyle
    ? `${start.militaryTime} - ${end.militaryTime}${
        withShiftLength ? ` (${pluralize(hours, 'hr')})` : ``
      }`
    : `${start.militaryTime}-${end.militaryTime}${
        withShiftLength ? ` • ${pluralize(hours, 'hr')}` : ``
      }`;
}

function jobTime(
  job: Partial<IJob>,
  {
    withShiftLength = true,
    scheduledShiftStyle = false,
  }: { withShiftLength?: boolean; scheduledShiftStyle?: boolean } = {},
): string | null {
  const format = 'HH:mm';
  const {
    current_starts_time,
    approved_clock_in,
    current_ends_time,
    approved_clock_out,
    location,
    guaranteed_minimum_hours,
    status,
  } = job;
  if (!current_starts_time || !current_ends_time) {
    return null;
  }

  const timezone = location?.timezone_lookup || tz.guess();
  const startsTime = approved_clock_in || current_starts_time;
  const endsTime = approved_clock_out || current_ends_time;
  const start = tz(startsTime, timezone);
  const end = tz(endsTime, timezone);
  const hoursDiff = end.diff(start, 'hours', true).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const hours =
    status === 'canceled_with_fee'
      ? guaranteed_minimum_hours
      : Math.max(guaranteed_minimum_hours ?? 0, +hoursDiff);

  return scheduledShiftStyle
    ? `${start.format(format)} - ${end.format(format)}${
        withShiftLength ? ` (${pluralize(hours, 'hr')})` : ``
      }`
    : `${start.format(format)}-${end.format(format)}${
        withShiftLength ? ` (${pluralize(hours, 'hr')})` : ``
      }`;
}

function jobHours({ current_starts_time, current_ends_time }: IJob | Partial<IJob>): string {
  const start = moment(current_starts_time);
  const end = moment(current_ends_time);
  return (
    end.diff(start, 'hours', true).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }) + ' hrs'
  );
}

function jobBillingHours({ job_billing_sums }: IJob | Partial<IJob>): string {
  return job_billing_sums?.total_hours
    ? `${pluralize(job_billing_sums?.total_hours, 'hr')}`
    : `0 hrs`;
}

export type JobBillingBreakdown = { label: string; description: string; total: string };
export type JobForJobBillingBreakdown = Pick<
  IJob,
  | 'is_w2'
  | 'job_billing_sums'
  | 'payout_base_hourly_rate_cents'
  | 'payout_double_overtime_multiplier'
  | 'payout_on_call_hourly_rate_cents'
  | 'payout_overtime_multiplier'
  | 'payout_taxable_hourly_rate_cents'
>;
function jobBillingBreakdown({
  is_w2,
  job_billing_sums,
  payout_base_hourly_rate_cents,
  payout_double_overtime_multiplier,
  payout_on_call_hourly_rate_cents,
  payout_overtime_multiplier,
  payout_taxable_hourly_rate_cents,
}: JobForJobBillingBreakdown): JobBillingBreakdown[] {
  const res = [];

  const baseRate = is_w2 ? payout_taxable_hourly_rate_cents : payout_base_hourly_rate_cents;
  const getDescription = (hours: number, rate: number) =>
    `${pluralize(hours, 'hour')} x ${centsToCurrency(rate)}`;

  // Only one of Regular, Oncall, or Callback should display per shift.
  if (job_billing_sums.payout_regular_amount_cents > 0) {
    res.push({
      label: 'Regular rate',
      description: getDescription(job_billing_sums.payout_regular_hours, baseRate),
      total: centsToCurrency(job_billing_sums.payout_regular_amount_cents),
    });
  } else if (job_billing_sums.payout_callback_amount_cents > 0) {
    const callbackRate = Math.floor(
      job_billing_sums.payout_callback_amount_cents / job_billing_sums.payout_callback_hours,
    );
    res.push({
      label: 'Call back rate',
      description: getDescription(job_billing_sums.payout_callback_hours, callbackRate),
      total: centsToCurrency(job_billing_sums.payout_callback_amount_cents),
    });
  } else if (job_billing_sums.total_on_call_hours > 0) {
    res.push({
      label: 'On call rate',
      description: getDescription(
        job_billing_sums.total_on_call_hours,
        payout_on_call_hourly_rate_cents,
      ),
      total: centsToCurrency(job_billing_sums.payout_total_amount_cents),
    });
  }

  if (job_billing_sums.payout_overtime_amount_cents > 0) {
    res.push({
      label: is_w2 ? 'Overtime rate' : 'Extended rate',
      description: getDescription(
        job_billing_sums.payout_overtime_hours,
        Math.floor(baseRate * payout_overtime_multiplier),
      ),
      total: centsToCurrency(job_billing_sums.payout_overtime_amount_cents),
    });
  }
  if (job_billing_sums.payout_double_amount_cents > 0) {
    res.push({
      label: is_w2 ? 'Overtime rate' : 'Extended rate',
      description: getDescription(
        job_billing_sums.payout_double_hours,
        Math.floor(baseRate * payout_double_overtime_multiplier),
      ),
      total: centsToCurrency(job_billing_sums.payout_double_amount_cents),
    });
  }

  return res;
}

function shiftHours({ starts_time, ends_time, primary_location }: Partial<IAssignment>): string {
  const timezone = primary_location?.timezone_lookup || tz.guess();
  const start = tz(starts_time, timezone);
  const end = tz(ends_time, timezone);
  return `${start.format('HH:mm')}-${end.format('HH:mm')}`;
}

function shiftHoursWithTotal({
  starts_time,
  ends_time,
  number_of_shifts_per_week,
  primary_location,
}: IAssignment): string {
  const timezone = primary_location?.timezone_lookup || tz.guess();
  const start = tz(starts_time, timezone);
  const end = tz(ends_time, timezone);
  return `${shiftHours({ starts_time, ends_time, primary_location })} (${(
    end.diff(start, 'hours', true) * number_of_shifts_per_week
  ).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })} hrs/week)`;
}

function assignmentTime(assignment: Partial<IAssignment>): string | null {
  const format = 'HH:mm';
  const { number_of_shifts_per_week, starts_time, ends_time, primary_location } = assignment;
  const timezone = primary_location?.timezone_lookup || tz.guess();
  const start = tz(starts_time, timezone);
  const end = tz(ends_time, timezone);
  // hoursDiff is specifically the raw ends - starts time.
  // Do not confuse this with assignment.hours_per_shift, which deducts estimated break time.
  const hoursDiff = end.diff(start, 'hours', true);

  return `${number_of_shifts_per_week}x${hoursDiff.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })}hr Shifts • ${start.format(format)} - ${end.format(format)}`;
}

function assignmentExpectedHours(assignment: Partial<IAssignment>): string {
  // assignment.hours_per_shift deducts estimated break time.
  // This is exactly what we want when displaying expected hours, because its shown next to compensation.
  const { hours_per_shift, number_of_shifts_per_week } = assignment;
  return (hours_per_shift * number_of_shifts_per_week).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

function assignmentProcedures(assignment: Partial<IAssignment>) {
  return assignment.surgery_types?.map((s) => s.display_name).join(', ') || '–';
}

function assignmentPatientPopulation(assignment: Partial<IAssignment>) {
  return titleize(assignment.patient_population || '–');
}

function assignmentPositions(assignment: Partial<IAssignment>) {
  return assignment.positions?.map((s) => s.display_name).join(', ') || '–';
}

function assignmentSpecialties(assignment: Partial<IAssignment>) {
  return assignment.specialties?.map((s) => s.label).join(', ') || '–';
}

function assignmentFullDayDate(assignment: Partial<IAssignment>, isAssignmentOnboarding = false) {
  const date = isAssignmentOnboarding ? assignment.onboarding_due_date : assignment.starts_date;
  if (!date) {
    return null;
  }
  return dateTimeLegacy.custom(
    date,
    'dddd, MM/DD/YY',
    assignment.primary_location?.timezone_lookup,
  );
}

function assignmentNewFullDayDate(
  assignment: Partial<IAssignment>,
  isAssignmentOnboarding = false,
) {
  const date = isAssignmentOnboarding ? assignment.onboarding_due_date : assignment.starts_date;
  if (!date) {
    return null;
  }
  return dateTimeLegacy.custom(date, 'dddd, M/D/YY', assignment.primary_location?.timezone_lookup);
}

function assignmentDates(
  { starts_date, ends_date }: IAssignment,
  { withParentheses = true, withAbbreviation = true },
): string {
  return `${dateTimeLegacy.date(starts_date)} - ${dateTimeLegacy.date(ends_date)}${
    withParentheses ? ` (` : ` • `
  }${pluralize(weekCount(starts_date, ends_date), withAbbreviation ? `wk` : `Week`)}${
    withParentheses ? `)` : ``
  }`;
}

function assignmentStartDateWithWeekCountLegacy({ starts_date, ends_date }: IAssignment): string {
  return starts_date && ends_date
    ? `${dateTimeLegacy.date(starts_date)} • ${pluralize(
        weekCount(starts_date, ends_date),
        'week',
      )}`
    : ``;
}

function assignmentStartDateAndEndDate({ starts_date, ends_date }: IAssignment): string {
  return `${dateTimeLegacy.date(starts_date)} - ${dateTimeLegacy.date(ends_date)}`;
}

function assignmentStartDateWithWeekCount({ starts_date, ends_date }: IAssignment): string {
  return starts_date && ends_date
    ? `${dateTime.date(starts_date)} • ${pluralize(weekCount(starts_date, ends_date), 'week')}`
    : ``;
}

function weekCount(starts_date: string, ends_date: string): number {
  const start = moment(starts_date);
  const end = moment(ends_date);
  const weekCount = Math.round((end.diff(start, 'days') + 1) / 7);

  return weekCount;
}

function formatDateTime(dateTime: MomentInput, format: string, timezone?: string): string {
  if (!dateTime) {
    return '';
  }
  if (timezone) {
    return tz(dateTime, timezone).format(format);
  }
  return moment.parseZone(dateTime).format(format);
}

const mapPatientPopulationToString: {
  [P in IAssignment['patient_population']]: string;
} = {
  adults: 'Adults',
  pediatrics: 'Peds',
  pediatrics_adults: 'Adults & Peds',
};

function patientPopulation(value: IAssignment['patient_population']) {
  return mapPatientPopulationToString[value];
}

function scrubs(value: IAssignment['scrubs_provided']) {
  return value ? 'Scrubs provided' : 'Bring your own scrubs';
}

// const mapCredentialKind: { [K in IProfessionalQualification['kind']]: string } = {
//   license: 'License',
//   certification: 'Certification',
//   document: 'Document',
// };

// const mapCredentialKindToIcon: { [K in Credential['kind']]: any } = {
//   license: 'license',
//   certification: 'certification',
//   document: 'document',
// };

// const mapCredentialStatus: {
//   [K in Credential['status']]: { text: string; variant: string };
// } = {
//   incomplete: {
//     text: 'Missing',
//     variant: 'error',
//   },
//   review: {
//     text: 'Under Review',
//     variant: 'success',
//   },
//   approved: {
//     text: 'Approved',
//     variant: 'success',
//   },
//   rejected: {
//     text: 'Rejected',
//     variant: 'error',
//   },
//   expired: {
//     text: 'Expired',
//     variant: 'error',
//   },
// };

// function credentialKindText(kind: Credential['kind']): string {
//   return mapCredentialKind[kind];
// }

// function credentialKindIcon(kind: Credential['kind']): any {
//   return mapCredentialKindToIcon[kind];
// }

// function credentialStatus(
//   status: Credential['status'],
//   expDate?: Credential['expiration_date'],
//   expiresSoon?: Credential['expires_soon'],
// ) {
//   if (expDate) {
//     if (moment(expDate).isBefore(moment())) {
//       return {
//         text: 'Expired',
//         variant: 'error',
//       };
//     }
//     if (expiresSoon) {
//       return {
//         text: 'Expiring soon',
//         variant: 'warning',
//       };
//     }
//   }
//   return mapCredentialStatus[status];
// }

const mapKindToString: { [K in IProfessional['kind']]: string } = {
  lvn: 'LVN',
  registered_nurse: 'Registered Nurse',
  allied: 'Allied',
  imaging_services: 'Imaging Services',
};
function kind(value: IProfessional['kind']): string {
  return mapKindToString[value];
}

function assignmentRequestStage(ar: IAssignmentRequest): string {
  if (ar.status === 'offer_sent') {
    return 'Offer Sent';
  }
  if (!ar.phone_screening_request) {
    return 'Applied';
  }
  switch (ar.phone_screening_request.status) {
    case 'scheduled':
      return 'Applied';
    case 'completed':
      return 'Completed Interview ';
    case 'no_show':
      return 'Missed Interview';
    case 'pending':
      return 'Applied';
    default:
      return 'error';
  }
}

function workHistoryDates(
  wh: Pick<IResumeItem, 'start_date' | 'end_date' | 'currently_work_here'>,
  options = {
    format: 'MMM YYYY',
  },
): string {
  const start = dateTimeLegacy.custom(wh.start_date, options.format);
  if (wh.currently_work_here) {
    return `${start} - Current`;
  }
  return `${start} - ${dateTimeLegacy.custom(wh.end_date, options.format)}`;
}

function parking(
  location: Pick<
    ILocation,
    | 'parking_free'
    | 'parking_reimbursed'
    | 'parking_validated'
    | 'parking_cost_cents'
    | 'parking_reimbursement_cents'
  >,
): string {
  if (location.parking_free) {
    return 'Free Parking';
  }
  if (location.parking_reimbursed) {
    return `Reimbursed - ${centsToCurrency(location.parking_reimbursement_cents || 0)}`;
  }
  if (location.parking_validated) {
    return 'Validated';
  }
  return `Not Reimbursed - ${centsToCurrency(location.parking_cost_cents || 0)}`;
}

export type JobForShiftType = Pick<IJob, 'shift_type'> & JobForIsOvernight;
function shiftType(job: JobForShiftType): string {
  const { shift_type } = job;

  const isOverNight = isOvernight(job);

  if (shift_type === ('regular' as ShiftType)) {
    return isOverNight ? 'Regular Shift Overnight' : 'Regular Shift';
  }

  if (shift_type === ('on_call' as ShiftType)) {
    return isOverNight ? 'On Call Shift Overnight' : 'On Call Shift';
  }

  if (shift_type === ('call_back' as ShiftType)) {
    return isOverNight ? 'Call Back Shift Overnight' : 'Call Back Shift';
  }

  if (shift_type === ('missed' as ShiftType)) {
    return 'Missed Shift';
  }
  if (shift_type === ('cancelled' as ShiftType)) {
    return 'Cancelled Shift';
  }
}

const cityStateZip = (location: ILocation | Partial<ILocation>) => {
  const city = location.address_city;
  if (city) {
    return `${location?.address_city ?? ''}, ${location?.state?.abbreviation ?? ''} ${
      location?.address_zip ?? ''
    }`;
  } else {
    return `${location?.state?.abbreviation ?? ''} ${location?.address_zip ?? ''}`.trim();
  }
};

const cityState = (location: Partial<ILocation>) => {
  const city = location.address_city;
  if (city) {
    return `${location.address_city ?? ''}, ${location.state?.abbreviation ?? ''}`;
  } else {
    return `${location.state?.abbreviation ?? ''}`;
  }
};

const cityZip = (location: Partial<ILocation>) => {
  const city = location.address_city;

  return !city
    ? `${location?.address_zip ?? ''}`
    : `${location.address_city ?? ''} ${location?.address_zip ?? ''}`.trim();
};

const facilityCityState = (location: Partial<ILocation>) => {
  const name = location.name;
  const city = location.address_city;

  if (!name && !city) {
    return `${location.state?.abbreviation ?? ''}`;
  }

  if (!city) {
    return `${location.name ?? ''}, ${location.state?.abbreviation ?? ''}`;
  }

  if (!name) {
    return `${location?.address_city ?? ''}, ${location.state?.abbreviation ?? ''}`;
  }

  return `${location.name ?? ''}, ${location?.address_city ?? ''}, ${
    location.state?.abbreviation ?? ''
  }`;
};

const facilityCity = (location: Partial<ILocation>) => {
  const name = location.name;

  return !name
    ? `${location?.address_city ?? ''}`
    : `${location.name ?? ''}, ${location?.address_city ?? ''}`;
};

const assignmentAddress = (assignment: Partial<IAssignment>, variant: string) => {
  const isApplied = variant === 'applications';
  const isBooked = variant === 'booked';
  const isTravel = assignment.is_w2 && assignment.is_travel;
  const showFacilityNameInAddress = isBooked || isApplied;

  if (
    (showFacilityNameInAddress && isTravel && isBooked) ||
    (showFacilityNameInAddress && isApplied)
  ) {
    return `${facilityCityState(assignment.primary_location)}`;
  }

  if (showFacilityNameInAddress && !isTravel && isBooked) {
    return `${facilityCity(assignment.primary_location)}`;
  }

  if (!showFacilityNameInAddress && isTravel) {
    return `${cityState(assignment.primary_location)}`;
  }

  if (!showFacilityNameInAddress && !isTravel) {
    return `${cityZip(assignment.primary_location)}`;
  }
};

const jobAddress = (job: Partial<IJob>, variant: string) => {
  const isUpcomingShift = variant === 'upcoming_shifts';
  const isBooked = job.status === 'booked';

  if (isUpcomingShift) {
    return `${cityStateZip(job.location)}`;
  }

  if (isBooked) {
    return `${facilityCity(job.location)}`;
  }

  return `${cityZip(job.location)}`;
};

const IN_TRANSIT_STATUSES = ['in_transit', 'pending'];
const PAID_DISPUTED_STATUSES = ['paid', 'disputed'];
const OPEN_STATUSES = ['open', 'submitted'];
const getPayoutStatus = (payout: IPayout) => {
  if (PAID_DISPUTED_STATUSES.includes(payout.status)) {
    return payout.status;
  }
  if (IN_TRANSIT_STATUSES.includes(payout.status)) {
    return 'in_transit';
  }
  if (OPEN_STATUSES.includes(payout.status)) {
    return 'open';
  } else {
    return 'processing';
  }
};

const formatSpecialties = (
  item: Partial<IAssignment> | Partial<IJob>,
  isMobile: boolean,
  maxLabelSize?: number,
): string => {
  const hasMultipleSpecialties = item.specialties.length > 1;
  const label = item.specialties[0].label;
  const isBigLabel = label.length > maxLabelSize;
  const truncateLabel = isMobile && isBigLabel;

  const specialty = truncateLabel ? `${label.slice(0, maxLabelSize)}...` : label;
  const otherSpecialties = hasMultipleSpecialties ? ` +${item.specialties.length - 1} more` : '';

  return specialty.concat(otherSpecialties);
};

const DisplayHelpers = {
  parking,
  dateTime: dateTimeLegacy,
  patientPopulation,
  scrubs,
  // credential: {
  //   kind: credentialKindText,
  //   iconName: credentialKindIcon,
  //   status: credentialStatus,
  // expirationDate: renderExpirationDate,
  // },
  kind,
  assignmentRequestStage,
  payoutDates,
  timecardCustomDates,
  formatPhoneNumber,
  workHistory: {
    dates: workHistoryDates,
  },
  job: {
    address: jobAddress,
    date: jobDate,
    displayTimecardStatus,
    fullDayDateLegacy: jobFullDayDateLegacy,
    fullDayDate: jobFullDayDate,
    disputeScheduledTime: jobDisputeScheduledTime,
    timeLegacy: jobTimeLegacy,
    time: jobTime,
    startTime: jobStartTime,
    endTime: jobEndTime,
    jobHours,
    jobBillingHours,
    jobBillingBreakdown,
    shiftType,
    specialties: jobSpecialties,
    procedures: jobProcedures,
    positions: jobPositions,
    patientPopulation: jobPatientPopulation,
  },
  assignment: {
    address: assignmentAddress,
    shiftHours,
    shiftHoursWithTotal,
    dates: assignmentDates,
    startDateWithWeekCountLegacy: assignmentStartDateWithWeekCountLegacy,
    startDateWithWeekCount: assignmentStartDateWithWeekCount,
    time: assignmentTime,
    fullDayDate: assignmentFullDayDate,
    newFullDayDate: assignmentNewFullDayDate,
    specialties: assignmentSpecialties,
    procedures: assignmentProcedures,
    patientPopulation: assignmentPatientPopulation,
    positions: assignmentPositions,
    weekCount,
    expectedHours: assignmentExpectedHours,
    assignmentStartDateAndEndDate,
  },
  location: {
    cityStateZip,
    cityState,
    cityZip,
    facilityCityState,
    facilityCity,
  },
  specialty: {
    formatSpecialties,
  },
  payout: {
    getPayoutStatus,
  },
};

export default DisplayHelpers;
