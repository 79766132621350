import { ButtonGroup, OptionType, Typography } from '@medely/web-components';
import React from 'react';
import { Field } from 'react-final-form';
import { required } from 'core/utils/validation';

const options: OptionType[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
];

interface RatingProps {
  name: string;
  label: string;
  isRequired: boolean;
  withNameLabel?: boolean;
}

const Rating = ({ name, label, isRequired, withNameLabel = true }: RatingProps) => {
  return (
    <>
      {withNameLabel && <Typography gutterBottom>{label}</Typography>}
      <Field name={`${name}.value`} validate={isRequired ? required : undefined}>
        {({ input }) => {
          return (
            <>
              <ButtonGroup
                options={options}
                value={input.value}
                onChange={input.onChange}
                squared
              />
            </>
          );
        }}
      </Field>
    </>
  );
};

export default Rating;
