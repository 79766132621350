import { BottomSheetDialog } from '@medely/web-components';
import React from 'react';
import { Button } from '@medely/ui-kit';
import { AddExperienceModalInfo } from './AddExperienceModalInfo';
import { ArrowLeft } from '@medely/web-components/icons';

type BottomSheetDialogProps = React.ComponentProps<typeof BottomSheetDialog>;

export type AddExperienceModalProps = {
  open: BottomSheetDialogProps['open'];
  onClose: () => void;
};

export const AddExperienceModal = ({ open, onClose }: AddExperienceModalProps) => {
  return (
    <BottomSheetDialog
      open={open}
      disableRestoreFocus
      content={
        <>
          <Button variant="text" onClick={onClose} startIcon={<ArrowLeft />}>
            Back
          </Button>
          <AddExperienceModalInfo onClose={onClose} />
        </>
      }
      isDesktop={false}
    />
  );
};
