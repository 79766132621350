import useCurrentUser from 'hooks/useCurrentUser';
import { useHelloSign } from 'hooks/useHelloSign';
import { useEffect } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';

declare global {
  interface Window {
    ReactNativeWebView: { postMessage(msg: string): void };
  }
}

export const ContractWebView = () => {
  const [{ contractId }] = useQueryParams({ contractId: NumberParam });
  const { currentUser } = useCurrentUser();
  const { showHelloSignDialog, helloSignClient } = useHelloSign();

  const closeWebView = () => {
    window.ReactNativeWebView.postMessage('close');
  };

  useEffect(() => {
    if (currentUser) {
      showHelloSignDialog(`/v3/professional/contracts/${contractId}/signature_request`);
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (helloSignClient) {
      helloSignClient.on('sign', () => {
        closeWebView();
      });
    }
  }, [helloSignClient]);
  return null;
};
