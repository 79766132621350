import React, { useEffect, useState } from 'react';
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { useTermsOfService } from './hooks/useTermsOfService';
import isDevOrStaging from '@src/utils/isDevOrStaging';
import { notifyRollbar } from '@src/utils/Rollbar/notifyRollbar';
import { Box, Button } from '@medely/web-components';
import config from '@src/config';
import useCurrentUser from '@src/hooks/useCurrentUser';
import useSnackbar from '@src/hooks/useSnackbar';

declare global {
  interface Window {
    medely?: {
      termsOfServiceSigned: () => void;
    };
  }
}

const accessId = '81e8ee67-11ac-47dc-9128-00fbdf0bf3c8';

export const TermsOfService = () => {
  const snackbar = useSnackbar();
  const { currentUser, isAgency } = useCurrentUser();
  const [valid, setValid] = useState(false);

  const groupKey = isAgency
    ? config.termsOfServiceKey.modal.agency
    : config.termsOfServiceKey.modal.marketplace;
  const signerId = encodeURIComponent(currentUser?.id);

  const { hasSignedLatestTerms, isLoading, isSuccess, refetch } = useTermsOfService({
    accessId,
    signerId,
    groupKey,
  });

  const handleSent = async (event_type: any) => {
    if (event_type === 'agreed') {
      refetch();
    }
  };

  // All contracts accepted
  const handleValid = () => {
    setValid(true);
  };

  const handleSubmit = () => {
    if (!valid) {
      snackbar.warning('Please accept the terms of service');
      return;
    }

    refetch();
  };

  const handleError = (_message: any) => {
    snackbar.error(_message, 'Something went wrong');
    notifyRollbar({
      errorLabel: 'Failed to accept terms of service for mobile user',
      args: {
        accountId: currentUser?.id,
        groupKey,
      },
    });
  };

  // We wait for user acceptance to propagate our system, rather then the callback
  // That way we don't send them right back here
  useEffect(() => {
    if (isSuccess && hasSignedLatestTerms) {
      window.medely?.termsOfServiceSigned();
    }
  }, [hasSignedLatestTerms, isSuccess]);

  return (
    <Box>
      <PSClickWrap
        clickWrapStyle="full"
        accessId={accessId}
        groupKey={groupKey}
        signerId={signerId}
        testMode={isDevOrStaging()}
        onValid={handleValid}
        onSent={handleSent}
        onError={handleError}
      />
      <Box position="sticky" bottom={0} zIndex={1} display="flex" justifyContent="flex-end">
        <Button loading={isLoading} variant="contained" color="primary" onClick={handleSubmit}>
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default TermsOfService;
