import { useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import filter from 'lodash/filter';
import { useSignedLatestTerms } from '@medely/pro-multi-platform';
import { notifyRollbar } from '@src/utils/Rollbar/notifyRollbar';
import useCurrentUser from '@src/hooks/useCurrentUser';

declare global {
  interface Window {
    _ps?: () => void;
  }
}

export type UseTermsOfServiceOptions = {
  accessId: string;
  signerId: string;
  groupKey: string;
};

export function useTermsOfService(options: UseTermsOfServiceOptions) {
  const { currentUser } = useCurrentUser();
  const accountId = currentUser?.id ?? 0;

  const {
    hasSignedLatestTerms,
    isLoading: isLoading_Latest,
    isSuccess: isSuccess_Latest,
    refetch: refetch_Latest,
  } = useSignedLatestTerms({
    accountId,
    groupKey: options.groupKey,
  });

  const {
    unsignedContractCount,
    isSuccess: isSuccess_Count,
    refetch: refetch_Count,
  } = useUnsignedContractCount(options);

  const refetch = () => {
    refetch_Latest();
    refetch_Count();
  };

  // This is a safeguard to ensure our internal API is staying in sync with the PactSafe API
  useEffect(() => {
    const doneLoading = isSuccess_Count && isSuccess_Latest;
    const unsignedContractMismatch = (unsignedContractCount === 0) === hasSignedLatestTerms;

    if (doneLoading && unsignedContractMismatch) {
      notifyRollbar({
        errorLabel: 'Unsigned contract mismatch',
        args: {
          hasSignedLatestTerms,
          unsignedContractCount,
        },
      });
    }
  }, [unsignedContractCount, hasSignedLatestTerms, isSuccess_Count, isSuccess_Latest]);

  return {
    hasSignedLatestTerms,
    // For faster rendering, we only care about our own API's results
    isLoading: isLoading_Latest,
    isSuccess: isSuccess_Latest,
    refetch,
  };
}

function useUnsignedContractCount({ accessId, groupKey, signerId }: UseTermsOfServiceOptions) {
  const checkContracts = async () => {
    const { data } = await axios.get(
      `https://pactsafe.io/latest?sid=${accessId}&gkey=${groupKey}&sig=${encodeURIComponent(
        signerId,
      )}`,
    );
    const numberOfUnsignedContracts = filter(data, (contract: any) => !contract).length;
    return numberOfUnsignedContracts;
  };

  const {
    data: unsignedContractCount,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery(['termsOfService', accessId, signerId, groupKey], async () => {
    const numberOfUnsignedContracts = await checkContracts();
    return numberOfUnsignedContracts;
  });

  return {
    unsignedContractCount,
    isLoading,
    isSuccess,
    refetch,
  };
}
