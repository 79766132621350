import { pluralize } from './text';

export function convertFeetToMeters(feet: number) {
  const INCHES_IN_FOOT = 12;
  const INCHES_IN_CENTIMETER = 2.54;
  const CENTIMETERS_IN_METER = 100;

  return (feet * INCHES_IN_FOOT * INCHES_IN_CENTIMETER) / CENTIMETERS_IN_METER;
}

export function convertMetersToMiles(meters: number) {
  const METERS_IN_MILE = 1609;

  return Math.round(meters / METERS_IN_MILE);
}

export function formatMetersToMiles(meters: number) {
  const miles = convertMetersToMiles(meters);
  return `${pluralize(miles, 'mile')} away`;
}
