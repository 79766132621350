import { ICondition } from '@medely/types';
import { GET_CONDITIONS } from '../graphql';
import { useCurrentUser } from './useCurrentUser';
import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { useNetworkRequest, GqlRequest } from './useNetworkRequest';

const DEFAULT_REFETCH_INTERVAL = 15000;

type UseQueryMissingConditionsInput = {
  assignment_id?: number;
  recommended?: boolean;
  job_id?: number;
  position_ids?: number[];
  professional_id: number;
};

const fetchMissingConditions = async (
  queryContext: QueryFunctionContext<[unknown, UseQueryMissingConditionsInput]>,
  gqlRequest: GqlRequest,
) => {
  const {
    queryKey: [_entity, { professional_id, job_id, assignment_id, position_ids, recommended }],
  } = queryContext;
  const { conditions } = await gqlRequest(GET_CONDITIONS, {
    input: {
      search: {
        missing_conditions: {
          for_application: false,
          for_on_boarding: false,
          for_premier_only: !!recommended,
          job_id,
          assignment_id,
          position_ids,
          professional_id,
        },
      },
    },
  });
  return conditions;
};

export type UseMissingConditionsOptions = Omit<
  UseQueryMissingConditionsInput,
  'professional_id'
> & {
  refetch?: boolean;
};

type UseMissingConditionsOptionsResult = {
  conditions: ICondition[];
  isLoading: boolean;
  refetch: () => Promise<unknown>;
};

export const useMissingConditions = ({
  refetch,
  ...args
}: UseMissingConditionsOptions = {}): UseMissingConditionsOptionsResult => {
  const { currentUser } = useCurrentUser();
  const { gqlRequest } = useNetworkRequest();

  const enabled = !!currentUser?.professional;
  const input: UseQueryMissingConditionsInput = {
    ...args,
    professional_id: currentUser!.professional!.id,
  };
  const {
    data,
    isLoading,
    isInitialLoading,
    refetch: refetchMissing,
  } = useQuery<any, unknown, any, [string, UseQueryMissingConditionsInput]>(
    ['missingConditions', input],
    (queryContext) => fetchMissingConditions(queryContext, gqlRequest),
    {
      enabled,
      refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined,
    },
  );
  return {
    refetch: refetchMissing,
    conditions: data ?? [],
    isLoading: enabled ? isLoading : isInitialLoading,
  };
};
