import React from 'react';
import { PageLayoutProps } from './interface';
import { Box, Heading } from '@medely/ui-kit';
import { usePageTitle } from './TitleProvider';

export const PageLayout = ({ title, children }: PageLayoutProps) => {
  usePageTitle(title);
  return (
    <>
      <Box pt={4} px={2}>
        <Heading size="m">{title}</Heading>
      </Box>
      {children}
    </>
  );
};
