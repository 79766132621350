import React, { ReactElement } from 'react';

import { Box, Label, Text, VStack } from '@medely/ui-kit';

type EmptyStateProps = {
  title: string;
  description: string;
  linkComponent?: ReactElement;
};

export const EmptyState = ({ title, description, linkComponent }: EmptyStateProps) => {
  return (
    <VStack py={3} alignItems="center">
      <Box pb={0.5}>
        <Label size="m">{title}</Label>
      </Box>
      <Box width="360px">
        <Text size="s" color="text.secondary" textAlign="center">
          {description}
        </Text>
      </Box>
      {!!linkComponent && <Box mt={2}>{linkComponent}</Box>}
    </VStack>
  );
};
