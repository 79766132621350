import React from 'react';
import { AttributeFieldArgs } from '.';
import { Button, Typography } from '@medely/web-components';
import { ChevronRight } from '@medely/web-components/icons';
import useSignedDocument from 'hooks/useSignedDocument';

const SignedDocument = ({
  qualificationAttribute,
}: Pick<AttributeFieldArgs, 'qualificationAttribute'>) => {
  const { isLoading, isComplete, showSigningDialog } = useSignedDocument(
    qualificationAttribute.professional_qualification_attribute_id,
  );

  if (isComplete) {
    return (
      <Typography weight="medium" variant="h6">
        Signature Completed
      </Typography>
    );
  }

  return (
    <Button
      disabled={isLoading}
      loading={isLoading}
      variant="contained"
      size="medium"
      endIcon={<ChevronRight fontSize="small" />}
      onClick={showSigningDialog}
    >
      Review and Sign
    </Button>
  );
};

export default SignedDocument;
