import React, { useEffect, useState } from 'react';
import { WorkExperiencePrimaryForm } from './WorkExperiencePrimaryForm';
import { ExperienceSummaryView } from './experienceSummary/ExperienceSummaryView';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';
import { AddMoreMonthsOfWorkExperience } from './AddMoreMonthsOfWorkExperience';
import { useWorkExperience } from './useWorkExperience';
import { LoadingState } from '../../components/LoadingState';
import { SetPageProps } from './types';

export const WorkExperience = () => {
  const [page, setPage] = useState<SetPageProps>('loading');
  const { workHistories, currentUser, currentUserLoading, workHistoryLoading } =
    useGetWorkExperienceInitialData();

  const workHistory = workHistories?.at(0);

  const loadingInitialData = currentUserLoading || workHistoryLoading;

  const hasOnlyOnePosition = currentUser?.professional?.professional_positions?.length === 1;

  const pages = {
    experienceSummary: <ExperienceSummaryView />,
    primaryForm: (
      <WorkExperiencePrimaryForm
        setPage={setPage}
        currentUser={currentUser || undefined}
        hasOnlyOnePosition={hasOnlyOnePosition}
      />
    ),
    addMoreMonths: (
      <AddMoreMonthsOfWorkExperience
        setPage={setPage}
        workHistories={workHistories}
        currentUser={currentUser || undefined}
        isLoading={loadingInitialData}
      />
    ),
    loading: <LoadingState />,
  };

  const { isMissingYoEFromMultiplesWorkHistories } = useWorkExperience({
    currentUser: currentUser || undefined,
    workHistories,
  });

  useEffect(() => {
    const getPage = () => {
      if (!workHistory) {
        return 'primaryForm';
      }
      if (
        isMissingYoEFromMultiplesWorkHistories({
          positionId: workHistory.position?.id,
        })
      ) {
        return 'addMoreMonths';
      }
      return 'experienceSummary';
    };
    if (!loadingInitialData) {
      setPage(getPage());
    }
  }, [currentUser, workHistory]);

  if (loadingInitialData) {
    return <LoadingState />;
  }

  return pages[page];
};
