import { Typography } from '@medely/web-components';
import { Field } from 'react-final-form';
import React from 'react';
import { required } from 'core/utils/validation';
import { TextField } from '@medely/web-components/forms';

type StringFieldProps = {
  name: string;
  isRequired: boolean;
  withNameLabel?: boolean;
  label: string;
};

const String = ({ name, isRequired, withNameLabel, label }: StringFieldProps) => {
  return (
    <>
      {withNameLabel && <Typography gutterBottom>{label}</Typography>}
      <Field name={`${name}.value`}>
        {({ input }) => (
          <TextField
            placeholder={withNameLabel ? undefined : label}
            validate={isRequired ? required : undefined}
            multiline
            {...input}
          />
        )}
      </Field>
    </>
  );
};

export default String;
