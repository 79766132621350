import { IProfessionalQualificationAttribute } from '@medely/types';
import { PdfPreview } from '@medely/web-components';
import React from 'react';

type PdfProps = {
  attribute: IProfessionalQualificationAttribute;
};

const Pdf = ({ attribute }: PdfProps) => {
  return <PdfPreview src={attribute.value} />;
};

export default Pdf;
