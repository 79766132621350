import React from 'react';
import { Dialog } from '@medely/web-components';
import { ShiftConfirmationInfo } from './ShiftConfirmationInfo.web';
import { ShiftConfirmationModalProps } from './interface';
import { Button } from '@medely/ui-kit';

export const ShiftConfirmationModal = ({
  onClose,
  open,
  job,
  jobMap,
  userCoordinates,
}: ShiftConfirmationModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      title="Thanks for confirming"
      data-testid="shift-confirmation-dialog"
      content={
        <ShiftConfirmationInfo jobMap={jobMap} job={job} userCoordinates={userCoordinates} />
      }
      actions={
        <Button variant="contained" color="primary" onClick={onClose} fullWidth>
          OK
        </Button>
      }
    />
  );
};
