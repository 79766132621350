import { IPosition } from '@medely/types';
import { GET_PROFILE_POSITIONS_WITH_SPECIALTIES } from '../graphql';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { GqlRequest, useNetworkRequest } from './useNetworkRequest';
import { useCurrentUser } from '.';

interface IPositionsWithSpecialtiesProps {
  positionIds?: number[];
}
interface IUsePositionsWithSpecialtiesReturn {
  positions: IPosition[];
  isLoading: boolean;
}

const fetchPositions = async (
  queryContext: QueryFunctionContext<[unknown, number[]]>,
  gqlRequest: GqlRequest,
) => {
  const {
    queryKey: [_entity, positionIds],
  } = queryContext;
  const { positions } = await gqlRequest(GET_PROFILE_POSITIONS_WITH_SPECIALTIES, {
    input: {
      search: {
        position_ids: positionIds,
      },
    },
  });
  return positions;
};

export const usePositionsWithSpecialties = ({
  positionIds = [],
}: IPositionsWithSpecialtiesProps): IUsePositionsWithSpecialtiesReturn => {
  const { gqlRequest } = useNetworkRequest();
  const { currentUser } = useCurrentUser();

  const enabled = !!currentUser;

  const { isLoading, isInitialLoading, data } = useQuery(
    ['positions', positionIds],
    (queryContext: QueryFunctionContext<[unknown, number[]]>) =>
      fetchPositions(queryContext, gqlRequest),
    {
      enabled,
    },
  );

  return {
    positions: data ?? [],
    isLoading: enabled ? isLoading : isInitialLoading,
  };
};
