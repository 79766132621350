import axios from 'axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { type Prediction } from '../constants/places';
const GOOGLE_API = 'https://maps.googleapis.com/maps/api/place/autocomplete/json';

type PredictionsQueryResult = {
  data: { predictions: Prediction[] };
};

type IUseLocationSearch = {
  apiKey: string;
  components?: Array<string>;
  input: string;
  types?: string[];
};

const fetchLocations = async ({
  queryKey: [_key, { apiKey, components, input, types }],
}: QueryFunctionContext<[unknown, IUseLocationSearch]>) => {
  const {
    data: { predictions },
  }: PredictionsQueryResult = await axios.get(GOOGLE_API, {
    params: {
      apiKey,
      components: components?.join('|'),
      input,
      key: apiKey,
      types: types?.join('|'),
    },
  });

  return predictions;
};

export const useLocationSearch = ({ apiKey, input, types, components }: IUseLocationSearch) => {
  const enabled = !isEmpty(input);

  const {
    data: locations,
    isInitialLoading,
    isLoading,
  } = useQuery(['locationSearch', { apiKey, input, types, components }], fetchLocations, {
    enabled,
  });

  return { locations, isLoading: enabled ? isLoading : isInitialLoading };
};

export default useLocationSearch;
