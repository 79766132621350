import React from 'react';
import { DialogProps } from '@medely/web-components';
import useSignedDocumentQualification from './useSignedDocumentQualification';
import CredentialModal from '../professionalQualificationModal/CredentialModal';

type Args = DialogProps & {
  qualificationId: number;
};

const MissingSignedDocumentDialog = ({ onClose, open, qualificationId }: Args) => {
  const { professionalQualificationId, isLoading } =
    useSignedDocumentQualification(qualificationId);

  return (
    <CredentialModal
      isLoading={isLoading}
      initialMode="edit"
      professionalQualificationId={professionalQualificationId}
      onClose={onClose}
      open={open}
    />
  );
};

export default MissingSignedDocumentDialog;
