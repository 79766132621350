import React from 'react';
import { Dialog } from '@medely/web-components';
import { WhatToExpectInfo } from './WhatToExpectInfo.web';
import { WhatToExpectModalProps } from './interface';

export const WhatToExpectModal = ({ open, onClose }: WhatToExpectModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      title="What to expect"
      data-testid="what-to-expect-dialog"
      content={<WhatToExpectInfo />}
    />
  );
};
