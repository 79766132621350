import { IQualification } from '@medely/types';
import { useRandomIdRef, TrackedEvents } from '@medely/pro-multi-platform';
import merge from 'lodash/merge';
import { useRef } from 'react';
import { useTrackedEvent } from './useTrackedEvent';

const TRACKED_EVENT_NAMES = {
  EDIT_STARTED: 'QualificationEditStartedTrackedEvent',
  EDIT_FINISHED: 'QualificationEditFinishedTrackedEvent',
};

const DEFAULT_QUERY_PARAMS = {
  object_type: 'Qualification',
};

type TrackQualificationOptions = {
  qualificationId?: IQualification['id'];
  qualificationSlug?: IQualification['slug'];
};

type TrackCloseQualificationOptions = TrackQualificationOptions & {
  saved?: boolean;
};

type UseTrackQualificationProps = TrackQualificationOptions;

type UseTrackQualificationReturn = {
  trackQualificationStartEditing: (options?: TrackQualificationOptions) => void;
  trackQualificationFinishEditing: (options?: TrackCloseQualificationOptions) => void;
};

const useToggleRef = (initialValue = false) => {
  const value = useRef(initialValue);

  const set = (newValue: boolean) => {
    value.current = newValue;
  };

  const get = () => {
    return value.current;
  };

  return { get, set };
};

export const useTrackQualification = (
  props?: UseTrackQualificationProps,
): UseTrackQualificationReturn => {
  const eventId = useRandomIdRef();
  const startEventDispatched = useToggleRef();

  const trackOpen = useTrackedEvent({
    event: TrackedEvents.QUALIFICATION_EDIT_STARTED,
    queryParams: {
      ...DEFAULT_QUERY_PARAMS,
      tracked_event_name: TRACKED_EVENT_NAMES.EDIT_STARTED,
    },
  });
  const trackClose = useTrackedEvent({
    event: TrackedEvents.QUALIFICATION_EDIT_FINISHED,
    queryParams: {
      ...DEFAULT_QUERY_PARAMS,
      tracked_event_name: TRACKED_EVENT_NAMES.EDIT_FINISHED,
    },
  });

  const generateParamsObjectId = (options: TrackQualificationOptions) => {
    const qualificationId = options?.qualificationId ?? props?.qualificationId;
    const qualificationSlug = options?.qualificationSlug ?? props?.qualificationSlug;

    if (qualificationId) {
      return {
        object_id: qualificationId,
      };
    } else if (qualificationSlug) {
      return {
        slug: qualificationSlug,
      };
    }

    return {};
  };

  const generateParams = (options: TrackQualificationOptions) => {
    const objectIdProps = generateParamsObjectId(options);

    return {
      ...objectIdProps,
      metadata: {
        event_id: eventId.get(),
      },
    };
  };

  const trackQualificationStartEditing: UseTrackQualificationReturn['trackQualificationStartEditing'] =
    (options) => {
      if (startEventDispatched.get()) {
        return;
      }
      const overrideParams = generateParams(options);

      trackOpen(overrideParams);
      startEventDispatched.set(true);
    };

  const trackQualificationFinishEditing: UseTrackQualificationReturn['trackQualificationFinishEditing'] =
    (options) => {
      if (!startEventDispatched.get()) {
        return;
      }
      const overrideParams = merge(generateParams(options), {
        metadata: {
          completed: options?.saved ?? false,
        },
      });

      trackClose(overrideParams);
      eventId.refresh();
      startEventDispatched.set(false);
    };

  return {
    trackQualificationStartEditing,
    trackQualificationFinishEditing,
  };
};
