import React, { ReactElement } from 'react';
import { Box, CircularProgress } from '@medely/ui-kit';

interface IContentLoaderProps {
  loading: boolean;
  children: ReactElement;
}

export const ContentLoader = ({ loading, children }: IContentLoaderProps): ReactElement => {
  if (loading) {
    return (
      <Box py={2} width="fit-content" m="auto">
        <CircularProgress />
      </Box>
    );
  }
  return children;
};
