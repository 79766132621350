import React from 'react';
import { Dialog } from '@medely/web-components';
import { PostBookingInfo } from './PostBookingInfo.web';
import { PostBookingModalProps } from './interface';

export const PostBookingModal = ({
  onClose,
  open,
  cancelationPolicyButton,
  job,
  jobMap,
  userCoordinates,
}: PostBookingModalProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      title="Your shift is booked"
      data-testid="post-booking-dialog"
      content={
        <PostBookingInfo
          cancelationPolicyButton={cancelationPolicyButton}
          jobMap={jobMap}
          job={job}
          userCoordinates={userCoordinates}
        />
      }
    />
  );
};
