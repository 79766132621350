import { ICondition } from '@medely/types';
import { axios } from 'utils/networkRequests';
import { useFetchProfessionalQualifications } from 'hooks/useProfessionalQualifications';
import { useEffect, useState } from 'react';

const useSignedDocumentQualification = (qualification_id: number) => {
  const [professionalQualificationId, setProfessionalQualificationId] = useState<
    number | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchProfessionalQualifications = useFetchProfessionalQualifications();

  const fetchOrCreateSignedDocumentQualification = async (
    qualification_id: number,
  ): Promise<number> => {
    const result = await fetchProfessionalQualifications({
      queryKey: [undefined, { qualification_ids: [qualification_id] }],
    });

    if (result.length) {
      return result[0].id;
    }

    const {
      data: {
        payload: { id },
      },
    } = await axios.post('/v3/professional/professional_qualifications/signed_document', {
      professional_qualification: {
        qualification_id,
      },
    });

    return id;
  };

  useEffect(() => {
    (async () => {
      const id = await fetchOrCreateSignedDocumentQualification(qualification_id);
      setProfessionalQualificationId(id);
      setIsLoading(false);
    })();
  }, [qualification_id]);

  return { professionalQualificationId, isLoading };
};

export default useSignedDocumentQualification;

export const findSignedDocumentQualification = (condition: ICondition) => {
  return condition.qualifications.find((qualification) =>
    qualification.qualification_attributes.some(
      (attr) => attr.active && attr.field_type === 'signed_document',
    ),
  );
};
