import { IProfessional, ProKind } from '@medely/types';

export enum ASSIGNMENT_TYPE {
  TRAVEL = 'travel',
  ASSIGNMENT = 'assignment',
  PER_DIEM = 'per_diem',
}

type PayRateOption = { [key in IProfessional['kind']]: number[] };
type PayRateOptions = { [key in ASSIGNMENT_TYPE]: PayRateOption };

const PAY_RATE_OPTIONS: PayRateOptions = {
  // @ts-ignore
  [ASSIGNMENT_TYPE.TRAVEL]: {
    registered_nurse: [200000, 250000, 275000, 300000],
    lvn: [200000, 225000, 250000, 300000],
    allied: [125000, 150000, 175000, 200000],
    imaging_services: [125000, 150000, 175000, 200000],
  },
  // @ts-ignore
  [ASSIGNMENT_TYPE.ASSIGNMENT]: {
    registered_nurse: [150000, 175000, 200000, 225000],
    lvn: [125000, 150000, 175000, 200000],
    allied: [80000, 125000, 150000, 200000],
    imaging_services: [80000, 125000, 150000, 200000],
  },
  // @ts-ignore
  [ASSIGNMENT_TYPE.PER_DIEM]: {
    registered_nurse: [45000, 50000, 55000, 60000],
    lvn: [35000, 40000, 45000, 50000],
    allied: [20000, 25000, 30000, 35000],
    imaging_services: [20000, 25000, 30000, 35000],
  },
};

const DEFAULT = [50000, 100000, 200000, 300000, 400000];

export const combinedPayRateOptions = ({
  kinds,
  assignmentType,
}: {
  kinds: ProKind[];
  assignmentType: ASSIGNMENT_TYPE;
}) => {
  const proPayRateOptions = assignmentType
    ? [
        ...new Set(
          Object.entries(PAY_RATE_OPTIONS[assignmentType])
            .filter(([key]) => kinds.includes(key as ProKind))
            .flatMap(([_, value]) => value),
        ),
      ]
    : DEFAULT;

  return proPayRateOptions;
};
