import { useState, useEffect, useMemo, useCallback } from 'react';
import { useHelloSign } from './useHelloSign';
import useProfessionalQualificationAttribute from './useProfessionalQualificationAttribute';
import { IProfessionalQualificationAttribute } from '@medely/types';
import { AxiosResponse } from 'utils/networkRequests';

const useSignedDocument = (
  professionalQualificationAttributeId: number | undefined,
  onClose?: () => void,
  onSign?: (data: IProfessionalQualificationAttribute) => Promise<AxiosResponse<any>>,
) => {
  const [isSigning, setIsSigning] = useState(false);
  const [hasSigned, setHasSigned] = useState(false);
  const { showHelloSignDialog, helloSignClient } = useHelloSign();
  const { data, isLoading: isLoadingProQualification } = useProfessionalQualificationAttribute(
    professionalQualificationAttributeId,
  );

  const isLoading = useMemo(
    () => isLoadingProQualification || isSigning,
    [isLoadingProQualification, isSigning],
  );
  const isComplete = data?.metadata ? !data.metadata.webhook_pending : false;

  useEffect(() => {
    if (helloSignClient) {
      helloSignClient.on('sign', async () => {
        setHasSigned(true);
        onSign && (await onSign(data));
        helloSignClient.close();
        onClose && onClose();
      });
      helloSignClient.on('close', () => {
        if (!hasSigned) {
          helloSignClient.close();
          setIsSigning(false);
          onClose && onClose();
        }
      });
    }
  }, [helloSignClient]);

  const showSigningDialog = useCallback(() => {
    setIsSigning(true);
    try {
      showHelloSignDialog(
        `/v3/professional/professional_qualifications/attributes/${professionalQualificationAttributeId}/embedded_signing_url`,
      );
    } catch (error) {
      console.error('unable to open HelloSign', error);
    }
  }, [showHelloSignDialog, professionalQualificationAttributeId]);

  return {
    isComplete,
    isLoading,
    showSigningDialog,
  };
};

export default useSignedDocument;
