// accounts for first type/click etc. and not the later ones
// @ts-ignore
export function debounceLeading(func, timeout = 300) {
  let timer: NodeJS.Timeout | undefined;
  return <TArgs>(...args: TArgs[]) => {
    if (!timer) {
      // @ts-ignore
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}
