import React from 'react';

import { MedelyDateTime } from '@medely/date-time-tools';
import { HStack, Heading, List, Text, VStack } from '@medely/ui-kit';
import { PayoutSummaryProps } from './interface';
import {
  PayoutDisplayStatus,
  centsToCurrency,
  getPayPeriod,
  getPayoutDisplayStatus,
  getPayoutStatusColor,
  isW2Payout,
} from '../../../utils';
import { DescriptionListItem } from '../../DescriptionListItem';

export const PayoutSummary = ({ payout, headerButton }: PayoutSummaryProps) => {
  const isW2 = isW2Payout(payout);

  const displayStatus = getPayoutDisplayStatus(payout);
  const arrivalDate = payout.arrival_date
    ? new MedelyDateTime(payout.arrival_date, { tz: 'system' }).format('M/d/yy')
    : '-';

  const descriptionItems = [
    {
      label: 'Status',
      value: (
        <Text size="m" color={getPayoutStatusColor(displayStatus)}>
          {displayStatus.toString()}
        </Text>
      ),
    },
    { label: 'Deposit ID', value: payout.id },
    getPayPeriod(payout),
    {
      label: isW2 ? 'Gross pay' : 'Total payment',
      value: centsToCurrency(payout.amount_cents),
    },
    // Hide estimated arrival for W2 payouts
    ...(isW2 ? [] : [{ label: 'Estimated arrival', value: arrivalDate }]),
  ];

  const processingText =
    displayStatus === PayoutDisplayStatus.Processing ? (
      <VStack>
        <Text size="s">Processing time may vary</Text>
        <Text size="s" color="text.secondary">
          Your bank may take 24 to 72 hours for processing, so please allow additional time for the
          payment to be credited to your account.
        </Text>
      </VStack>
    ) : null;
  const w2Text = isW2 ? (
    <Text size="s" color="text.secondary">
      You worked shifts as a W-2 employee for Frontline Health, Inc. The amount shown here is
      pre-tax, you will have tax and insurance withheld in your final payment by Frontline Health.
    </Text>
  ) : null;

  return (
    <VStack gap={2}>
      <HStack justifyContent="space-between">
        <Heading size="s">Payout details</Heading>
        {headerButton}
      </HStack>
      <List items={descriptionItems} keyFn={(item) => item.label} render={DescriptionListItem} />
      {!!processingText || !!w2Text ? (
        <VStack gap={1.5}>
          {processingText}
          {w2Text}
        </VStack>
      ) : null}
    </VStack>
  );
};
