import { Typography } from '@medely/web-components';
import { TextField } from '@medely/web-components/forms';
import { Field } from 'react-final-form';
import React from 'react';
import { required } from 'core/utils/validation';

type TextFieldProps = {
  name: string;
  isRequired: boolean;
  withNameLabel?: boolean;
  label: string;
};

const Text = ({ name, isRequired, withNameLabel, label }: TextFieldProps) => {
  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {withNameLabel && <Typography gutterBottom>{label}</Typography>}
      <Field name={`${name}.value`}>
        {({ input }) => (
          <TextField
            InputProps={{
              onCopy: handleChange,
              onPaste: handleChange,
            }}
            validate={isRequired ? required : undefined}
            multiline
            minRows={4}
            {...input}
          />
        )}
      </Field>
    </>
  );
};

export default Text;
