import React from 'react';

import { JobStatusEnum } from '@medely/types';
import { Divider } from '@medely/web-components';
import { Box, CircularProgress } from '@medely/ui-kit';
import { CompletedShiftGeneralInfo } from './CompletedShiftGeneralInfo';
import { CompletedShiftPaymentInfo } from './CompletedShiftPaymentInfo';
import { CompletedShiftLayoutProps } from './interface';
import { useCurrentUser } from '../../../hooks';
import { isAgencyAccount } from '../../../utils';

export const CompletedShiftLayout = ({ job, disputeCard }: CompletedShiftLayoutProps) => {
  const { currentUser } = useCurrentUser();
  const isAgency = isAgencyAccount(currentUser);

  if (!job) {
    return <CircularProgress />;
  }

  const hidePaymentSection =
    isAgency ||
    [JobStatusEnum.NoShow, JobStatusEnum.LateCancellation, JobStatusEnum.MissedShift].includes(
      job.status as JobStatusEnum,
    );

  return (
    <>
      {!!disputeCard ? <Box mb={3}>{disputeCard}</Box> : null}
      {hidePaymentSection ? null : (
        <>
          <Box py={3}>
            <CompletedShiftPaymentInfo job={job} />
          </Box>
          <Divider thick />
        </>
      )}
      <Box py={3}>
        <CompletedShiftGeneralInfo job={job} />
      </Box>
    </>
  );
};
