import { ICondition } from '@medely/types';
import { useFetchProfessionalQualifications } from 'hooks/useProfessionalQualifications';
import { useQuery } from '@tanstack/react-query';
import { axios } from 'utils/networkRequests';

const useI9Qualification = (qualification_id: number) => {
  const fetchProfessionalQualifications = useFetchProfessionalQualifications();

  const fetchOrCreateI9Qualification = async (qualification_id: number): Promise<number> => {
    const result = await fetchProfessionalQualifications({
      queryKey: ['professionalQualifications', { qualification_ids: [qualification_id] }],
    });

    if (result.length) {
      return result[0].id;
    }

    const {
      data: {
        payload: { id },
      },
    } = await axios.post('/v3/professional/professional_qualifications?type=i9', {
      professional_qualification: {
        qualification_id,
      },
    });

    return id;
  };

  const { data: professionalQualificationId, isLoading } = useQuery({
    queryKey: ['fetchOrCreateI9Qualification', qualification_id], // if it changes, it will re-fetch
    queryFn: () => fetchOrCreateI9Qualification(qualification_id),
  });

  return { professionalQualificationId, isLoading };
};

export default useI9Qualification;

export const findI9Qualification = (condition: ICondition) => {
  return condition.qualifications.find((qualification) => qualification.slug === 'i_9_form');
};
