import moment, { Moment, MomentInput } from 'moment-timezone';
import { IJob } from '@medely/types';

export const setDateToTimeMoment = (
  date: MomentInput,
  time: MomentInput,
  ignoreDateTz = true,
): Moment | null => {
  const dateMoment = ignoreDateTz ? moment.utc(date) : moment(date);
  const timeMoment = moment(time);

  const dateTimeMoment = timeMoment.clone();
  dateTimeMoment.tz(dateMoment.tz());
  dateTimeMoment.set('year', dateMoment.year());
  dateTimeMoment.set('month', dateMoment.month());
  dateTimeMoment.set('date', dateMoment.date());

  if (dateTimeMoment.isValid()) {
    return dateTimeMoment;
  } else {
    throw new Error('Invalid datetime');
  }
};

export const deriveDate = (
  job: IJob,
  time: Moment,
  mode: 'clock_in' | 'clock_out',
): string | null => {
  const startMoment = moment(job.current_starts_time).tz(job.location.timezone_lookup);
  const endMoment = moment(job.current_ends_time).tz(job.location.timezone_lookup);
  const currentMoment = moment(time).tz(job.location.timezone_lookup);

  if (startMoment.isAfter(endMoment)) {
    throw new Error('current_starts_time is after current_ends_time');
  } else if (endMoment.diff(startMoment, 'days') > 1) {
    throw new Error('current_starts_time and current_ends_time are more than one day apart');
  }

  const timeAtStartDate = setDateToTimeMoment(startMoment, time, false);
  const timeAtEndDate = setDateToTimeMoment(endMoment, time, false);

  if (mode === 'clock_out') {
    if (timeAtStartDate.isBefore(startMoment, 'minute')) {
      return startMoment.clone().add(1, 'day').format('YYYY-MM-DD');
    }
  } else {
    if (timeAtEndDate.isAfter(endMoment, 'minute')) {
      if (!job.location.professional_can_adjust_times && currentMoment.isAfter(endMoment)) {
        return currentMoment.format('YYYY-MM-DD');
      }
      return endMoment.clone().subtract(1, 'day').format('YYYY-MM-DD');
    }
  }

  if (timeAtStartDate.isBetween(startMoment, endMoment)) {
    return timeAtStartDate.format('YYYY-MM-DD');
  }
  if (timeAtEndDate.isBetween(startMoment, endMoment)) {
    return timeAtEndDate.format('YYYY-MM-DD');
  }

  return mode === 'clock_in' ? startMoment.format('YYYY-MM-DD') : endMoment.format('YYYY-MM-DD');
};

export type JobForIsOvernight = Pick<
  IJob,
  'current_ends_time' | 'current_starts_time' | 'location'
>;
export const isOvernight = (job: JobForIsOvernight): boolean =>
  !moment
    .tz(job.current_starts_time, job.location.timezone_lookup)
    .isSame(moment.tz(job.current_ends_time, job.location.timezone_lookup), 'date');
