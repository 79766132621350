import React, { useEffect } from 'react';

import { AdjustmentListItem } from './AdjustmentListItem';
import { EarningsJobListItem } from './EarningsJobListItem';
import { Box, CircularProgress, List } from '@medely/ui-kit';
import { EarningsPendingListProps } from './interface';
import { formatPendingJobForListItem } from './utils';
import { useEarningsEvent, usePendingEarnings } from '../../hooks';
import { useProNavigate } from '../../contexts';

export const EarningsPendingList = ({ onError }: EarningsPendingListProps) => {
  const { adjustments, jobs, isLoading } = usePendingEarnings(onError);
  const { navigate } = useProNavigate();
  const { trackEarningsPendingView, trackPendingListItemClicked } = useEarningsEvent();

  useEffect(() => {
    trackEarningsPendingView();
  }, []);

  if (isLoading) {
    return (
      <Box pt={4}>
        <CircularProgress />
      </Box>
    );
  }

  const formattedJobs = jobs.map((job) => formatPendingJobForListItem(job));
  const sortedPendingItems = [...adjustments, ...formattedJobs].sort((a, b) => {
    const startsDateA = 'starts_date' in a ? a.starts_date : a.created_at;
    const startsDateB = 'starts_date' in b ? b.starts_date : b.created_at;
    return new Date(startsDateA).getTime() - new Date(startsDateB).getTime();
  });

  return (
    <List
      items={sortedPendingItems}
      keyFn={(item) => item.id}
      showDivider={true}
      render={(item) => {
        return 'is_w2' in item ? (
          <EarningsJobListItem
            variant="pending"
            job={item}
            onClick={() => {
              trackPendingListItemClicked({ job_id: item.id });
              navigate('JobDetails', { id: item.id });
            }}
          />
        ) : (
          <AdjustmentListItem
            adjustment={item}
            onClick={() => {
              trackPendingListItemClicked({ expense_adjustment_id: item.id });
              navigate('AdjustmentDetail', { id: item.id });
            }}
          />
        );
      }}
    />
  );
};
