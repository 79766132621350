import { gql } from 'graphql-request';

export const GET_UNPAID_TIMECARD_ADJUSTMENTS = gql`
  query UnpaidTimecardAdjustments($input: ExpenseAdjustmentQueryInput) {
    unpaidTimecardAdjustments(input: $input) {
      amount_cents
      created_at
      id
      job_id
      make_payable_at
      taxable_type
    }
  }
`;

export const GET_EXPENSE_ADJUSTMENT = gql`
  query ExpenseAdjustment($id: Int!) {
    expenseAdjustment(id: $id) {
      amount_cents
      created_at
      id
      taxable_type
      payout {
        ends_time
        id
        status
        starts_time
        w2_submit_by_time
      }
    }
  }
`;
