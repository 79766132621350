import React from 'react';
import { Link } from '@medely/web-components';
import { Box, Label, Text, useConfigurationContext } from '@medely/ui-kit';

interface ICovidProtocolProps {
  description: string;
}

export const CovidProtocol = ({ description }: ICovidProtocolProps) => {
  const { help } = useConfigurationContext();
  return (
    <Box pt={1} pb={2}>
      <Box
        borderColor="controls.outline"
        borderStyle="solid"
        borderWidth={1}
        borderRadius="8px"
        p={1.5}
      >
        <Label
          size="m"
          color="state.error.secondary"
          testId="book-job-confirmation-dialog-covid-title"
        >
          Covid-19 Protocol
        </Label>
        <Text size="m">
          {description}
          {/* TODO(PRO-2839): when the ui-kit link is done, combine this file with the mobile version
            and use it here as an external link to help.covid_requirements  */}
          <Link
            external
            href={help.covid_requirements}
            data-testid="book-job-confirmation-dialog-covid-link"
          >
            Learn more
          </Link>
        </Text>
      </Box>
    </Box>
  );
};
