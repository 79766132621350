export const toSnakeCase = (str: string): string =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((c) => c.toLowerCase())
    .join('_') || '';

export const toPhoneNumber = (str: string): string | null => {
  const cleaned = ('' + str).replace(/\D/g, '');

  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};
/**
 * Returns the string pluralized with the count prefixed
 */
export const pluralize = (count: number, noun: string, suffix = 's', separator = ' '): string =>
  `${count}${separator}${noun}${count !== 1 ? suffix : ''}`;

/**
 * Only returns the string pluralized
 */
export const textPluralize = (count: number, noun: string, suffix = 's'): string =>
  noun + (count !== 1 ? suffix : '');

export const capitalize = (str: string): string =>
  str.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
