import { TermsOfServiceModal, TermsOfServiceModalProps } from '@medely/web-components';
import { gateNames } from 'constants/statsig';
import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { useGate } from 'statsig-react';
import useTermsOfServiceAnalytics from './useTermsOfServiceAnalytics';
import config from 'config';

type ProfessionalTermsOfServiceModalProps = Pick<TermsOfServiceModalProps, 'onError' | 'onSuccess'>;

const ProfessionalTermsOfServiceModal = ({
  onError,
  onSuccess,
}: ProfessionalTermsOfServiceModalProps) => {
  const { isApplicant, currentUser, isAgency, isSaas } = useCurrentUser();
  const { trackViewed, trackCompleted } = useTermsOfServiceAnalytics('Modal');

  const { value: tosOn } = useGate(gateNames.tosOn);
  const isImpersonating = window.localStorage.getItem('impersonating') === 'true';

  const showTosModal = currentUser && tosOn && !isImpersonating && !isSaas;

  const tosGroupKey = isAgency
    ? config.termsOfServiceKey.modal.agency
    : config.termsOfServiceKey.modal.marketplace;

  return (
    <>
      {showTosModal && (
        <TermsOfServiceModal
          disabled={isApplicant && !isAgency}
          groupKey={tosGroupKey}
          onDisplayed={trackViewed}
          onError={onError}
          onSuccess={() => {
            trackCompleted();
            onSuccess?.call(this);
          }}
          signerId={currentUser?.email}
        />
      )}
    </>
  );
};

export default ProfessionalTermsOfServiceModal;
