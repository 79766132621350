import { FormControlLabel, Radio, RadioGroup, Typography } from '@medely/web-components';
import { Field } from 'react-final-form';
import React from 'react';
import { required } from 'core/utils/validation';

const options: { value: string; label: string }[] = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

const Boolean = ({
  name,
  isRequired,
  label,
}: {
  name: string;
  isRequired: boolean;
  label: string;
}) => (
  <>
    <Typography gutterBottom>{label}</Typography>
    <RadioGroup>
      {options.map((o) => (
        <Field
          key={o.value.toString()}
          name={`${name}.value`}
          validate={isRequired ? required : undefined}
          component="input"
          type="radio"
          value={o.value}
        >
          {({ input }) => (
            <FormControlLabel
              key={o.value}
              value={o.value}
              control={<Radio color="primary" />}
              label={o.label}
              checked={input.checked}
              onChange={input.onChange}
            />
          )}
        </Field>
      ))}
    </RadioGroup>
  </>
);

export default Boolean;
