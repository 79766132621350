import { IProfessionalQualification, IQualification } from '@medely/types';
import isEmpty from 'lodash/isEmpty';

export const shouldAutoOpenHelloSign = (
  professionalQualification: IProfessionalQualification | undefined,
): boolean => {
  if (!professionalQualification) {
    return false;
  }

  const {
    status,
    qualification: { instructions },
  } = professionalQualification;

  return (
    status === 'incomplete' &&
    isEmpty(instructions) &&
    isSimpleSignedDocumentQualification(professionalQualification.qualification)
  );
};

export const isSimpleSignedDocumentQualification = (
  qualification: IQualification | undefined,
): boolean => {
  if (!qualification) {
    return false;
  }

  const { qualification_attributes } = qualification;

  return (
    qualification_attributes.length === 1 &&
    qualification_attributes[0].field_type === 'signed_document'
  );
};
