import { useQuery } from '@tanstack/react-query';
import { formatMetersToMiles } from '../../utils';
import { useGooglePlacesLoaderContext } from '../../components/maps/googlePlacesLoaderContext';
import { Loader } from '@googlemaps/js-api-loader';
import { DistanceMatrixReturnProps, useDistanceMatrixProps } from './interface';

const fetchDistance = async ({
  queryKey: [_entity, jobLat, jobLong, userLat, userLong, loader],
}: {
  queryKey: [unknown, number, number, number, number, Loader | null];
}): Promise<google.maps.DistanceMatrixResponse | null> => {
  if (!loader) {
    return null;
  }
  const { DistanceMatrixService } = await loader.importLibrary('routes');

  const userLocation: google.maps.LatLngLiteral = { lat: userLat, lng: userLong };
  const jobLocation: google.maps.LatLngLiteral = { lat: jobLat, lng: jobLong };
  const requestData: google.maps.DistanceMatrixRequest = {
    origins: [userLocation],
    destinations: [jobLocation],
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.IMPERIAL,
  };
  const distanceMatrix = new DistanceMatrixService();
  const response = await distanceMatrix.getDistanceMatrix(requestData);

  return response;
};

export const useDistanceMatrix = ({
  job,
  userLat,
  userLong,
  showMileageInJobDetails,
}: useDistanceMatrixProps): DistanceMatrixReturnProps => {
  const { loader } = useGooglePlacesLoaderContext();

  const jobLat = job?.location?.coordinates?.latitude ?? 0;
  const jobLong = job?.location?.coordinates?.longitude ?? 0;

  const { data, isLoading } = useQuery(
    ['distance', jobLat, jobLong, userLat, userLong, loader],
    fetchDistance,
    {
      enabled: !!userLat && !!userLong && showMileageInJobDetails,
    },
  );

  const distance = formatMetersToMiles(data?.rows[0]?.elements[0]?.distance?.value ?? 0);

  return { data: data ?? null, distance: data ? distance : '', isLoading };
};
