import { ICondition } from '@medely/types';
import { Box, Button, Dialog, DialogProps, RichText, Typography } from '@medely/web-components';
import { useSubmitProfessionalQualification } from 'hooks/useProfessionalQualification';
import React from 'react';

export type AuthorizationDialogProps = {
  condition: ICondition;
} & Pick<DialogProps, 'onClose' | 'open'>;

const AuthorizationDialog = ({ condition, onClose, open }: AuthorizationDialogProps) => {
  const { submit, isSubmitting } = useSubmitProfessionalQualification();

  const qualification = condition.qualifications[0];
  if (!qualification) {
    return <></>;
  }

  const handleSubmit = () =>
    submit(
      { professional_qualification: { qualification_id: qualification.id } },
      {
        onSuccess: onClose,
      },
    );

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      open={open}
      title="Upload Missing Credential"
      content={
        <>
          <Typography weight="medium" data-testid="authorization-dialog-form-title">
            {qualification.long_name}
          </Typography>
          <Box mt={2} data-testid="authorization-dialog-form-instructions">
            <RichText value={qualification.instructions} />
          </Box>
        </>
      }
      actions={
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <Button
            data-testid="authorization-dialog-submit"
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
            sx={{ ml: 2 }}
          >
            Authorize
          </Button>
        </Box>
      }
    />
  );
};

export default AuthorizationDialog;
