import { useMutation } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';

export const useApplicationMutations = (): {
  nextStep: () => void;
  prevStep: () => void;
  isLoading: boolean;
} => {
  const { axios } = useNetworkRequest();

  const { mutate: nextStep, isLoading: nextLoading } = useMutation(() =>
    axios.patch(`/v3/professional/application_wizard/next`),
  );

  const { mutate: prevStep, isLoading: prevLoading } = useMutation(() => {
    return axios.patch(`/v3/professional/application_wizard/back`);
  });

  return {
    nextStep,
    prevStep,
    isLoading: nextLoading || prevLoading,
  };
};
