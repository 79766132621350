import { Box, Text } from '@medely/ui-kit';
import { Timeline } from '../jobs/Timeline';
import React from 'react';
import { Clock, Education, Facility, Money } from '../icons';
import { isAgencyAccount } from '../../utils/account';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { config } from '../../config';
import { Link } from '@medely/web-components';

// This file is only duplicated while the ui-kit Link component is not ready, after that we will only have one file with this information.
const timelineItems = [
  {
    id: 1,
    icon: <Education />,
    label: 'Before you arrive',
    description: (
      <>
        <Text size="m" color="text.secondary">
          Review our{' '}
          <Link href={config.help.setYourselfUpForSuccess} external>
            guide to success
          </Link>{' '}
          to learn how to make the most of Medely.
        </Text>
        <Box mt={1}>
          <Text size="m" color="text.secondary">
            If you have questions about your shift, call the facility to understand the charting
            systems, how to access medications, obtain login access, and learn any other necessary
            protocols.
          </Text>
        </Box>
      </>
    ),
  },
  {
    id: 2,
    icon: <Facility />,
    label: 'When you arrive at the facility',
    description: (
      <Text size="m" color="text.secondary">
        Arrive early to give yourself time to prepare and be ready to work at the scheduled start
        time.
      </Text>
    ),
  },
  {
    id: 3,
    icon: <Clock />,
    label: 'Start and end shift',
    description: (
      <Text size="m" color="text.secondary">
        You can clock in and out simply by using the Medely app directly at your scheduled start
        time.{' '}
        <Link href={config.help.clockInClockOut} external>
          Learn more
        </Link>
      </Text>
    ),
  },
  {
    id: 4,
    icon: <Money />,
    label: 'Get paid',
    description: (
      <Text size="m" color="text.secondary">
        After you clock out of a shift, we will automatically process your payment and deposit it
        into your bank account within 6 days.
      </Text>
    ),
  },
];

const agencyTimelineItems = [
  {
    id: 1,
    icon: <Education />,
    label: 'Before you arrive',
    description: (
      <>
        <Text size="m" color="text.secondary">
          Review our{' '}
          <Link href={config.help.setYourselfUpForSuccess} external>
            guide to success
          </Link>{' '}
          to learn how to make the most of Medely.
        </Text>
        <Box mt={1}>
          <Text size="m" color="text.secondary">
            If you have questions about your shift, call the facility to understand the charting
            systems, how to access medications, obtain login access, and learn any other necessary
            protocols.
          </Text>
        </Box>
      </>
    ),
  },
  {
    id: 2,
    icon: <Facility />,
    label: 'When you arrive at the facility',
    description: (
      <Text size="m" color="text.secondary">
        Arrive early to give yourself time to prepare and be ready to work at the scheduled start
        time.
      </Text>
    ),
  },
  {
    id: 3,
    icon: <Clock />,
    label: 'Start and end shift',
    description: (
      <Text size="m" color="text.secondary">
        You can clock in and out simply by using the Medely app directly at your scheduled start
        time.{' '}
        <Link href={config.help.clockInClockOut} external>
          Learn more
        </Link>
      </Text>
    ),
  },
];

export const WhatToExpectInfo = () => {
  const { currentUser } = useCurrentUser();

  const items = isAgencyAccount(currentUser) ? agencyTimelineItems : timelineItems;
  return <Timeline timelineItems={items} />;
};
