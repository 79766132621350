import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Box, Button, Heading, Icon, List } from '@medely/ui-kit';
import { Divider } from '@medely/web-components';
import { PayoutDetailsLayoutProps } from './interface';
import { PayoutSummary } from './PayoutSummary';
import { useProNavigate } from '../../../contexts';
import { PageLayout } from '../../pageLayout';
import { AdjustmentListItem } from '../AdjustmentListItem';
import { EarningsJobListItem } from '../EarningsJobListItem';
import { formatPayoutForJobList } from './utils';
import useEarningsEvent from '../../../hooks/analytics/useEarningsEvent';

export const PayoutDetailsLayout = ({ payout, onExportPdf }: PayoutDetailsLayoutProps) => {
  const { navigate } = useProNavigate();
  const { trackPayoutJobListItemClicked, trackPayoutAdjustmentListItemClicked } =
    useEarningsEvent();

  const standAloneAdjustments = (payout.expense_adjustments ?? []).filter((adj) => !adj.job_id);
  const jobs = formatPayoutForJobList(payout);

  return (
    <PageLayout>
      <>
        <PayoutSummary
          payout={payout}
          headerButton={
            <Button
              variant="outlined"
              startIcon={<Icon name="arrow-up-from-bracket" color="secondary" />}
              onClick={onExportPdf}
            >
              Export
            </Button>
          }
        />
        {isEmpty(jobs) ? null : (
          <>
            <Divider thick boxProps={{ my: 3 }} />
            <Box mb={1}>
              <Heading size="s">Completed shifts</Heading>
            </Box>
            <List
              showDivider
              items={jobs}
              keyFn={(job) => job.id}
              render={(job) => (
                <EarningsJobListItem
                  variant="payout"
                  job={job}
                  onClick={() => {
                    trackPayoutJobListItemClicked({
                      job_id: job.id,
                      payout_id: payout.id,
                    });
                    navigate('JobDetails', { id: job.id });
                  }}
                />
              )}
            />
          </>
        )}
        {isEmpty(standAloneAdjustments) ? null : (
          <>
            <Divider thick boxProps={{ my: 3 }} />
            <Box mb={1}>
              <Heading size="s">Adjustments</Heading>
            </Box>
            <List
              showDivider
              items={standAloneAdjustments}
              keyFn={(adj) => adj.id}
              render={(adj) => (
                <AdjustmentListItem
                  adjustment={adj}
                  onClick={() => {
                    trackPayoutAdjustmentListItemClicked({
                      expense_adjustment_id: adj.id,
                      payout_id: payout.id,
                    });
                    navigate('AdjustmentDetail', { id: adj.id });
                  }}
                />
              )}
            />
          </>
        )}
      </>
    </PageLayout>
  );
};
