import { OptionType, Select } from '@medely/web-components';
import React from 'react';
import useStates from 'hooks/useStates';

type StateIdSelectProps = {
  abbrev?: boolean;
  value: number | string;
  onChange: (value: number | string) => void;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  onBlur?: (event: any) => void;
};

const StateIdSelect = ({
  abbrev = false,
  value,
  onChange,
  error,
  helperText,
  required = false,
  onBlur,
}: StateIdSelectProps): React.ReactElement | null => {
  const { states } = useStates();
  const options = states.map(({ abbreviation, id, name }) => ({
    value: id,
    label: abbrev ? abbreviation : name,
  }));
  if (!options.length) {
    return null;
  }
  return (
    <Select
      textFieldProps={{
        label: 'State',
        error,
        helperText,
        fullWidth: true,
        required,
        onBlur: onBlur,
      }}
      options={options}
      value={options.find((o) => o.value === value)}
      onChange={(option: OptionType) => {
        if (!option) {
          onChange(null);
        } else {
          onChange(option.value);
        }
      }}
    />
  );
};

export default StateIdSelect;
