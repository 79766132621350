import { IState } from '@medely/types';

export const DISTANCE_RADIUS_FILTER_RADIUS = 50;

export type ICity = {
  label: string;
  latitude: number;
  longitude: number;
};

export type Coordinates = { latitude: number; longitude: number };

export interface ILocationFilter {
  compact?: boolean;
  location_areas?: {
    radius: number;
    coordinates: Coordinates[];
  };
  state_ids?: IState['id'][];
}

export const buildCoordinates = (cities: ICity[] | undefined): Coordinates[] => {
  return !cities
    ? []
    : cities.map((city) => {
        const { latitude, longitude } = city;

        return {
          latitude,
          longitude,
        };
      });
};

export const buildAssignmentsFilterLocationFilter = ({
  compact,
  stateIds,
  cities,
}: {
  compact?: boolean;
  stateIds?: IState['id'][];
  cities?: ICity[];
}): ILocationFilter => ({
  compact,
  location_areas: !!cities?.length
    ? {
        radius: DISTANCE_RADIUS_FILTER_RADIUS,
        coordinates: cities.map(({ latitude, longitude }) => ({ latitude, longitude })),
      }
    : undefined,
  state_ids: stateIds?.length ? stateIds.map((id: IState['id']) => +id) : undefined,
});
