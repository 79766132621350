import React, { useEffect } from 'react';
import { DateParam, useQueryParam, withDefault } from 'use-query-params';

import { Divider } from '@medely/web-components';
import { Box, HStack, Heading } from '@medely/ui-kit';
import { EarningsLayoutProps } from './interface';
import { EarningsExportButton } from '../EarningsExportButton';
import { EarningsHeader } from '../EarningsHeader';
import { EarningsPendingSummary } from '..';
import { getStartAndEndOfMonth } from '../utils';
import { MedelyDateTime } from '@medely/date-time-tools';
import { PayoutsList } from '../PayoutsList';
import { sumPayouts } from '../../../utils/payoutUtils';
import { useEarningsEvent, usePayouts } from '../../../hooks';

export const EarningsLayout = ({ downloadCsvFile, onError }: EarningsLayoutProps) => {
  const defaultDate = MedelyDateTime.now({ tz: 'system' }).startOf('month');
  const [month, setMonth] = useQueryParam(
    'month',
    withDefault(DateParam, new Date(defaultDate.toISO())),
  );
  const { monthStart, monthEnd } = getStartAndEndOfMonth(month);

  const { trackEarningsView } = useEarningsEvent();
  useEffect(() => {
    trackEarningsView({ month: monthStart });
  }, [monthStart]);

  const { payouts, isLoading } = usePayouts({ monthStart, monthEnd, onError });
  const amount = sumPayouts(payouts);

  return (
    <Box testId="earnings-box">
      <EarningsHeader month={month} setMonth={setMonth} amount={amount} />
      <Box pt={3}>
        <EarningsPendingSummary />
      </Box>
      <Divider thick={true} boxProps={{ py: '24px' }} />
      <HStack alignItems="center" justifyContent="space-between">
        <Heading size="m">Payouts</Heading>
        <EarningsExportButton downloadCsvFile={downloadCsvFile} />
      </HStack>
      <PayoutsList isLoading={isLoading} month={month} payouts={payouts} />
    </Box>
  );
};
