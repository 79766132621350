import { IJob } from '@medely/types';
import { Box, InfoBadge } from '@medely/ui-kit';
import React, { ReactElement } from 'react';
import { MedelyDateTime } from '@medely/date-time-tools';
import { pluralize } from '../../utils/text';

interface ICountdownBadgeProps {
  job: IJob;
}

export const CountdownBadge = ({
  job: { current_starts_time, location },
}: ICountdownBadgeProps): ReactElement => {
  const timezone = {
    tz: location?.timezone_lookup ?? 'America/Los_Angeles',
  };
  const startTime = new MedelyDateTime(current_starts_time, timezone);
  const hours = MedelyDateTime.now(timezone).difference(startTime, 'hours');
  const label = `Starts ${hours >= 1 ? `in ${pluralize(hours, 'hour')}` : `within the hour`}`;
  const displayBadge = hours >= 0 && hours <= 12;
  return (
    <Box testId="countdown-badge-container">
      {displayBadge ? <InfoBadge color="info" label={label} /> : undefined}
    </Box>
  );
};
