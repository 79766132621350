import { BottomSheetDialog } from '@medely/web-components';
import React from 'react';
import { Button } from '@medely/ui-kit';
import { ArrowLeft } from '@medely/web-components/icons';
import { EditWorkExperienceFormInfo } from './EditWorkExperienceModalInfo';

type BottomSheetDialogProps = React.ComponentProps<typeof BottomSheetDialog>;

export type EditWorkExperienceModalProps = {
  open: BottomSheetDialogProps['open'];
  onClose: () => void;
  workHistoryId: number | undefined;
};

export const EditWorkExperienceModal = ({
  open,
  onClose,
  workHistoryId,
}: EditWorkExperienceModalProps) => {
  return (
    <BottomSheetDialog
      open={open}
      disableRestoreFocus
      content={
        <>
          <Button variant="text" onClick={onClose} startIcon={<ArrowLeft />}>
            Back
          </Button>
          <EditWorkExperienceFormInfo onClose={onClose} workHistoryId={workHistoryId} />
        </>
      }
      isDesktop={false}
    />
  );
};
