import { Box, Label, Text } from '@medely/ui-kit';
import { Button, Link } from '@medely/web-components';
import React from 'react';
import { config } from '../../config';
import useDialog from '@src/hooks/useDialog';
import { WhatToExpectModal } from '../whatToExpectModal';

//When the link component is ready, we will no longer need a .web and a .mobile: we can delete them and have just one file.
type JobBlurbsProps = {
  isNewToMedely: boolean;
  isNewToFacility: boolean;
  isFavorite: boolean;
};

export const JobBlurbs = ({ isNewToMedely, isNewToFacility, isFavorite }: JobBlurbsProps) => {
  const { open, closeDialog, openDialog } = useDialog();

  if (isNewToMedely) {
    return (
      <>
        <Label size="m" color="text.primary">
          New to Medely?
        </Label>
        <Box mt={0.5} mb={1}>
          <Text size="m" color="text.primary">
            You probably have a few questions. We’re here to help you succeed.
          </Text>
          <Box my={2}>
            <Button variant="link" onClick={() => openDialog()}>
              <Text size="l" color="state.teal.primary">
                View what to expect
              </Text>
            </Button>
          </Box>
          <Link href={config.help.setYourselfUpForSuccess} external>
            <Text size="l" color="state.teal.primary">
              Learn how Medely works
            </Text>
          </Link>
        </Box>
        <WhatToExpectModal open={open} onClose={closeDialog} />
      </>
    );
  } else if (isNewToFacility) {
    return (
      <>
        <Label size="m" color="text.primary">
          This is a new facility for you
        </Label>
        <Box mt={0.5} mb={1}>
          <Text size="m" color="text.primary">
            Please review the facility instructions and location before your shift starts so you can
            be as prepared as possible.
          </Text>
        </Box>
      </>
    );
  } else if (isFavorite) {
    return (
      <>
        <Label size="m" color="text.primary">
          You&apos;re a favorite of this facility
        </Label>
        <Box mt={0.5} mb={1}>
          <Text size="m" color="text.primary">
            As a favorite of this facility, you were given priority in booking this shift.
          </Text>
        </Box>
      </>
    );
  } else {
    return null;
  }
};
