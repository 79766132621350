import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { AdjustmentDetailLayoutProps } from './interface';
import { PageLayout } from '../../pageLayout/PageLayout';
import useEarningsEvent from '../../../hooks/analytics/useEarningsEvent';
import useExpenseAdjustment from '../../../hooks/useExpenseAdjustment';
import { AdjustmentSummary } from './AdjustmentSummary';

export const AdjustmentDetailLayout = ({ id }: AdjustmentDetailLayoutProps) => {
  const { expenseAdjustment } = useExpenseAdjustment({ id });
  const { trackAdjustmentDetailsView } = useEarningsEvent();

  useEffect(() => {
    if (expenseAdjustment?.id) {
      trackAdjustmentDetailsView({ expense_adjustment_id: expenseAdjustment.id });
    }
  }, [expenseAdjustment?.id]);

  if (isEmpty(expenseAdjustment)) {
    return null;
  }

  return (
    <PageLayout>
      <AdjustmentSummary expenseAdjustment={expenseAdjustment} />
    </PageLayout>
  );
};
