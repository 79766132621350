import { graphql } from '../../../../graphql/generated';
import { useGraphQLQuery } from '../../../../hooks/useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';
import { centsToCurrency } from '../../../../utils/centsToCurrency';

type UseWageLogProps = {
  market_id?: number;
  position_id: number;
};

type ProfessionalRateProps = {
  specialty_id: number;
  professional_rate: number[];
};

const ProfessionalRatesGql = graphql(`
  query GetMarketPositionRateSettingsForWorkExperience(
    $input: MarketPositionRateSettingQueryInput
  ) {
    marketPositionRateSettings(input: $input) {
      specialty_id
      professional_rate
    }
  }
`);

const findNonSpecialtyProfessionalRate = (professionalRates: ProfessionalRateProps[]) => {
  return professionalRates.find((rate) => !rate.specialty_id) ?? professionalRates[0];
};

const getMinHourlyRate = (nonSpecialtyProfessionalRate: ProfessionalRateProps) =>
  nonSpecialtyProfessionalRate && !!nonSpecialtyProfessionalRate?.professional_rate?.length
    ? centsToCurrency(nonSpecialtyProfessionalRate?.professional_rate[0])
    : '$0';

const getMaxHourlyRate = (nonSpecialtyProfessionalRate: ProfessionalRateProps) =>
  nonSpecialtyProfessionalRate?.professional_rate?.length > 1
    ? centsToCurrency(nonSpecialtyProfessionalRate?.professional_rate[1])
    : '$0';

export const useWageLog = ({ market_id, position_id }: UseWageLogProps) => {
  const { data: professionalRatesData } = useGraphQLQuery<ResultOf<typeof ProfessionalRatesGql>>({
    operationName: 'GetMarketPositionRateSettingsForWorkExperience',
    query: ProfessionalRatesGql,
    variables: {
      input: {
        search: {
          market_id,
          position_id,
        },
      },
    },
    enabled: !!market_id && !!position_id,
  });

  const professionalRates = professionalRatesData?.marketPositionRateSettings;

  const hasProfessionalRatesData = professionalRates ? professionalRates.length >= 1 : false;

  const getHelperTextInfo = () => {
    if (hasProfessionalRatesData) {
      // @ts-ignore
      const nonSpecialtyProfessionalRate = findNonSpecialtyProfessionalRate(professionalRates);
      const minHourlyRate = getMinHourlyRate(nonSpecialtyProfessionalRate);
      const maxHourlyRate = getMaxHourlyRate(nonSpecialtyProfessionalRate);
      const wageLogHelperText = `Facilities in your area pay between ${minHourlyRate} - ${maxHourlyRate}.`;
      return wageLogHelperText;
    } else {
      return '';
    }
  };

  return {
    wageLogHelperText: getHelperTextInfo(),
    hasProfessionalRatesData,
  };
};
