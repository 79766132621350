import useSignedDocument from 'hooks/useSignedDocument';
import React, { useEffect, useState } from 'react';
import { SignedDocumentModalArgs } from './SignedDocumentDialogContent';

type AutoOpenSignedDocumentModalProps = Omit<
  SignedDocumentModalArgs,
  'professionalQualification'
> & { pqaId: number };

const AutoOpenSignedDocumentModal = ({
  pqaId,
  onClose,
  onSign,
}: AutoOpenSignedDocumentModalProps) => {
  const [hasOpenedDialog, setHasOpenedDialog] = useState(false);

  const { isLoading, showSigningDialog } = useSignedDocument(pqaId, onClose, onSign);

  useEffect(() => {
    if (!isLoading && !hasOpenedDialog) {
      setHasOpenedDialog(true);
      showSigningDialog();
    }
  }, [isLoading, hasOpenedDialog]);

  return <></>;
};

export default AutoOpenSignedDocumentModal;
