import React from 'react';

import { HStack, Text } from '@medely/ui-kit';

type DescriptionListItemProps = {
  label: string;
  value: string | number | React.ReactElement | null;
};

/*
 * Simple ListItem for use with ui-kit's List component.
 * @param label - string to be rendered on the left side.
 * @param value - string or number will be rendered as Text in primary color. ReactElement is allowed for custom content.
 */
export const DescriptionListItem = ({ label, value }: DescriptionListItemProps) => {
  let rightSide = null;
  if (value !== null) {
    rightSide = React.isValidElement(value) ? value : <Text size="m">{value}</Text>;
  }

  return (
    <HStack my={1} justifyContent="space-between" flex={1}>
      <Text size="m" color="text.secondary">
        {label}
      </Text>
      {rightSide}
    </HStack>
  );
};
