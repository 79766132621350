import { IProfessionalQualificationAttribute } from '@medely/types';
import React from 'react';
import { Typography } from '@medely/web-components';

export const TEST_TYPE_OPTIONS = [
  { value: 'tb_blood', label: 'Blood' },
  { value: 'ppd_skin', label: 'PPD' },
  { value: 'chest_x_ray', label: 'X-Ray' },
  { value: 'inh_treatment', label: 'INH Treatment' },
];

type TbProps = {
  attribute: IProfessionalQualificationAttribute;
};

export const YesNoString = ({ attribute }: TbProps) => (
  <Typography variant="body2" color="textSecondary">
    {!attribute.value || attribute.value === 'false' ? 'No' : 'Yes'}
  </Typography>
);

export const TbType = ({ attribute }: TbProps) => (
  <Typography variant="body2" color="textSecondary">
    {TEST_TYPE_OPTIONS.find((t) => t.value === attribute.value)?.label || 'Unknown'}
  </Typography>
);
