import { IJob } from '@medely/types';
import { Analytics } from '@medely/ui-kit';

export const usePerDiemEventTracking = (analytics: Analytics) => {
  return {
    trackBooked: (jobId: IJob['id']) => {
      analytics.track('Staff Booking', { Job_ID: jobId });
      analytics.track({
        Job_ID: jobId,
        context: 'Job Booking',
        subcontext: 'Book Button',
        action: 'Clicked',
      });
    },
    trackCanceled: (jobId: IJob['id']) => {
      analytics.track('Staff Cancelations', { Job_ID: jobId });
      analytics.track({
        Job_ID: jobId,
        context: 'Job Details',
        subcontext: 'Cancel Button',
        action: 'Clicked',
      });
    },
    trackClockIn: (jobId: IJob['id']) => {
      analytics.track('Staff Clock In', { Job_ID: jobId });
      analytics.track({
        Job_ID: jobId,
        context: 'Start Shift',
        subcontext: 'Confirm Button',
        action: 'Clicked',
      });
    },
    trackClockOut: (jobId: IJob['id']) => {
      analytics.track('Staff Clock Out', { Job_ID: jobId });
      analytics.track({
        Job_ID: jobId,
        context: 'End Shift',
        subcontext: 'Confirm Button',
        action: 'Clicked',
      });
    },
    trackShiftDetailsReview: (jobId: IJob['id']) => {
      analytics.track('Job Booking - Viewed', {
        Job_ID: jobId,
        context: 'Job Booking',
        action: 'Viewed',
      });
    },
  };
};
