import { Box, Label, Text } from '@medely/ui-kit';
import { ContentLoader } from '../../components';
import { useCurrentUser, usePositionsWithSpecialties } from '../../hooks';
import React from 'react';

interface IFormattedPosition {
  header: string;
  description: string;
  id: number;
}
export const PositionsAndSpecialtiesView = () => {
  const { currentUser, isLoading: loadingUser } = useCurrentUser();
  const professionalPositions = currentUser?.professional?.positions ?? [];
  const professionalPositionIds = professionalPositions.map(({ id }) => id);
  const { positions: allPositionsWithSpecialties, isLoading: loadingPositions } =
    usePositionsWithSpecialties({
      positionIds: professionalPositionIds,
    });
  const professionalSpecialties = currentUser?.professional?.specialties;

  const formattedSpecialties = (positionId: number): string => {
    const allSpecialties =
      allPositionsWithSpecialties.find(({ id }) => id === positionId)?.specialties ?? [];
    const professionalSpecialtyLabels = allSpecialties
      .filter(({ id }) =>
        professionalSpecialties?.find(({ id: specialtyId }) => id === specialtyId),
      )
      .map(({ label }) => label);
    return professionalSpecialtyLabels.join(' • ');
  };

  const formattedPositions: IFormattedPosition[] = professionalPositions.map((position) => ({
    header: position.display_name ?? '',
    description: formattedSpecialties(position.id),
    id: position.id,
  }));

  return (
    <ContentLoader loading={loadingUser || loadingPositions}>
      {!!formattedPositions.length ? (
        <Box>
          {formattedPositions.map(({ header, description, id }) => (
            <Box key={`position-${id}`} py={1}>
              <Label size="m">{header}</Label>
              <Box py={!!description ? 0.5 : 0}>
                <Text size="m">{description}</Text>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box py={4}>
          <Box pb={0.5}>
            <Label size="m" textAlign="center">
              No positions and specialties
            </Label>
          </Box>
          <Text size="m" textAlign="center">
            Positions and specialties help identify shifts that align with your expertise and
            experience.
          </Text>
        </Box>
      )}
    </ContentLoader>
  );
};
