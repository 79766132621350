import { Box, CircularProgress } from '@medely/web-components';
import React from 'react';

export const CenteredLoader = () => (
  <Box display="flex" justifyContent="center" height="100vh" alignItems="center">
    <CircularProgress />
  </Box>
);

export default CenteredLoader;
