import React, { forwardRef, ReactElement } from 'react';
import { Box, Container } from '@medely/web-components';
import { FixedFooterProps } from './interface';

const FixedFooter = forwardRef(
  ({ children }: FixedFooterProps, ref): ReactElement => (
    <Box
      position="fixed"
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      left={0}
      right={0}
      bottom={0}
      p={2}
      bgcolor={'background.paper'}
      boxShadow={4}
      sx={{ overflowY: 'hidden' }}
      zIndex={11}
      ref={ref}
    >
      <Container maxWidth="sm" sx={{ textAlign: 'end' }}>
        {children}
      </Container>
    </Box>
  ),
);

FixedFooter.displayName = 'FixedFooter';

export { FixedFooter };
