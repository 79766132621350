import {
  ICondition,
  IProfessionalQualification,
  IProfessionalQualificationAttribute,
  IQualification,
  IQualificationAttribute,
} from '@medely/types';
import { ConditionSatisfactionHelper } from '@medely/credentials-tools';
import { SUPPORTED_INPUT_SOURCES } from 'utils/professionalQualifications';

export const getUploadedPQForCondition = (
  condition: ICondition,
  professionalQualifications: IProfessionalQualification[],
): IProfessionalQualification | undefined => {
  const { satisfactionOptions } = new ConditionSatisfactionHelper({
    condition,
    professionalQualifications,
  });

  // There can be more than one PQ that satisfies the condition, but we only want to show one. Eventually this will be changed based on input from product/design and based on how we're handling upload forms for complex requirements (https://medely.atlassian.net/browse/PLAT2-1357), but until then we'll just show the first related pq
  return satisfactionOptions[0]?.qualifications?.[0]?.satisfyingProfessionalQualifications?.[0];
};

// TODO: Remove all usages after https://medely.atlassian.net/browse/
export const getValidProfessionalQualificationAttributes = (
  attributes: IProfessionalQualificationAttribute[] | undefined,
) => {
  if (!attributes) {
    return [];
  }

  return attributes.filter(
    ({ qualification_attribute }) =>
      SUPPORTED_INPUT_SOURCES.includes(qualification_attribute.input_source) &&
      qualification_attribute.active,
  );
};

// TODO: Remove all usages after https://medely.atlassian.net/browse/INT-729
export const getValidQualificationAttributes = (
  attributes: IQualificationAttribute[] | undefined,
) => {
  if (!attributes) {
    return [];
  }

  return attributes?.filter(
    (qualification_attribute) =>
      SUPPORTED_INPUT_SOURCES.includes(qualification_attribute.input_source) &&
      qualification_attribute.active,
  );
};

export const QUALIFICATION_AUTHORIZATION_SLUGS = ['caps_check'];

export const isAuthorizationType = ({ slug }: IQualification) => {
  return QUALIFICATION_AUTHORIZATION_SLUGS.includes(slug);
};
