import { Box, Button, HStack, SubmitButton } from '@medely/ui-kit';
import React from 'react';

type ApplicationButtonsProps = {
  disabled: boolean;
  backClick: () => void;
};

export const ApplicationButtons = ({ disabled, backClick }: ApplicationButtonsProps) => {
  return (
    <HStack>
      <Box mr={0.5} width="100%">
        <Button variant="outlined" disabled={disabled} onClick={backClick} fullWidth>
          Back
        </Button>
      </Box>
      <Box ml={0.5} width="100%">
        <SubmitButton disabled={disabled} fullWidth>
          Continue
        </SubmitButton>
      </Box>
    </HStack>
  );
};
