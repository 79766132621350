import { MedelyDateTime } from '@medely/date-time-tools';
import { EarningsPendingJob } from './interface';

export const formatPendingJobForListItem = (job: EarningsPendingJob) => {
  const { job_billings } = job;

  const jbs = job_billings.map((jb) => ({
    ...jb,
    job: { status: job.status },
  }));

  return {
    ...job,
    job_billings: jbs,
  };
};

export const getStartAndEndOfMonth = (month: Date) => {
  const monthStart = new MedelyDateTime(month.toISOString(), { tz: 'system' })
    .startOf('month')
    .toISO();
  const monthEnd = new MedelyDateTime(month.toISOString(), { tz: 'system' })
    // bump 1 month instead of using endOf('month') to avoid .99999 issues.
    .startOf('month')
    .plus({ months: 1 })
    .toISO();
  return { monthStart, monthEnd };
};
