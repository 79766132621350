import { Link, Text } from '@medely/ui-kit';
import React from 'react';

export const LearnAboutPayoutsLink = () => {
  return (
    <Text size="m">
      <Link
        url="https://help.medely.com/en/articles/9736094-learn-about-payouts"
        variant="text"
        size="m"
      >
        Learn about payouts
      </Link>
    </Text>
  );
};
