export const COVID_REQUIREMENTS = {
  info: {
    vaccine_or_negative_test: 'Proof of vaccine or negative test required. ',
    vaccine_or_declination: 'Proof of vaccine or declination and proof of negative test required. ',
    vaccine_only: 'Proof of vaccine required. ',
    vaccine_booster: 'Proof of COVID vaccine and COVID booster required. ',
    vaccine_booster_or_declination:
      'Proof of COVID vaccine and COVID booster required or an approved Medical or Religious Declination with a negative test. ',
    cms_mandate: 'Proof of a COVID vaccine or Medical/Religious Declination required. ',
  },
  confirmation: {
    vaccine_or_negative_test:
      'Working at this facility requires proof of COVID vaccination or a negative test. ',
    vaccine_or_declination:
      'Working at this facility requires proof of COVID vaccination or a declination with a negative test. ',
    vaccine_only: 'Working at this facility requires proof of COVID vaccination. ',
    vaccine_booster:
      'Working at this facility requires a completed COVID vaccine series and a COVID booster. ',
    vaccine_booster_or_declination:
      'Working at this facility requires a completed COVID vaccine series and a COVID booster or an approved Medical or Religious Declination with a negative test. ',
    cms_mandate:
      'Working at this facility requires a completed COVID vaccine series or an approved Medical or Religious Declination. Please upload your proof to your Medely account under Credentials. ',
  },
};
