import useGraphQLRequest from './useGraphQLRequest';
import { GET_LOCATION } from '../graphql';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

export const useLocation = (id: number) => {
  const { request } = useGraphQLRequest();
  const enabled = !!id;

  const fetchLocation = async ({
    queryKey: [_entity, id],
  }: QueryFunctionContext<[unknown, number]>) => {
    const { location } = await request(GET_LOCATION, {
      id,
    });
    return { location };
  };

  const { data, isLoading } = useQuery(['location', id], fetchLocation, { enabled });
  return { location: data?.location, isLoading };
};

export default useLocation;
