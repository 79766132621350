import React from 'react';
import { CircularAvatar } from './CircularAvatar';
import { Book } from '@medely/web-components/icons';

export const Education = () => {
  return (
    <CircularAvatar color="accent.backing-1" width="40px" height="40px">
      <Book htmlColor="#3E3E83" width="15px" />
    </CircularAvatar>
  );
};
