import React from 'react';

import { IExpenseAdjustment } from '@medely/types';
import { DesignTokenColor, Heading, List, Text, VStack } from '@medely/ui-kit';
import { DescriptionListItem } from '../../DescriptionListItem';
import { centsToCurrency, getPayoutDisplayStatus, getPayoutStatusColor } from '../../../utils';
import DisplayHelpers from '../../../utils/displayHelpers';
import { MedelyDateTime } from '@medely/date-time-tools';
import { IPayoutForPayoutListItem, PayoutListItem } from '../PayoutListItem';
import { useEarningsEvent } from '../../../hooks/analytics/useEarningsEvent';
import { useProNavigate } from '../../../contexts/NavigationContext';

type AdjustmentSummaryProps = {
  expenseAdjustment: Pick<
    IExpenseAdjustment,
    'amount_cents' | 'created_at' | 'id' | 'taxable_type'
  > & {
    payout: IPayoutForPayoutListItem | null;
  };
};

export const AdjustmentSummary = ({ expenseAdjustment }: AdjustmentSummaryProps) => {
  const { navigate } = useProNavigate();
  const { trackAdjustmentDetailsPayoutListItemClicked } = useEarningsEvent();

  const { amount_cents, created_at, id, payout, taxable_type } = expenseAdjustment;
  const amountTextColor: DesignTokenColor =
    amount_cents < 0 ? 'state.error.secondary' : 'text.primary';
  const createdAt = new MedelyDateTime(created_at, { tz: 'system' });
  const pageSubtitle = DisplayHelpers.expenseAdjustment.taxableTypeAsTitle(taxable_type);

  let status = {
    color: 'text.primary' as DesignTokenColor,
    label: 'Pending',
  };
  if (!!payout) {
    const displayStatus = getPayoutDisplayStatus(payout);
    status = {
      color: getPayoutStatusColor(displayStatus) ?? 'text.primary',
      label: displayStatus.toString(),
    };
  }

  const descriptionItems = [
    {
      label: 'Status',
      value: (
        <Text size="m" color={status.color}>
          {status.label}
        </Text>
      ),
    },
    {
      label: 'Issue date',
      value: createdAt.format('M/d/yy'),
    },
    {
      label: 'Amount',
      value: (
        <Text size="m" color={amountTextColor}>
          {centsToCurrency(amount_cents)}
        </Text>
      ),
    },
  ];

  return (
    <VStack gap={2}>
      <Heading size="s">{pageSubtitle}</Heading>
      <List items={descriptionItems} keyFn={(item) => item.label} render={DescriptionListItem} />
      {!!payout && (
        <PayoutListItem
          payout={payout}
          variant="simplified"
          onClick={() => {
            trackAdjustmentDetailsPayoutListItemClicked({
              expense_adjustment_id: id,
              payout_id: payout.id,
            });
            navigate('EarningsPayoutDetails', { id: payout.id });
          }}
        />
      )}
    </VStack>
  );
};
