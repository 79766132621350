import useCurrentUser from 'hooks/useCurrentUser';
import useProfessionalQualifications from 'hooks/useProfessionalQualifications';
import { ConditionOrCheck, ConditionSatisfactionHelper } from '@medely/credentials-tools';
import type { ICondition, IProfessionalQualification, IQualification } from '@medely/types';

export type QualificationToProfessionalQualification = Record<
  IQualification['id'],
  Pick<IProfessionalQualification, 'id' | 'status'>
>;

export type MissingConditionOption = {
  description: string;
  orCondition: ConditionOrCheck;
  qualifications: (IQualification & { description?: string })[];
  qualificationToProfessionalQualification: QualificationToProfessionalQualification;
  remainingActionableQualifications: IQualification[];
  willBeSatisfied: boolean;
};

const professionalQualificationStatusOrder = {
  approved: 1,
  review: 2,
  rejected: 3,
  pending: 4,
  incomplete: 5,
  expired: 6,
  uploaded_in_error: 7,
};

// If the pro has multiple PQs for a qualification, we want to link the one with that is closest to being approved
const findLowestOrderSatisfyingPq = (
  professionalQualifications: Pick<IProfessionalQualification, 'status' | 'id'>[],
) =>
  professionalQualifications.reduce(
    (lowestOrderSatisfyingPq, pq) =>
      professionalQualificationStatusOrder[pq.status] <
      professionalQualificationStatusOrder[lowestOrderSatisfyingPq.status]
        ? pq
        : lowestOrderSatisfyingPq,
    professionalQualifications[0],
  );

type SatisfactionOptionQualificationInput = {
  qualification: Pick<IQualification, 'id'>;
  satisfyingProfessionalQualifications: Pick<IProfessionalQualification, 'status' | 'id'>[];
};

// Link the qualifications of an option to the matching professional qualification that is closest to being approved
// Used for displaying status chips and opening the credential modal
const buildQualificationToProfessionalQualificationRecord = (
  satisfactionOptionQualifications: SatisfactionOptionQualificationInput[],
) =>
  satisfactionOptionQualifications.reduce<QualificationToProfessionalQualification>(
    (acct, item) => {
      if (!item.satisfyingProfessionalQualifications.length) {
        return acct;
      }

      const lowestOrderSatisfyingPq = findLowestOrderSatisfyingPq(
        item.satisfyingProfessionalQualifications,
      );

      return {
        ...acct,
        [item.qualification.id]: {
          id: lowestOrderSatisfyingPq.id,
          status: lowestOrderSatisfyingPq.status,
        },
      };
    },
    {},
  );

// Used to determine if the pro has any remaining actionable qualifications
// When the professional completes an action we want to exit fully out of the complex modal if they have no more actions to take
const buildRemainingActionableQualifications = (
  satisfactionOptionQualifications: SatisfactionOptionQualificationInput[],
) =>
  satisfactionOptionQualifications.reduce<Pick<IQualification, 'id'>[]>(
    (remaining, satisfactionOptionQualification) => {
      if (
        satisfactionOptionQualification.satisfyingProfessionalQualifications.some(
          (pq) => pq.status === 'approved' || pq.status === 'review',
        )
      ) {
        return remaining;
      }
      return [...remaining, satisfactionOptionQualification.qualification];
    },
    [],
  );

const calculateMissingOptions = ({
  condition,
  professionalQualifications,
  satisfiedConditionIds,
}: {
  condition: ICondition;
  professionalQualifications: IProfessionalQualification[];
  satisfiedConditionIds: number[];
}): MissingConditionOption[] => {
  const conditionHelper = new ConditionSatisfactionHelper({
    condition,
    professionalQualifications,
    satisfiedConditionIds,
  });

  return conditionHelper.availableSatisfactionOptions.reduce((acct, option) => {
    const qualifications = option.qualifications.map((q) => ({
      ...q.qualification,
      description: q.description,
    }));
    if (!qualifications.length) {
      return acct;
    }
    const willBeSatisfied = option.qualifications.every((q) => !q.isMissing);
    const qualificationToProfessionalQualification =
      buildQualificationToProfessionalQualificationRecord(option.qualifications);
    const remainingActionableQualifications = buildRemainingActionableQualifications(
      option.qualifications,
    );

    return [
      ...acct,
      {
        description: option.description,
        orCondition: option.orCondition,
        qualificationToProfessionalQualification,
        qualifications,
        remainingActionableQualifications,
        willBeSatisfied,
      } as MissingConditionOption,
    ];
  }, []);
};

const useMissingConditionOptions = (condition: ICondition) => {
  const { currentUser, refetch: refetchUser } = useCurrentUser();
  const { allProfessionalQualifications, refetch: refetchPqs } = useProfessionalQualifications();

  const options = calculateMissingOptions({
    condition,
    professionalQualifications: allProfessionalQualifications,
    satisfiedConditionIds: currentUser.professional.satisfied_condition_ids ?? [],
  });

  const refetchConditionOptions = async () => {
    // Without the inner refetch stale data comes back
    const refetchData = await Promise.all([
      (await refetchUser()).refetch(),
      (await refetchPqs()).refetch(),
    ]);
    const { satisfied_condition_ids } = refetchData[0].data?.professional;
    const professionalQualifications = refetchData[1].data;

    return calculateMissingOptions({
      condition,
      professionalQualifications,
      satisfiedConditionIds: satisfied_condition_ids ?? [],
    });
  };

  return { options, refetchConditionOptions };
};

export default useMissingConditionOptions;
export const exportedForTesting = {
  findLowestOrderSatisfyingPq,
  buildQualificationToProfessionalQualificationRecord,
  buildRemainingActionableQualifications,
};
