import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface IPageTitleContext {
  pageTitle: string;
  setPageTitle: (title: string) => void;
}

const PageTitleContext = createContext<IPageTitleContext>({
  pageTitle: '',
  setPageTitle: () => null,
});

export const usePageTitle = (title?: string) => {
  const context = useContext(PageTitleContext);
  useEffect(() => {
    if (!!title) {
      context.setPageTitle(title);
      return () => {
        context.setPageTitle('');
      };
    }
    return;
  }, [title]);
  return useContext(PageTitleContext);
};

export const PageTitleProvider = ({ children }: { children: ReactNode }) => {
  const [pageTitle, setPageTitle] = useState<string>('');
  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
};
