import { Text, Box, Heading } from '@medely/ui-kit';
import { Divider } from '@medely/web-components';
import React from 'react';
import { WhatToExpectInfo } from '../whatToExpectModal/WhatToExpectInfo.web';
import { CancelationPolicy } from '../jobs/CancelationPolicy.web';
import { JobDetailsListItem } from '../jobs/JobDetailsListItem';
import { Calendar, Clock, MapMarkerAlt } from '@medely/web-components/icons';
import { ShiftDate, ShiftLocation } from '../jobs';
import DisplayHelpers from '../../utils/displayHelpers';
import { PostBookingInfoProps } from './interface';

export const PostBookingInfo = ({
  jobMap,
  job,
  cancelationPolicyButton,
  userCoordinates,
}: PostBookingInfoProps) => {
  return (
    <Box px={1}>
      <Text size="l" color="text.primary">
        You are now scheduled to work a shift at {job.location.name}.
      </Text>
      <Box mt={2}>{jobMap}</Box>
      <Box py={2}>
        <Box py={1}>
          <JobDetailsListItem icon={<MapMarkerAlt width="14px" />} title="Location">
            <ShiftLocation
              bookVariant="post-book"
              distanceVariant="local"
              job={job}
              userCoordinates={userCoordinates}
            />
          </JobDetailsListItem>
        </Box>
        <Box py={1}>
          <JobDetailsListItem icon={<Calendar width="14px" />} title="Date">
            <ShiftDate job={job} />
          </JobDetailsListItem>
        </Box>
        <Box py={1}>
          <JobDetailsListItem icon={<Clock width="14px" />} title="Shift time">
            <Text size="m">{DisplayHelpers.job.time(job, { scheduledShiftStyle: true })}</Text>
          </JobDetailsListItem>
        </Box>
      </Box>
      <Divider />
      <Box py={2}>
        <CancelationPolicy />
        <Box width="45%">{cancelationPolicyButton}</Box>
      </Box>
      <Divider />
      <Box py={2}>
        <Heading size="xs" color="text.primary">
          What to expect
        </Heading>
      </Box>
      <WhatToExpectInfo />
    </Box>
  );
};
