import { CheckboxGroup, Typography } from '@medely/web-components';
import { Field } from 'react-final-form';
import { IQualificationAttribute } from '@medely/types';
import React from 'react';
import { required } from 'core/utils/validation';

const EnumerableMulti = ({
  name,
  isRequired,
  qualificationAttribute,
  withNameLabel,
}: {
  name: string;
  isRequired: boolean;
  qualificationAttribute: IQualificationAttribute;
  withNameLabel?: boolean;
}) => {
  const options =
    qualificationAttribute.data?.values?.map((value, index) => ({
      value,
      label: qualificationAttribute.data?.labels[index],
    })) ?? [];
  return (
    <>
      {withNameLabel && <Typography gutterBottom>{qualificationAttribute.name}</Typography>}
      <Field name={`${name}.value`} validate={isRequired ? required : undefined}>
        {({ input }) => (
          <CheckboxGroup
            options={options}
            selected={input.value as unknown as string[]}
            onChange={input.onChange}
          />
        )}
      </Field>
    </>
  );
};

export default EnumerableMulti;
