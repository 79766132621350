import React from 'react';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';

import { IJobIrregularity } from '@medely/types';
import { useNetworkRequest } from '../../../hooks/useNetworkRequest';
import { WarningAlert } from './WarningAlert';

const IRREGULARITY_LABEL_OVERRIDE: { [key: string]: string } = {
  'Professional created shift': 'recently added shift',
};

type ShiftInReviewAlertProps = {
  jobId: number;
};

export const ShiftInReviewAlert = ({ jobId }: ShiftInReviewAlertProps) => {
  const { irregularities } = useJobIrregularities(jobId);
  if (!irregularities.length) {
    return null;
  }

  const getLowercaseLabel = (label: string) =>
    IRREGULARITY_LABEL_OVERRIDE[label] ?? label.toLocaleLowerCase();
  const labels = irregularities.map((irregularity) => getLowercaseLabel(irregularity.label));
  const joinedLabels =
    labels.slice(0, -1).join(', ') + (labels.length > 1 ? ' and ' : '') + labels.slice(-1);

  return <WarningAlert description={`In review for ${joinedLabels}`} />;
};

const useJobIrregularities = (jobId: number) => {
  const { gqlRequest } = useNetworkRequest();

  const fetchJobIrregularities = async ({ queryKey: [_entity, jobId] }: QueryFunctionContext) => {
    const { jobIrregularities } = await gqlRequest(jobIrregularitiesQuery, {
      input: {
        search: {
          job_id: jobId,
        },
      },
    });

    return jobIrregularities;
  };

  const { isLoading, data } = useQuery(['jobIrregularities', jobId], (queryContext) =>
    fetchJobIrregularities(queryContext),
  );

  return {
    irregularities: (data || []) as Pick<IJobIrregularity, 'id' | 'label'>[],
    isLoading,
  };
};

const jobIrregularitiesQuery = gql`
  query JobIrregularities($input: JobIrregularityQueryInput) {
    jobIrregularities(input: $input) {
      id
      label
    }
  }
`;
