import { notifyRollbar } from '../utils/Rollbar/notifyRollbar';
import { useClearI9 } from './useClearI9';
import useProfessionalQualificationAttribute from './useProfessionalQualificationAttribute';

const useI9 = (professionalQualificationAttributeId: number | undefined) => {
  const { showClearI9Page } = useClearI9();
  const { data, isLoading } = useProfessionalQualificationAttribute(
    professionalQualificationAttributeId,
  );

  const isComplete = data?.metadata ? !data.metadata.webhook_pending : false;

  return {
    isComplete,
    isLoading,
    showI9Page: () => {
      try {
        showClearI9Page(data?.metadata?.i9_pro_url);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('unable to open ClearI9', error);
        notifyRollbar({ errorLabel: 'Unable to open ClearI9', args: data?.metadata });
      }
    },
  };
};

export default useI9;
