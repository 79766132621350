import { Box, isMobile } from '@medely/ui-kit';
import React from 'react';

export const TaskCardLayout = ({ children }: React.PropsWithChildren) => {
  // TODO: Remove these once next step cards are fully multi-platform
  // Components shouldn't set margin, consuming components should handle layout
  const [mx, my, mb] = isMobile() ? [2, 1, undefined] : [0, 0, 1];

  return (
    <Box flex={1} my={my} mx={mx} mb={mb}>
      {children}
    </Box>
  );
};
