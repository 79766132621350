import React from 'react';
import { CircularAvatar } from './CircularAvatar';
import { Clock as IconClock } from '@medely/web-components/icons';

export const Clock = () => {
  return (
    <CircularAvatar color="accent.backing-1" width="40px" height="40px">
      <IconClock htmlColor="#3E3E83" width="20px" />
    </CircularAvatar>
  );
};
