import { Box, Label } from '@medely/ui-kit';
import React, { ReactElement } from 'react';
import { TimelineItem } from './TimelineItem';

type LabelProps = React.ComponentProps<typeof Label>;

export type Item = {
  id: number;
  icon: ReactElement;
  label: LabelProps['children'];
  description: ReactElement;
};

interface TimelineProps {
  timelineItems: Item[];
}

export const Timeline = ({ timelineItems }: TimelineProps) => {
  return (
    <Box>
      {timelineItems.map((item, index) => {
        return (
          <TimelineItem key={item.id} item={item} isLast={index === timelineItems.length - 1} />
        );
      })}
    </Box>
  );
};
