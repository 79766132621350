import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';
import { getTimecardQuery } from '../graphql';
import { IsAgencyAccountInput } from '@src/utils';

export const useTimecard = (id: number, account?: IsAgencyAccountInput) => {
  const { gqlRequest } = useNetworkRequest();
  const query = getTimecardQuery(account);
  const enabled = !!id;

  const getTimecard = async ({
    queryKey: [, timecardId],
  }: QueryFunctionContext<[unknown, number]>) => {
    const { timecard } = await gqlRequest(query, { id: timecardId });
    return timecard;
  };

  const { data, isLoading, isInitialLoading } = useQuery(['timecard', id], getTimecard, {
    enabled,
  });

  return { timecard: data, isLoading: enabled ? isLoading : isInitialLoading };
};

export default useTimecard;
