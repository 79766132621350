import React from 'react';
import { DxGyCampaignContainer } from './DxGyCampaignContainer';

type DxGyFinishCardProps = {
  amount: string;
  goal: string;
};

export const DxGyFinishCard = ({ amount, goal }: DxGyFinishCardProps) => {
  return (
    <DxGyCampaignContainer
      title={`You earned ${amount}!`}
      body={`Way to go! You completed all ${goal} shifts.`}
    />
  );
};
