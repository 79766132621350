import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { IExpenseAdjustment, IJob, IJobBilling, IJobBillingSums, IPayout } from '@medely/types';
import { centsToCurrency } from './centsToCurrency';
import { pluralize } from './text';

export type JobBillingForTotalHours = Pick<IJobBilling, 'total_hours'>;
export const hoursFromJobBillings = (jobBillings: JobBillingForTotalHours[]) =>
  Math.round((jobBillings.reduce((acc, jb) => acc + (jb?.total_hours || 0), 0) ?? 0) * 100) / 100;

export type JobBillingForShiftTotals = Pick<IJobBilling, 'job_id'> & JobBillingForTotalHours;
export const shiftTotalsFromJobBillings = (jobBillings: JobBillingForShiftTotals[]) => {
  const hours = hoursFromJobBillings(jobBillings);
  const numJobs = uniqBy(jobBillings, 'job_id').length;

  return {
    shiftCount: numJobs,
    hours,
  };
};

export const splitAmountsByStatus = (
  jobBillings: (Pick<IJobBilling, 'id' | 'category' | 'payout_total_amount_cents'> & {
    job: Pick<IJob, 'status'> & { isJobSplit: boolean };
    payout?: Pick<IPayout, 'status'> | null;
    disputed_job_billings?: { id: number }[] | null;
  })[],
  expenseAdjustments: (Pick<IExpenseAdjustment, 'amount_cents'> & {
    payout?: Pick<IPayout, 'status'> | null;
  })[],
  isSplitPayEnabled: boolean,
) => {
  const breakdown = {
    payout: 0,
    inReview: 0,
    disputed: 0,
    waitingForPayout: 0,
  };

  jobBillings.forEach((jb) => {
    if (!!jb.payout) {
      breakdown.payout += jb.payout_total_amount_cents;
    } else {
      if (isSplitPayEnabled && jb.job.isJobSplit) {
        if (jb.job.status === 'disputed' && !isEmpty(jb.disputed_job_billings)) {
          breakdown.disputed += jb.payout_total_amount_cents;
        } else if (jb.job.status === 'held_for_dispute_review' && jb.category === 'disputable') {
          breakdown.inReview += jb.payout_total_amount_cents;
        } else if (jb.category === 'standard') {
          // job_billings will not be paid unless they're standard.
          breakdown.waitingForPayout += jb.payout_total_amount_cents;
        }
      } else {
        if (jb.job.status === 'disputed') {
          breakdown.disputed += jb.payout_total_amount_cents;
        } else if (jb.job.status === 'held_for_dispute_review') {
          breakdown.inReview += jb.payout_total_amount_cents;
        } else {
          breakdown.waitingForPayout += jb.payout_total_amount_cents;
        }
      }
    }
  });

  expenseAdjustments.forEach((adjustment) => {
    if (!adjustment.payout) {
      breakdown.waitingForPayout += adjustment.amount_cents;
    } else {
      breakdown.payout += adjustment.amount_cents;
    }
  });

  return breakdown;
};

export const pendingHours = (
  jobBillings: (Pick<IJobBilling, 'id' | 'total_hours'> & {
    payout?: Pick<IPayout, 'status'> | null;
  })[],
) => {
  return hoursFromJobBillings(jobBillings.filter((jb) => !jb.payout));
};

export type JobBillingBreakdown = {
  key: string;
  label: string;
  description?: string;
  total: string;
};
export type JobForJobBillingBreakdown = Pick<
  IJob,
  | 'is_w2'
  | 'payout_base_hourly_rate_cents'
  | 'payout_double_overtime_multiplier'
  | 'payout_on_call_hourly_rate_cents'
  | 'payout_overtime_multiplier'
  | 'payout_taxable_hourly_rate_cents'
> & {
  job_billing_sums: Pick<
    IJobBillingSums,
    | 'payout_callback_amount_cents'
    | 'payout_callback_hours'
    | 'payout_regular_amount_cents'
    | 'payout_regular_hours'
    | 'payout_total_amount_cents'
    | 'payout_overtime_amount_cents'
    | 'payout_overtime_hours'
    | 'payout_double_amount_cents'
    | 'payout_double_hours'
    | 'total_on_call_hours'
  >;
};
export function breakdownJobByRate({
  is_w2,
  job_billing_sums,
  payout_base_hourly_rate_cents,
  payout_double_overtime_multiplier,
  payout_on_call_hourly_rate_cents,
  payout_overtime_multiplier,
  payout_taxable_hourly_rate_cents,
}: JobForJobBillingBreakdown): JobBillingBreakdown[] {
  const res = [];

  const baseRate = is_w2 ? payout_taxable_hourly_rate_cents : payout_base_hourly_rate_cents;
  const getDescription = (hours: number, rate: number) =>
    `${pluralize(hours, 'hour')} x ${centsToCurrency(rate)}`;

  // Only one of Regular, Oncall, or Callback should display per shift.
  if (!!job_billing_sums.payout_regular_amount_cents) {
    res.push({
      key: 'regular_rate',
      label: 'Regular rate',
      description: getDescription(job_billing_sums.payout_regular_hours, baseRate),
      total: centsToCurrency(job_billing_sums.payout_regular_amount_cents),
    });
  } else if (!!job_billing_sums.payout_callback_amount_cents) {
    const callbackRate = Math.floor(
      job_billing_sums.payout_callback_amount_cents / job_billing_sums.payout_callback_hours,
    );
    res.push({
      key: 'callback_rate',
      label: 'Call back rate',
      description: getDescription(job_billing_sums.payout_callback_hours, callbackRate),
      total: centsToCurrency(job_billing_sums.payout_callback_amount_cents),
    });
  } else if (!!job_billing_sums.total_on_call_hours) {
    res.push({
      key: 'oncall_rate',
      label: 'On call rate',
      description: getDescription(
        job_billing_sums.total_on_call_hours,
        payout_on_call_hourly_rate_cents,
      ),
      total: centsToCurrency(job_billing_sums.payout_total_amount_cents),
    });
  }

  if (!!job_billing_sums.payout_overtime_amount_cents) {
    res.push({
      key: 'ot1_rate',
      label: is_w2 ? 'Overtime rate' : 'Extended rate',
      description: getDescription(
        job_billing_sums.payout_overtime_hours,
        Math.floor(baseRate * payout_overtime_multiplier),
      ),
      total: centsToCurrency(job_billing_sums.payout_overtime_amount_cents),
    });
  }
  if (!!job_billing_sums.payout_double_amount_cents) {
    res.push({
      key: 'ot2_rate',
      label: is_w2 ? 'Overtime rate' : 'Extended rate',
      description: getDescription(
        job_billing_sums.payout_double_hours,
        Math.floor(baseRate * payout_double_overtime_multiplier),
      ),
      total: centsToCurrency(job_billing_sums.payout_double_amount_cents),
    });
  }

  return res;
}
