import { DatePicker, Typography } from '@medely/web-components';
import { Field } from 'react-final-form';
import React from 'react';
import moment from 'moment';
import { required } from 'core/utils/validation';

const Date = ({
  name,
  isRequired,
  withNameLabel,
  label,
}: {
  name: string;
  isRequired: boolean;
  withNameLabel?: boolean;
  label: string;
}) => (
  <>
    {withNameLabel && <Typography gutterBottom>{label}</Typography>}
    <Field name={`${name}.value`} validate={isRequired ? required : undefined}>
      {({ input, meta }) => (
        <DatePicker
          label={label}
          variant="default"
          value={moment(input.value)}
          onChange={(value) => input.onChange(value.format())}
          error={meta.touched && meta.invalid}
          helperText={meta.touched && meta.error}
          fullWidth
        />
      )}
    </Field>
  </>
);

export default Date;
