import React, { ReactElement, ReactNode, createContext, useContext } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

interface IContext {
  apiKey: string;
  loader: Loader | null;
}

interface IGooglePlacesLoaderProviderProps {
  apiKey: string;
  children: ReactNode;
}

const GooglePlacesLoaderContext = createContext<IContext>({
  apiKey: '',
  loader: null,
});

export const GooglePlacesLoaderProvider = ({
  apiKey,
  children,
}: IGooglePlacesLoaderProviderProps): ReactElement => {
  const loader = new Loader({
    apiKey,
  });

  return (
    <GooglePlacesLoaderContext.Provider value={{ apiKey, loader }}>
      {children}
    </GooglePlacesLoaderContext.Provider>
  );
};

export const useGooglePlacesLoaderContext = (): IContext => useContext(GooglePlacesLoaderContext);
