import React from 'react';
import { isEmpty } from 'lodash';
import { HStack, CircularProgress, List } from '@medely/ui-kit';

import { IPayout } from '@medely/types';
import { PayoutListItem } from './PayoutListItem';
import { useProNavigate } from '../../contexts/NavigationContext';
import { useEarningsEvent } from '../../hooks';
import { PayoutsListEmptyState } from './PayoutsListEmptyState';

type PayoutsListProps = {
  isLoading: boolean;
  month: Date;
  payouts: IPayout[];
};

export const PayoutsList = ({ isLoading, month, payouts }: PayoutsListProps) => {
  const { navigate } = useProNavigate();
  const { trackEarningsPayoutListItemClicked } = useEarningsEvent();

  if (isLoading) {
    return (
      <HStack justifyContent="center" py={8}>
        <CircularProgress />
      </HStack>
    );
  } else if (isEmpty(payouts)) {
    return <PayoutsListEmptyState month={month} />;
  }

  return (
    <List
      items={payouts}
      keyFn={(payout) => payout.id}
      showDivider
      render={(payout) => {
        return (
          <PayoutListItem
            variant="detailed"
            payout={payout}
            onClick={() => {
              trackEarningsPayoutListItemClicked({ payout_id: payout.id });
              navigate('EarningsPayoutDetails', { id: payout.id });
            }}
          />
        );
      }}
    />
  );
};
