import { IProfessionalQualificationAttribute } from '@medely/types';
import React from 'react';
import Pdf from './Pdf';

type ImageProps = {
  attribute: IProfessionalQualificationAttribute;
};

const Image = ({ attribute }: ImageProps) => {
  const isPdf = attribute.value.split('?')[0].split('.').pop() === 'pdf';
  return isPdf ? (
    <Pdf attribute={attribute} />
  ) : (
    <img src={attribute.value} style={{ width: '90%' }} />
  );
};

export default Image;
