import { IProfessionalQualificationAttribute } from '@medely/types';
import { Typography } from '@medely/ui-kit';
import { CircularProgress } from '@medely/web-components';
import AutoOpenSignedDocumentModal from 'components/credentials/professionalQualificationModal/signedDocumentModal/AutoOpenSignedDocumentModal';
import useProfessionalQualification from 'hooks/useProfessionalQualification';
import React, { useCallback, useMemo } from 'react';
import { NumberParam, useQueryParams } from 'use-query-params';

declare global {
  interface Window {
    ReactNativeWebView: { postMessage(msg: string): void };
  }
}

export const SignedDocumentWebView = () => {
  const [{ professionalQualificationId }] = useQueryParams({
    professionalQualificationId: NumberParam,
  });
  const { professionalQualification, editAsync, isLoading } = useProfessionalQualification(
    professionalQualificationId,
  );

  const handleSign = useCallback(
    async (data: IProfessionalQualificationAttribute) => {
      return editAsync(
        {
          id: data.professional_qualification_id,
          professional_qualification: {
            qualification_id: professionalQualification.qualification.id,
            professional_qualification_attributes_attributes: [
              {
                qualification_attribute_id: data.qualification_attribute_id,
                value: data.value,
              },
            ],
          },
        },
        {
          onSuccess: () => window.ReactNativeWebView.postMessage('done'),
        },
      );
    },
    [professionalQualification],
  );

  const handleClose = useCallback(() => {
    window.ReactNativeWebView.postMessage('close');
  }, []);

  const pqaId = useMemo(
    () =>
      professionalQualification?.professional_qualification_attributes?.find(
        (pqa) => pqa.qualification_attribute.field_type === 'signed_document',
      )?.id || null,
    [professionalQualification],
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isLoading && !pqaId) {
    return <Typography>Unable to open form. Please try again later.</Typography>;
  }

  return <AutoOpenSignedDocumentModal pqaId={pqaId} onSign={handleSign} onClose={handleClose} />;
};
