import React from 'react';
import { config } from '@src/config';
import { useProNavigate } from '@src/contexts';
import { SetYourselfUpInfo } from './SetYourselfUpInfo';

export const SetYourselfUp = () => {
  const { openUrl } = useProNavigate();
  const onClick = () => openUrl(config.help.setYourselfUpForSuccess);

  return <SetYourselfUpInfo onClick={onClick} />;
};
