import { Box, Button, Form, HStack, OptionType, SubmitButton, yup } from '@medely/ui-kit';
import { type ResultOf } from '@graphql-typed-document-node/core';
import React from 'react';
import ClinicalExperienceAndSpecialties from './ClinicalExperienceAndSpecialties';
import { PositionsSelect } from './PositionsSelect';
import useAllPositionsInMarkets from '../../../../hooks/useAllPositionsInMarkets';
import { FixedFooterLayout } from '../../../../components/FixedFooterLayout';
import { RecommendedPositions } from './RecommendedPositions';
import usePositionInfoMutations from '../../../../hooks/usePositionInfoMutations';
import { useApplicationMutations } from '../../../../hooks/useApplicationMutations';
import { SelectedPosition } from './types';
import { getRecommendedPositions } from '../../util';
import { graphql } from '@src/graphql/generated';
import { useGraphQLQuery } from '../../../../hooks/useGraphQLRequest';

const FIRST_STEP = 1;
const LAST_STEP = 3;

const PositionsGql = graphql(`
  query GetCurrentUserForPositions($id: Int!, $location_ids: [Int!]!) {
    me {
      professional {
        professional_positions {
          position_id
          years_of_experience
        }
        specialties {
          id
        }
      }
    }
  }
`);

export const Positions = () => {
  const { data, isLoading: currentUserLoading } = useGraphQLQuery<ResultOf<typeof PositionsGql>>({
    operationName: 'GetCurrentUserForPositions',
    query: PositionsGql,
    variables: {},
  });
  const currentUser = data?.me;

  const { positions, isLoading: allPositionsLoading } = useAllPositionsInMarkets();
  const { submitPositionInfo } = usePositionInfoMutations();
  const { nextStep, prevStep, isLoading: mutationIsLoading } = useApplicationMutations();
  const [step, setStep] = React.useState(1);
  const [initialSubmittedPositions, setInitialSubmittedPositions] = React.useState<
    SelectedPosition[]
  >([]);
  const [recommendedPositions, setRecommendedPositions] = React.useState<OptionType[]>([]);

  const schema = yup.object({
    professional_positions: yup.object({
      positions: yup
        .array()
        .of(
          yup.object({
            id: yup.number(),
            years_of_experience: yup.number().when([], (_, sch) => {
              return step === LAST_STEP ? sch.required('Required') : sch.nullable();
            }),
            specialty_ids: yup.array().of(yup.number()),
          }),
        )
        .min(1, 'Please select at least one position')
        .required(),
    }),
  });

  const initialSelectedPositions = currentUser?.professional?.professional_positions?.map(
    (professionalPosition) => {
      const fullPosition = positions.find((p) => p.id === professionalPosition?.position_id);
      const specialtyIds = !!fullPosition?.specialties
        ? fullPosition.specialties
            .map((s) => String(s?.id))
            .filter((id) =>
              currentUser?.professional?.specialties?.some((s) => String(s?.id) === id),
            )
        : [];
      return {
        id: professionalPosition?.position_id,
        // @ts-ignore years_of_experience missing on type IProfessionalPosition
        years_of_experience: professionalPosition.years_of_experience,
        specialty_ids: specialtyIds,
      };
    },
  );

  const defaultValues = {
    professional_positions: {
      positions: initialSelectedPositions || [],
    },
  };

  const handleSubmit = async (values: any) => {
    await submitPositionInfo(values, {
      onSuccess: () => {
        const selectedPositions: SelectedPosition[] = [...values.professional_positions.positions];
        if (step === FIRST_STEP) {
          setInitialSubmittedPositions(selectedPositions);
        }
        handleContinue(selectedPositions);
      },
    });
  };

  const handleBackClick = () => {
    if (step === FIRST_STEP) {
      prevStep();
      return;
    }
    if (step === LAST_STEP && !recommendedPositions.length) {
      setStep(1);
      return;
    }
    setStep(step - 1);
  };

  const handleContinue = (initialPositions: SelectedPosition[]) => {
    if (step === LAST_STEP) {
      nextStep();
      return;
    }
    if (step === FIRST_STEP) {
      const newRecommendedPositions = getRecommendedPositions(positions, initialPositions);
      setRecommendedPositions(newRecommendedPositions);
      if (!newRecommendedPositions.length) {
        setStep(3);
        return;
      }
    }
    setStep(step + 1);
  };

  const disableButtons = mutationIsLoading || currentUserLoading || allPositionsLoading;
  const baseValuesLoading = currentUserLoading || allPositionsLoading;

  return !baseValuesLoading ? (
    <Form defaultValues={defaultValues} handleSubmit={handleSubmit} schema={schema}>
      <FixedFooterLayout
        footerContent={
          <HStack>
            <Box mr={0.5} width="100%">
              <Button
                onClick={handleBackClick}
                variant="outlined"
                fullWidth
                disabled={disableButtons}
              >
                Back
              </Button>
            </Box>
            <Box ml={0.5} width="100%">
              <SubmitButton fullWidth disabled={disableButtons} testId="positions-continue-button">
                Continue
              </SubmitButton>
            </Box>
          </HStack>
        }
      >
        {step === 1 && <PositionsSelect allPositions={positions} />}
        {step === 2 && (
          <RecommendedPositions
            allPositions={positions}
            initialSubmittedPositions={initialSubmittedPositions}
          />
        )}
        {step === 3 && <ClinicalExperienceAndSpecialties allPositions={positions} />}
      </FixedFooterLayout>
    </Form>
  ) : null;
};
