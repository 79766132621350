import React from 'react';
import { CircularAvatar } from './CircularAvatar';
import { FileInvoiceDollar } from '@medely/web-components/icons';

export const Money = () => {
  return (
    <CircularAvatar color="accent.backing-1" width="40px" height="40px">
      <FileInvoiceDollar htmlColor="#3E3E83" width="20px" />
    </CircularAvatar>
  );
};
