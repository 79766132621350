import { axios } from 'utils/networkRequests';
import config from 'config';
import HelloSign from 'hellosign-embedded';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

const createSignatureRequestUrl = async (requestUrl: string) => {
  return axios.post(requestUrl);
};

export const useHelloSign = () => {
  const helloSignClient = useMemo(
    () =>
      new HelloSign({
        clientId: config.helloSignId,
        skipDomainVerification: process.env.NODE_ENV === 'development',
      }),
    [],
  );

  const { mutate: requestHelloSignUrl } = useMutation(createSignatureRequestUrl, {
    onSettled: () => null,
  });

  const showHelloSignDialog = useCallback(
    (requestUrl: string) => {
      requestHelloSignUrl(requestUrl, {
        onSuccess: ({ data: { url } }) => {
          helloSignClient.open(url);
        },
      });
    },
    [helloSignClient, requestHelloSignUrl],
  );

  return { helloSignClient, showHelloSignDialog };
};
