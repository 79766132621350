import { MaskedInput, Typography } from '@medely/web-components';
import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { required } from 'core/utils/validation';

const Integer = ({
  name,
  isRequired,
  withNameLabel,
  label,
}: {
  name: string;
  isRequired: boolean;
  withNameLabel?: boolean;
  label: string;
}) => {
  const [val, setVal] = useState<string>();
  return (
    <>
      {withNameLabel && <Typography gutterBottom>{label}</Typography>}
      <Field name={`${name}.value`} validate={isRequired ? required : undefined}>
        {({ input, meta }) => {
          // for some reason the masked input isn't working w input.value and input.onChange. need to come back to this
          const onChange = (value: unknown) => {
            setVal(value as string);
            input.onChange(value);
          };
          return (
            <MaskedInput
              mask="integer"
              fullWidth
              value={val}
              onChange={onChange}
              error={meta.touched && meta.error}
              helperText={meta.touched && meta.error}
            />
          );
        }}
      </Field>
    </>
  );
};

export default Integer;
