import { FieldProps, FieldRenderProps } from 'react-final-form';

// Can resolve this by removing unneeded escapes, but not sure where to verify the change
// eslint-disable-next-line no-useless-escape
const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const MESSAGES = {
  email: 'Email is not valid',
  minLength: (length: number) => `Minimum is ${length} characters`,
  inRange: (min: number, max: number) => `Required to be between ${min} and ${max}`,
  required: 'Required',
};

type ValidationFn<InputValue = unknown> = FieldProps<
  InputValue,
  FieldRenderProps<InputValue>
>['validate'];

// eslint-disable-next-line max-len
export const compose =
  (...fns: ValidationFn[]): ValidationFn =>
  (...args) =>
    fns.filter(Boolean).reduceRight((acc, fn) => acc || fn(...args), undefined);

export const required: ValidationFn = (v) => (!v ? MESSAGES.required : undefined);

export const email: ValidationFn<string> = (v) => {
  if (!v) {
    return undefined;
  }
  return EMAIL_REGEXP.test(v) ? undefined : MESSAGES.email;
};

// eslint-disable-next-line max-len
export const minLength =
  (length: number): ValidationFn<string> =>
  (v) =>
    v?.length < length ? MESSAGES.minLength(length) : undefined;

/**
 * Validates if number is between min and max INCLUSIVE
 * @param  {number} min Minimum value should be less than max
 * @param  {number} max Maximum value should be greater than min
 * @return {String | undefined} undefined if valid and a string indicating the error otherwise.
 */
export const inRange =
  (min: number, max: number): ValidationFn<number | null | undefined> =>
  (v) => {
    const value = Number(v) || 0;
    if (value < min || value > max) {
      return MESSAGES.inRange(min, max);
    }
    return undefined;
  };

export const password = compose(minLength(8), required);
