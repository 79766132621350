import React, { ReactElement } from 'react';
import ProfessionalQualificationModal, {
  ProfessionalQualificationModalProps,
} from './ProfessionalQualificationModal';

type CredentialModalArgs = ProfessionalQualificationModalProps;

const CredentialModal = (props: CredentialModalArgs): ReactElement => (
  <ProfessionalQualificationModal {...props} />
);

export default CredentialModal;
