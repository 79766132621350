import { useFirebaseContext } from '@medely/ui-kit';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import CenteredLoader from 'components/CenteredLoader';
import Login from './Login';

export default function MobileAuthLogin() {
  const { signInWithToken, googleUser } = useFirebaseContext();
  const history = useHistory();
  const [mobileToken] = useQueryParam('mobileToken', StringParam);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleRedirect = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const hasRedirect = urlParams.has('redirect');

    if (hasRedirect) {
      const params = urlParams.get('params');
      const url = params
        ? `${urlParams.get('redirect').concat(params)}`
        : `${urlParams.get('redirect')}`;
      return location.replace(url);
    }

    location.replace('/');
  };

  useEffect(() => {
    const signInAndRedirect = async (mobileToken: string) => {
      try {
        await signInWithToken(mobileToken);
        handleRedirect();
      } catch (e) {
        console.error(e);
        setError(e);
      }
    };

    if (mobileToken) {
      signInAndRedirect(mobileToken);
    }
  }, [googleUser, mobileToken]);

  return <>{!!error ? <Login /> : <CenteredLoader />}</>;
}
