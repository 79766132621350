import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';
import { AxiosResponse } from 'axios';
import omit from 'lodash/omit';

type EditValuesProps = {
  wage_log?:
    | {
        hourly_rate_cents: number;
        position_id: number | undefined;
      }
    | undefined;
  id: number | undefined;
  position_id: number | undefined;
  location_type_id: number;
  bedside_care: boolean;
  employment_type: number;
  currently_work_here: boolean;
  start_date: Date;
  end_date: Date;
};

const useWorkExperienceInfoMutations = (): {
  submitWorkExperienceInfo: UseMutateFunction<AxiosResponse<any>, any, any, unknown>;
  editWorkExperienceInfo: UseMutateFunction<AxiosResponse<any>, any, any, unknown>;
} => {
  const { axios } = useNetworkRequest();
  const queryClient = useQueryClient();

  const { mutate: submitWorkExperienceInfo } = useMutation(
    (values) => axios.post(`/v3/professional/work_histories`, values),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['currentUser']);
        queryClient.invalidateQueries(['GetWorkHistoriesForExperienceSummaryView']);
        queryClient.invalidateQueries(['GetWorkHistoryForWorkExperience']);
        queryClient.invalidateQueries(['GetCurrentUserForWorkExperience']);
      },
    },
  );

  const { mutate: editWorkExperienceInfo } = useMutation(
    (values: EditValuesProps) =>
      axios.put(`/v3/professional/work_histories/${values.id}`, {
        work_history: omit(values, 'id'),
      }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['currentUser']);
        queryClient.invalidateQueries(['GetWorkHistoriesForExperienceSummaryView']);
        queryClient.invalidateQueries(['GetWorkHistoryForWorkExperience']);
        queryClient.invalidateQueries(['GetCurrentUserForWorkExperience']);
      },
    },
  );

  return {
    submitWorkExperienceInfo,
    editWorkExperienceInfo,
  };
};

export default useWorkExperienceInfoMutations;
