import { StringParam, useQueryParams } from 'use-query-params';

interface IUseUtmQueryParams {
  utm: {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
  };
}

export const useUtmQueryParams = (): IUseUtmQueryParams => {
  const [utm] = useQueryParams({
    utm_source: StringParam,
    utm_medium: StringParam,
    utm_campaign: StringParam,
    utm_term: StringParam,
    utm_content: StringParam,
  });

  return { utm };
};
