import { IProfessionalQualification, IProfessionalQualificationAttribute } from '@medely/types';
import useSignedDocument from 'hooks/useSignedDocument';
import React from 'react';
import {
  DialogContent,
  Box,
  Typography,
  DialogActions,
  Button,
  RichText,
} from '@medely/web-components';
import { STATUS_MAP } from 'utils/credentialUtils';
import { AxiosResponse } from 'utils/networkRequests';

export type SignedDocumentModalArgs = {
  onClose?: () => void;
  professionalQualification: IProfessionalQualification;
  onSign: (data: IProfessionalQualificationAttribute) => Promise<AxiosResponse<any>>;
};

const SignedDocumentDialogContent = ({
  professionalQualification,
  onClose,
  onSign,
}: SignedDocumentModalArgs) => {
  const { isLoading, showSigningDialog } = useSignedDocument(
    professionalQualification.professional_qualification_attributes[0].id,
    onClose,
    onSign,
  );

  return (
    <>
      <DialogContent>
        <Box mb={2} data-testid="signed-document-modal">
          <Typography
            gutterBottom
            weight="medium"
            data-testid="signed-document-modal-qualification-name"
          >
            {professionalQualification.qualification.long_name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ color: STATUS_MAP[professionalQualification.status].color }}
            data-testid="signed-document-modal-qualification-status"
          >
            {STATUS_MAP[professionalQualification.status].label}
          </Typography>
        </Box>
        <Box mt={2} data-testid="qualification-form-instructions">
          <RichText value={JSON.stringify(professionalQualification.qualification.instructions)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          data-testid="signed-document-modal-cancel-button"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={showSigningDialog}
          loading={isLoading}
          disabled={isLoading}
          data-testid="signed-document-modal-sign-button"
        >
          Review and Sign
        </Button>
      </DialogActions>
    </>
  );
};

export default SignedDocumentDialogContent;
