import { IProfessionalQualificationAttribute } from '@medely/types';
import { PdfPreview, Typography } from '@medely/web-components';
import React from 'react';

const SignedDocument = ({ attribute }: { attribute: IProfessionalQualificationAttribute }) => {
  if (attribute.metadata?.webhook_pending) {
    return (
      <Typography
        variant="body2"
        color="textSecondary"
        data-testid="credential-modal-view-content-credential-status"
      >
        Signature required.
      </Typography>
    );
  }

  return <PdfPreview src={attribute.value} />;
};

export default SignedDocument;
