import { ICondition } from '@medely/types';
import useGraphQLRequest from './useGraphQLRequest';
import { GET_EXPIRING_CONDITIONS } from '@medely/pro-multi-platform';
import useCurrentUser from './useCurrentUser';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { DEFAULT_REFETCH_INTERVAL } from 'constants/graphql';

type UseNearFutureExpiringConditionsProps = {
  refetch?: boolean;
};

type Return = {
  expiringConditions: ICondition[];
  isLoading: boolean;
};

const useNearFutureExpiringConditions = ({
  refetch,
}: UseNearFutureExpiringConditionsProps = {}): Return => {
  const { request } = useGraphQLRequest();
  const { currentUser } = useCurrentUser();

  const fetchNearFutureExpiringConditions = async ({
    queryKey: [_entity, professional_id],
  }: QueryFunctionContext) => {
    const { conditions } = await request(GET_EXPIRING_CONDITIONS, {
      input: {
        search: {
          expiring_conditions: {
            professional_id,
          },
        },
      },
    });
    return conditions;
  };

  const { data, isLoading } = useQuery(
    ['expiringConditions', currentUser?.professional.id],
    fetchNearFutureExpiringConditions,
    {
      refetchInterval: refetch ? DEFAULT_REFETCH_INTERVAL : undefined,
    },
  );
  return {
    expiringConditions: data ?? [],
    isLoading,
  };
};

export default useNearFutureExpiringConditions;
