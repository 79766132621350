import DisplayHelpers from 'utils/displayHelpers';
import { IProfessionalQualificationAttribute } from '@medely/types';
import React from 'react';
import { Typography } from '@medely/web-components';

type DateProps = {
  attribute: IProfessionalQualificationAttribute;
};

const Date = ({ attribute }: DateProps) => (
  <Typography variant="body2" color="textSecondary">
    {DisplayHelpers.dateTime.date(attribute.value)}
  </Typography>
);

export default Date;
