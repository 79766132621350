import React, { ReactElement, ReactNode, useEffect } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { MedelyLogo } from '@medely/web-components/icons';
import { useTheme } from '@medely/web-components/themes';
import { Box, Heading, Text, useAnalytics } from '@medely/ui-kit';
import ProArchivedView from './ProArchivedView';

const ProRejectedView = ({ theme }) => (
  <>
    <MedelyLogo color={theme.palette.primary.main} />
    <Box py={3}>
      <Heading size="xs">Thank you for your interest in joining Medely.</Heading>
    </Box>
    <Box pb={3}>
      <Text size="m">
        At this time, your application has been declined because you do not meet one or more of
        Medely&apos;s requirements.
      </Text>
    </Box>
  </>
);

interface IProViewProps {
  children?: ReactNode | ReactNode[];
}

const ProViewContent = ({ children }: IProViewProps): ReactElement => {
  const { isArchived, isRejected, currentUser } = useCurrentUser();
  const accountId = currentUser.id;
  const theme = useTheme();

  const analytics = useAnalytics();

  const isArchivedOrRejected = isArchived || isRejected;

  const trackName = isRejected
    ? 'Product Application - Application Rejected - Viewed'
    : 'Product Application - Application Archived - Viewed';

  const applicationVersion = isRejected ? 'rejected' : 'archived';

  useEffect(() => {
    if (isArchivedOrRejected) {
      analytics.track(trackName, {
        account_id: accountId,
        application_version: applicationVersion,
      });
    }
  }, []);

  if (isArchived) {
    return <ProArchivedView />;
  } else if (isRejected) {
    return <ProRejectedView theme={theme} />;
  } else {
    return <>{children}</>;
  }
};

export default ProViewContent;
