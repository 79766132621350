import { FormControlLabel, Checkbox as MuiCheckbox } from '@medely/web-components';
import { Field } from 'react-final-form';
import React from 'react';
import { required } from 'core/utils/validation';

const Checkbox = ({
  name,
  isRequired,
  label,
}: {
  name: string;
  isRequired: boolean;
  label: string;
}) => (
  <Field
    name={`${name}.value`}
    validate={isRequired ? required : undefined}
    component="input"
    type="checkbox"
  >
    {({ input }) => <FormControlLabel control={<MuiCheckbox />} label={label} {...input} />}
  </Field>
);

export default Checkbox;
