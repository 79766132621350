import { useGraphQLContext } from '@medely/ui-kit';
import { DocumentNode } from 'graphql';
import {
  type QueryFunctionContext,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { gqlRequest } from '../utils/networkRequests/gqlRequest';
import { useNetworkRequest } from './useNetworkRequest';

type RequestFn<TResult, TRequestParams> = (
  query: string | DocumentNode,
  variables?: TRequestParams,
) => Promise<TResult>;

type UseGraphqlRequestResult<TResult, TRequestParams> = {
  gqlRequest: RequestFn<TResult, TRequestParams>;
  request: RequestFn<TResult, TRequestParams>;
};

const useGraphQLRequest = <TResult = any, TRequestParams = any>(): UseGraphqlRequestResult<
  TResult,
  TRequestParams
> => {
  const { transform } = useGraphQLContext();

  const request: RequestFn<TResult, TRequestParams> = async (query, variables) => {
    return gqlRequest<TResult>(transform(query), variables);
  };

  return { gqlRequest: request, request };
};

export default useGraphQLRequest;

type UseGraphQLQueryOptions<TVariables, TRequestParams> = UseQueryOptions & {
  operationName: string;
  paramsFn?: (variables: TVariables) => TRequestParams;
  query: string | DocumentNode;
  variables: TVariables;
};

export const useGraphQLQuery = <TResult = unknown, TVariables = unknown, TRequestParams = unknown>(
  options: UseGraphQLQueryOptions<TVariables, TRequestParams>,
) => {
  const { request } = useGraphQLRequest<TResult, TRequestParams>();
  const { paramsFn, query, variables, ...queryOptions } = options;

  const queryFn = async ({
    queryKey: [, queryFnParams],
  }: QueryFunctionContext<(QueryKey | TVariables | undefined)[]>) => {
    const params = paramsFn
      ? paramsFn(queryFnParams as TVariables)
      : (queryFnParams as TRequestParams);

    return request(query, params);
  };

  return useQuery(
    [options.operationName as unknown as QueryKey, variables],
    queryFn,
    queryOptions as any,
  );
};

export const useLegacyGraphQLRequest = () => {
  const { transform } = useGraphQLContext();
  const { gqlRequest } = useNetworkRequest();

  const request = async (query: string, variables?: unknown) => {
    return gqlRequest(transform(query), variables);
  };

  return { gqlRequest: request, request };
};
