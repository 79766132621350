import React, { ReactElement } from 'react';
import { Box, Text, useConfigurationContext } from '@medely/ui-kit';
import { Link } from '@medely/web-components';

export const ProfessionalTerms = (): ReactElement => {
  const { professionalTermsPathname } = useConfigurationContext();
  return (
    <Box bgcolor="surface.backing-1" p={2} borderRadius="8px">
      <Text size="m">
        {`By booking this shift, you agree to Medely's `}
        {/* TODO(PRO-2839): when the ui-kit link is done, combine this file with the mobile version
            and use it here as an external link to professionalTermsPathname  */}
        <Link href={professionalTermsPathname} target="_blank" rel="noopener noreferrer">
          Professional Service Agreement
        </Link>
      </Text>
    </Box>
  );
};
