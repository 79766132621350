import { Alert } from '@medely/ui-kit';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import CenteredLoader from '../CenteredLoader';

const TIMEOUT_IN_SECONDS = 5000;
export const HelpArticle = () => {
  const [hasReachedTimeout, setHasReachedTimeout] = useState(false);
  const [{ url }] = useQueryParams({ url: StringParam });
  const getIntercomCookieSession = document.cookie.match(new RegExp('intercom-session-([^;]+)'));
  const validIntercomSession = Boolean(
    getIntercomCookieSession && getIntercomCookieSession[0].split('=')[1] !== '',
  );
  const canShowArticle = Boolean(url && validIntercomSession);

  useEffect(() => {
    setTimeout(() => setHasReachedTimeout(true), TIMEOUT_IN_SECONDS);
  }, []);

  useEffect(() => {
    if (canShowArticle) {
      window.location.replace(url);
    }
  }, [canShowArticle]);

  if (hasReachedTimeout && !canShowArticle) {
    return (
      <Alert
        severity="error"
        variant="filled"
        title="Unable to open this link"
        description="Please try again."
        hideCloseIcon
      />
    );
  }

  return <CenteredLoader />;
};
