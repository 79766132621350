import { FileInput, Typography } from '@medely/web-components';
import { Field } from 'react-final-form';
import React, { Fragment } from 'react';
import { requiredImage } from 'core/utils/validation';

const Image = ({
  name,
  label,
  withNameLabel,
  isRequired,
}: {
  name: string;
  label: string;
  isRequired: boolean;
  withNameLabel?: boolean;
}) => (
  <>
    {withNameLabel && <Typography gutterBottom>{label}</Typography>}
    <Field name={`${name}.value`} validate={isRequired ? requiredImage : undefined}>
      {({ input, meta }) => {
        return (
          <div onClick={() => input.onBlur()}>
            <FileInput
              id={`${name}-file-input`}
              fullWidth
              accept={['.png', '.jpg', '.jpeg', '.pdf']}
              labelPrimary={`${label}${isRequired ? '*' : ''}`}
              error={meta.touched && meta.error}
              {...input}
            />
          </div>
        );
      }}
    </Field>
  </>
);

export default Image;
