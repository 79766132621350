import { graphql } from '../../../../../graphql/generated';
import { useGraphQLQuery } from '../../../../../hooks/useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const ExperienceSummaryInitialCurrentUserGql = graphql(`
  query GetInitialCurrentUserForExperienceSummaryView($id: Int!, $location_ids: [Int!]!) {
    me {
      professional {
        markets {
          id
        }
      }
    }
  }
`);

const ExperienceSummaryCurrentUserGql = graphql(`
  query GetCurrentUserForExperienceSummaryView(
    $id: Int!
    $location_ids: [Int!]!
    $market_ids: [Int!]!
  ) {
    me {
      id
      professional {
        professional_positions {
          position {
            id
            ...ExperienceSummaryCardPosition
          }
        }
      }
    }
  }
`);

const ExperienceSummaryViewWorkHistoriesGql = graphql(`
  query GetWorkHistoriesForExperienceSummaryView($input: WorkHistoryQueryInput) {
    workHistories(input: $input) {
      position_id
      ...ExperienceSummaryCardWorkHistory
    }
  }
`);

export const useExperienceSummaryViewQueries = () => {
  const { data: initialCurrentUserData, isLoading: initialCurrentUserIsLoading } = useGraphQLQuery<
    ResultOf<typeof ExperienceSummaryInitialCurrentUserGql>
  >({
    operationName: 'GetInitialCurrentUserForExperienceSummaryView',
    query: ExperienceSummaryInitialCurrentUserGql,
    variables: {},
  });

  const initialCurrentUser = initialCurrentUserData?.me;

  const { data: currentUserData, isLoading: currentUserIsLoading } = useGraphQLQuery<
    ResultOf<typeof ExperienceSummaryCurrentUserGql>
  >({
    operationName: 'GetCurrentUserForExperienceSummaryView',
    query: ExperienceSummaryCurrentUserGql,
    variables: {
      market_ids: initialCurrentUser?.professional?.markets?.map((market) => market.id),
    },
    enabled: !!initialCurrentUser,
  });

  const currentUser = currentUserData?.me;

  const { data: workHistoriesData, isLoading: workExperienceIsLoading } = useGraphQLQuery<
    ResultOf<typeof ExperienceSummaryViewWorkHistoriesGql>
  >({
    operationName: 'GetWorkHistoriesForExperienceSummaryView',
    query: ExperienceSummaryViewWorkHistoriesGql,
    variables: {},
  });
  const workHistories = workHistoriesData?.workHistories;

  const isLoading = initialCurrentUserIsLoading || currentUserIsLoading || workExperienceIsLoading;

  return {
    currentUser,
    workHistories,
    isLoading,
  };
};
