import { FormControlLabel, Radio, RadioGroup, Typography } from '@medely/web-components';
import { Field } from 'react-final-form';
import { IQualificationAttribute } from '@medely/types';
import React from 'react';
import { required } from 'core/utils/validation';

const Enumerable = ({
  name,
  isRequired,
  qualificationAttribute,
  withNameLabel,
}: {
  name: string;
  isRequired: boolean;
  qualificationAttribute: IQualificationAttribute;
  withNameLabel?: boolean;
}) => {
  const options =
    qualificationAttribute.data?.values?.map((value, index) => ({
      value,
      label: qualificationAttribute.data?.labels[index],
    })) ?? [];
  return (
    <>
      {withNameLabel && <Typography gutterBottom>{qualificationAttribute.name}</Typography>}
      <RadioGroup>
        {options.map((o) => (
          <Field
            key={o.value}
            name={`${name}.value`}
            validate={isRequired ? required : undefined}
            component="input"
            type="radio"
            value={o.value}
          >
            {({ input }) => (
              <FormControlLabel
                key={o.value}
                value={o.value}
                control={<Radio color="primary" />}
                label={o.label}
                checked={input.checked}
                onChange={input.onChange}
              />
            )}
          </Field>
        ))}
      </RadioGroup>
    </>
  );
};

export default Enumerable;
