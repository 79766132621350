import React from 'react';
import { DxGyCampaignContainer } from './DxGyCampaignContainer';

type DxGyInProgressCardProps = {
  endTime: string;
  goal: string;
  amount: string;
  progress: string;
};

export const DxGyInProgressCard = ({
  endTime,
  goal,
  amount,
  progress,
}: DxGyInProgressCardProps) => {
  return (
    <DxGyCampaignContainer
      title={`Complete ${goal} shifts, earn ${amount}!`}
      body={`${progress} of ${goal} shifts completed. Keep it up and book another shift! Ends ${endTime}`}
    />
  );
};
