import { TaskCard } from '@medely/ui-kit';
import React from 'react';
import { TaskCardLayout } from '../TaskCard';

type SetYourselfUpInfoProps = {
  onClick: () => void;
};

export const SetYourselfUpInfo = ({ onClick }: SetYourselfUpInfoProps) => {
  return (
    <TaskCardLayout>
      <TaskCard
        iconProps={{ name: 'info-circle', color: 'primary' }}
        title="Set yourself up for success"
        body="Learn how to find and book shifts with Medely."
        onClick={onClick}
      />
    </TaskCardLayout>
  );
};
