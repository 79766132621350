import React from 'react';
import { CircularAvatar } from './CircularAvatar';
import { CalendarCheck } from '@medely/web-components/icons';

export const Confirm = () => {
  return (
    <CircularAvatar color="accent.backing-1" width="40px" height="40px">
      <CalendarCheck htmlColor="#3E3E83" width="20px" />
    </CircularAvatar>
  );
};
