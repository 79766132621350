import { IQualification } from '@medely/types';
import useGraphQLRequest from './useGraphQLRequest';
import { GET_QUALIFICATION } from '@medely/pro-multi-platform';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

const useQualification = (
  id?: number,
): {
  qualification: IQualification | undefined;
  isLoading: boolean;
} => {
  const { request } = useGraphQLRequest();

  const getQualification = async ({
    queryKey: [_entity, id],
  }: QueryFunctionContext<[unknown, number]>) => {
    const { qualification } = await request(GET_QUALIFICATION, { id });
    return qualification;
  };

  const { data, isLoading } = useQuery(['qualification', id], getQualification, { enabled: !!id });

  return {
    qualification: data,
    isLoading,
  };
};

export default useQualification;
