import { IProfessionalQualification } from '@medely/types';
import { axios } from 'utils/networkRequests';
import { QueryFunctionContext, useMutation, useQuery } from '@tanstack/react-query';
import { GET_PROFESSIONAL_QUALIFICATION } from '@medely/pro-multi-platform';
import { omit } from 'lodash';
import useCurrentUser from './useCurrentUser';
import { useAnalytics } from '@medely/ui-kit';
import useGraphQLRequest from './useGraphQLRequest';

export const useTrackSuccessfulPQSubmission = () => {
  const { currentUser } = useCurrentUser();
  const analytics = useAnalytics();

  return {
    track: (qualificationId: number) => {
      analytics.track('PQ Submitted', {
        professionalId: currentUser?.professional?.id,
        qualificationId,
      });
    },
  };
};

export const useEditProfessionalQualification = () => {
  const { track } = useTrackSuccessfulPQSubmission();

  const {
    mutateAsync: editAsync,
    mutate: edit,
    isLoading: isEditing,
  } = useMutation(
    (values: any) =>
      axios.put(`/v3/professional/professional_qualifications/${values.id}`, omit(values, ['id'])),
    {
      onSuccess: (_, variables) => {
        track(variables.professional_qualification.qualification_id);
      },
    },
  );

  return { edit, editAsync, isEditing };
};

export const useSubmitProfessionalQualification = () => {
  const { track } = useTrackSuccessfulPQSubmission();
  const { mutateAsync: submit, isLoading: isSubmitting } = useMutation(
    (values: any) => axios.post(`/v3/professional/professional_qualifications`, values),
    {
      onSuccess: (_, variables) => {
        track(variables.professional_qualification.qualification_id);
      },
    },
  );

  return { submit, isSubmitting };
};

export const useSubmitPendingProfessionalQualification = () => {
  const { track } = useTrackSuccessfulPQSubmission();
  const { mutate: submitPending, isLoading: isSubmittingPending } = useMutation(
    ({ qualification_id }: { qualification_id: number; professional_id: number }) =>
      axios.post(`v3/professional/professional_qualifications/pending`, {
        professional_qualification: {
          qualification_id,
        },
      }),
    {
      onSuccess: (_, variables) => {
        track(variables.qualification_id);
      },
    },
  );

  return { submitPending, isSubmittingPending };
};

const useProfessionalQualification = (id: number) => {
  const { request } = useGraphQLRequest();

  const { edit, editAsync, isEditing } = useEditProfessionalQualification();
  const { submit, isSubmitting } = useSubmitProfessionalQualification();
  const { submitPending, isSubmittingPending } = useSubmitPendingProfessionalQualification();

  const fetchProfessionalQualification = async ({
    queryKey: [_entity, id],
  }: QueryFunctionContext): Promise<IProfessionalQualification> => {
    const { professionalQualification } = await request(GET_PROFESSIONAL_QUALIFICATION, {
      id,
    });
    return professionalQualification;
  };

  const { data, isFetching } = useQuery(
    ['professionalQualification', id],
    fetchProfessionalQualification,
    {
      enabled: !!id,
      staleTime: 0, // staletime set to 0 to ensure we can load images with not-expired url's
    },
  );

  return {
    edit,
    editAsync,
    isLoading: isEditing || isFetching || isSubmitting || isSubmittingPending,
    professionalQualification: data,
    submit,
    submitPending,
  };
};

export default useProfessionalQualification;
