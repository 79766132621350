import { graphql } from '../../../../graphql/generated';

export const AddMoreMonthsOfWorkExperienceWorkHistoryGql = graphql(`
  fragment AddMoreMonthsOfWorkExperienceWorkHistory on WorkHistory {
    id
    currently_work_here
    employment_type
    end_date
    location_type_id
    start_date
    position {
      id
    }
    wage_logs {
      hourly_rate_cents
    }
  }
`);

export const AddMoreMonthsOfWorkExperienceCurrentUserGql = graphql(`
  fragment AddMoreMonthsOfWorkExperienceCurrentUser on Account {
    id
    professional {
      professional_positions {
        has_recent_experience
        position {
          display_name
          id
        }
      }
    }
    ...WorkExperienceFormCurrentUser
    ...UseWorkExperienceCurrentUser
  }
`);
