import { FileInput, Typography } from '@medely/web-components';
import { Field } from 'react-final-form';
import React from 'react';
import { required } from 'core/utils/validation';

const PDF = ({
  name,
  label,
  isRequired,
  withNameLabel,
}: {
  name: string;
  label: string;
  isRequired: boolean;
  withNameLabel?: boolean;
}) => (
  <>
    {withNameLabel && <Typography gutterBottom>{label}</Typography>}
    <Field name={`${name}.value`} validate={isRequired ? required : undefined}>
      {({ input }) => (
        <FileInput
          id={`${name}-file-input`}
          fullWidth
          accept={['.pdf']}
          labelPrimary={label}
          {...input}
        />
      )}
    </Field>
  </>
);

export default PDF;
