import React, { useEffect } from 'react';
import { LoadingState } from '../../components/LoadingState';
import { Box, Form, Heading, useAnalytics } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../../../components/FixedFooterLayout';
import { ApplicationButtons } from '../../components/ApplicationButtons';
import { useWorkExperience } from './useWorkExperience';
import { useGetWorkExperienceInitialData } from './useGetWorkExperienceInitialData';
import { createWorkExperienceValidation } from './workExperienceSchema';
import { WorkExperienceForm } from './WorkExperienceForm';
import useWorkExperienceInfoMutations from '../../../../hooks/useWorkExperienceInfoMutations';
import { SetPageProps, ValuesProps } from './types';
import { FragmentType, parseFragmentData } from '../../../../graphql/generated';
import {
  AddMoreMonthsOfWorkExperienceCurrentUserGql,
  AddMoreMonthsOfWorkExperienceWorkHistoryGql,
} from './addMoreMonthsOfWorkExperienceWorkHistoryQueries';

type AddMoreMonthsOfWorkExperienceProps = {
  setPage: (value: SetPageProps) => void;
  workHistories?: FragmentType<typeof AddMoreMonthsOfWorkExperienceWorkHistoryGql>[];
  currentUser?: FragmentType<typeof AddMoreMonthsOfWorkExperienceCurrentUserGql>;
  isLoading: boolean;
};

export const AddMoreMonthsOfWorkExperience = ({
  setPage,
  workHistories: inputWorkHistories,
  currentUser: inputCurrentUser,
  isLoading,
}: AddMoreMonthsOfWorkExperienceProps) => {
  const workHistories = parseFragmentData(
    AddMoreMonthsOfWorkExperienceWorkHistoryGql,
    inputWorkHistories,
  );
  const currentUser = parseFragmentData(
    AddMoreMonthsOfWorkExperienceCurrentUserGql,
    inputCurrentUser,
  );

  const analytics = useAnalytics();
  const { locationTypesLoading } = useGetWorkExperienceInitialData();
  const { submitWorkExperienceInfo } = useWorkExperienceInfoMutations();

  const { createPayload, getAddedMonthsFromMultiplesWorkHistories } = useWorkExperience({
    currentUser,
    //@ts-ignore
    workHistories,
  });

  const baseValuesLoading = locationTypesLoading || isLoading;

  const recentPositionData = currentUser?.professional?.professional_positions?.find(
    (p) => p.has_recent_experience,
  );

  const positionId = recentPositionData?.position.id;
  const positionName = recentPositionData?.position.display_name;
  const crnPositionId = 25;
  const minMonthsNeeded = positionId === crnPositionId ? 6 : 8;
  const initialAddedMonths = getAddedMonthsFromMultiplesWorkHistories(positionId);
  const missingMonths = minMonthsNeeded - initialAddedMonths;

  const handleBack = () => {
    setPage('experienceSummary');
  };

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createPayload({ ...values, position_id: positionId });
    submitWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        window.location.reload();
      },
    });
  };

  const disableButtons = baseValuesLoading;

  useEffect(() => {
    analytics.track('Product Application Add More Experience - Viewed', {
      account_id: currentUser?.id,
      context: 'Product Application Add More Experience',
      subcontext: null,
      action: 'Viewed',
    });
  }, []);

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <Form handleSubmit={handleSubmit} schema={createWorkExperienceValidation(missingMonths)}>
        <FixedFooterLayout
          footerContent={<ApplicationButtons disabled={disableButtons} backClick={handleBack} />}
        >
          <Box pb={2}>
            <Heading size="s" color="text.primary">
              Add at least {missingMonths} more months of recent {positionName} experience
            </Heading>
          </Box>
          <WorkExperienceForm
            currentUser={currentUser}
            positionId={positionId}
            hidePositionField={true}
            setPage={setPage}
          />
        </FixedFooterLayout>
      </Form>
    </>
  );
};
