import { graphql } from '../graphql/generated';
import { useGraphQLQuery } from './useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const MarketsGql = graphql(`
  query Markets($input: MarketQueryInput) {
    markets(input: $input) {
      id
      display_name
      market_locations {
        zip_code
      }
    }
  }
`);

export type Market = Exclude<ResultOf<typeof MarketsGql>['markets'], undefined | null>[number];

export type UseMarketsOptions = {
  zipCode: string;
};

export type UseMarketsResult = {
  isLoading: boolean;
  markets: Market[];
};

export const useMarkets = ({ zipCode }: UseMarketsOptions): UseMarketsResult => {
  const { data: marketsData, isLoading } = useGraphQLQuery<ResultOf<typeof MarketsGql>>({
    operationName: 'GetMarkets',
    query: MarketsGql,
    enabled: zipCode.length === 5,
    variables: {
      input: {
        search: {
          state_zip_codes: [zipCode],
        },
      },
    },
  });

  return {
    isLoading,
    markets: marketsData?.markets ?? [],
  };
};
