import Date from './Date';
import DefaultAttribute from './DefaultAttribute';
import { IProfessionalQualificationAttribute } from '@medely/types';
import Image from './Image';
import React from 'react';
import State from './State';
import Text from 'components/credentials/attributeFields/Text';
import Pdf from './Pdf';
import { TbType, YesNoString } from './TbTypes';
import SignedDocument from './SignedDocument';

const COMPONENT_MAPPING = {
  boolean: YesNoString,
  date: Date,
  enumerable: TbType,
  expiration_date: Date,
  image_back: Image,
  image_front: Image,
  pdf: Pdf,
  signed_document: SignedDocument,
  state: State,
  text: Text,
  valid_date: Date,
};

type AttributeProps = {
  attribute: Partial<IProfessionalQualificationAttribute>;
};

const Attribute = ({ attribute }: AttributeProps) => {
  const Component = COMPONENT_MAPPING[attribute.qualification_attribute.field_type];
  return !!Component ? (
    <Component attribute={attribute} data-testid="attribute" />
  ) : (
    <DefaultAttribute attribute={attribute} />
  );
};

export default Attribute;
