import { type Prediction, GOOGLE_PLACES_STATE_TYPE } from '../constants/places';

export const filterGooglePlacesPredictions = (predictions: Prediction[]): Prediction[] => {
  const mappedPlaceIds = new Map<string, Prediction>();
  const results: Prediction[] = [];

  predictions.forEach((prediction) => {
    if (mappedPlaceIds.get(prediction.place_id)?.types.includes(GOOGLE_PLACES_STATE_TYPE)) {
      return;
    }

    mappedPlaceIds.set(prediction.place_id, prediction);
    results.push(prediction);
  });

  return results;
};
