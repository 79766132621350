/** Based on web/src/utils/displayHelpers.ts */

import { tz as momentTz } from 'moment-timezone';

import { MedelyDateTime } from '@medely/date-time-tools';
import {
  DisputeStatus,
  IDispute,
  IJob,
  ILocation,
  IPayout,
  IPosition,
  IState,
  ShiftType,
} from '@medely/types';
import { InfoBadgeColor } from '@medely/ui-kit';
import { TAXABLE_TYPE_LABEL_OVERRIDE } from '../constants';
import { JobForIsOvernight, isOvernight } from './jobUtils';
import { pluralize } from './text';

const humanizeDate = (date: string, tz = 'system') =>
  new MedelyDateTime(date, { tz }).humanizedDate;
const titleize = (input: string) =>
  input.replace(/_/g, ' ').replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());

const taxableTypeAsTitle = (taxable_type: string) => {
  return (
    TAXABLE_TYPE_LABEL_OVERRIDE[taxable_type as keyof typeof TAXABLE_TYPE_LABEL_OVERRIDE] ||
    titleize(taxable_type)
  );
};

const jobTime = (
  job: Pick<
    IJob,
    | 'approved_clock_in'
    | 'approved_clock_out'
    | 'current_ends_time'
    | 'current_starts_time'
    | 'guaranteed_minimum_hours'
    | 'status'
  > & { location: { timezone_lookup?: string } },
  {
    withShiftLength = true,
    scheduledShiftStyle = false,
  }: { withShiftLength?: boolean; scheduledShiftStyle?: boolean } = {},
): string | null => {
  const {
    current_starts_time,
    approved_clock_in,
    current_ends_time,
    approved_clock_out,
    location,
    guaranteed_minimum_hours,
    status,
  } = job;
  if (!current_starts_time || !current_ends_time) {
    return null;
  }

  const timezone = location?.timezone_lookup || momentTz.guess();
  const startsTime = approved_clock_in || current_starts_time;
  const endsTime = approved_clock_out || current_ends_time;
  const start = new MedelyDateTime(startsTime, {
    tz: timezone,
  });

  const end = new MedelyDateTime(endsTime, {
    tz: timezone,
  });

  const hoursDiff = start.difference(end, 'hours').toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const hours =
    status === 'canceled_with_fee'
      ? guaranteed_minimum_hours
      : Math.max(guaranteed_minimum_hours ?? 0, +hoursDiff);

  return scheduledShiftStyle
    ? `${start.militaryTime} - ${end.militaryTime}${
        withShiftLength ? ` (${pluralize(hours ?? 0, 'hr')})` : ``
      }`
    : `${start.militaryTime}-${end.militaryTime}${
        withShiftLength ? ` • ${pluralize(hours ?? 0, 'hr')}` : ``
      }`;
};

type JobForDate = Pick<IJob, 'starts_date'>;
const jobDate = (job: JobForDate, format: 'fullDay' | 'short'): string | null => {
  const date = new MedelyDateTime(job.starts_date, { tz: 'system' });

  if (!date) {
    return null;
  }

  if (format === 'fullDay') {
    return date.humanizedDate;
  }

  return date.dateShort;
};

type JobForShiftType = Pick<IJob, 'shift_type'> & JobForIsOvernight;
const shiftType = (job: JobForShiftType, withOvernight = true) => {
  const { shift_type } = job;

  const isOverNight = withOvernight && isOvernight(job);

  if (shift_type === ('regular' as ShiftType)) {
    return isOverNight ? 'Regular shift overnight' : 'Regular shift';
  }

  if (shift_type === ('on_call' as ShiftType)) {
    return isOverNight ? 'On call overnight' : 'On call';
  }

  if (shift_type === ('call_back' as ShiftType)) {
    return isOverNight ? 'Call back overnight' : 'Call back';
  }

  if (shift_type === ('missed' as ShiftType)) {
    return 'Missed shift';
  }
  if (shift_type === ('cancelled' as ShiftType)) {
    return 'Canceled shift';
  }

  return '';
};

const positions = (job: { positions: Pick<IPosition, 'display_name'>[] }) => {
  return job?.positions?.map((s) => s.display_name).join(', ') || '–';
};

type JobForPaymentStatusLabel = Pick<IJob, 'status'> & {
  disputes?: Pick<IDispute, 'status'>[];
  job_billings: { payout?: Pick<IPayout, 'status'> }[];
  expense_adjustments: { payout?: Pick<IPayout, 'status'> }[];
};
const jobPaymentStatusLabel = ({
  status,
  disputes,
  job_billings,
  expense_adjustments,
}: JobForPaymentStatusLabel): { label: string; color: InfoBadgeColor } => {
  if (status === 'disputed') {
    const isWaitingOnPro = disputes?.some(
      (dispute) => dispute.status === DisputeStatus.WaitingForProToRespond,
    );
    return {
      label: isWaitingOnPro ? 'Disputed' : 'Dispute in review',
      color: 'error',
    };
  }

  const payables = [...job_billings, ...expense_adjustments];
  const isPartiallyPaid = payables.some((payable) => payable.payout?.status === 'paid');
  if (isPartiallyPaid) {
    const isFullyPaid = payables.every((payable) => payable.payout?.status === 'paid');
    return {
      label: isFullyPaid ? 'Paid' : 'Partially paid',
      color: 'success',
    };
  }

  if (status === 'held_for_dispute_review') {
    return {
      label: 'In review',
      color: 'warning',
    };
  }

  return {
    label: 'Pending',
    color: 'info',
  };
};

const cityStateZip = (
  location: Pick<ILocation, 'address_city' | 'address_zip'> & {
    state: Pick<IState, 'abbreviation'>;
  },
) => {
  const city = location.address_city;
  if (city) {
    return `${city}, ${location.state?.abbreviation ?? ''} ${location.address_zip ?? ''}`;
  } else {
    return `${location.state?.abbreviation ?? ''} ${location.address_zip ?? ''}`.trim();
  }
};

export const shiftStartDateTime = (job: IJob) => {
  const tz = job.location.timezone_lookup || 'system';

  const date = job.starts_date
    ? new MedelyDateTime(job.starts_date, { keepLocalTime: true, tz })
    : new MedelyDateTime(job.current_starts_time, { tz });

  const time = jobTime(job, { scheduledShiftStyle: true });
  return `${date.humanizedDate} • ${time}`;
};

const DisplayHelpers = {
  expenseAdjustment: { taxableTypeAsTitle },
  humanizeDate,
  job: {
    date: jobDate,
    paymentStatusLabel: jobPaymentStatusLabel,
    positions,
    shiftType,
    time: jobTime,
    shiftStartDateTime,
  },
  location: {
    cityStateZip,
  },
  titleize,
};

export default DisplayHelpers;
