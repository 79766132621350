import { Box, HStack, VStack } from '@medely/ui-kit';
import React, { ReactElement } from 'react';

type BoxProps = React.ComponentProps<typeof Box>;

type CircularAvatarProps = {
  color: BoxProps['bgcolor'];
  width: BoxProps['width'];
  height: BoxProps['height'];
  children: ReactElement;
};

export const CircularAvatar = ({ children, color, width, height }: CircularAvatarProps) => {
  return (
    <VStack alignItems="center">
      <HStack
        bgcolor={color}
        width={width}
        height={height}
        borderRadius="50px"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </HStack>
    </VStack>
  );
};
