import { ICondition } from '@medely/types';
import { GET_CONDITION } from '../graphql';
import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { useNetworkRequest, GqlRequest } from './useNetworkRequest';

type Return = {
  condition: ICondition;
  isLoading: boolean;
};

const fetchMissingConditions = async (
  queryContext: QueryFunctionContext,
  gqlRequest: GqlRequest,
) => {
  const {
    queryKey: [_entity, id],
  } = queryContext;
  const { condition } = await gqlRequest(GET_CONDITION, { id });
  return condition;
};

export const useCondition = (conditionId: number): Return => {
  const { gqlRequest } = useNetworkRequest();

  const { data, isLoading } = useQuery(['conditions', conditionId], (queryContext) =>
    fetchMissingConditions(queryContext, gqlRequest),
  );
  return {
    condition: data,
    isLoading: isLoading,
  };
};
