import React from 'react';
import { DialogProps } from '@medely/web-components';
import CredentialModal from '../professionalQualificationModal/CredentialModal';
import useI9Qualification from './useI9Qualification';

type Args = DialogProps & {
  qualificationId: number;
};

const MissingI9Dialog = ({ onClose, open, qualificationId }: Args) => {
  const { professionalQualificationId, isLoading } = useI9Qualification(qualificationId);

  return (
    <CredentialModal
      isLoading={isLoading}
      initialMode="edit"
      professionalQualificationId={professionalQualificationId}
      onClose={onClose}
      open={open}
    />
  );
};

export default MissingI9Dialog;
