import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNetworkRequest } from './useNetworkRequest';
import { AxiosResponse } from 'axios';

export const OTHER_REFERRAL_ID = 9;

const usePositionInfoMutations = (): {
  nextStep: () => void;
  prevStep: () => void;
  submitPositionInfo: UseMutateFunction<AxiosResponse<any>, any, any, unknown>;
  isLoading: boolean;
} => {
  const { axios } = useNetworkRequest();
  const queryClient = useQueryClient();

  const { mutate: nextStep, isLoading: nextLoading } = useMutation(() =>
    axios.patch(`/v3/professional/application_wizard/next`),
  );

  const { mutate: prevStep, isLoading: prevLoading } = useMutation(() => {
    return axios.patch(`/v3/professional/application_wizard/back`);
  });

  const { mutate: submitPositionInfo } = useMutation(
    (values) => axios.post(`/v3/professional/positions`, values),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['currentUser']);
      },
    },
  );

  return {
    nextStep,
    prevStep,
    submitPositionInfo,
    isLoading: nextLoading || prevLoading,
  };
};

export default usePositionInfoMutations;
