import { IJob } from '@medely/types';
import { Box, Text } from '@medely/ui-kit';
import React, { ReactElement } from 'react';
import DisplayHelpers from '../../utils/displayHelpers';
import { MedelyDateTime } from '@medely/date-time-tools';
import { CountdownBadge } from './CountdownBadge';

interface IShiftDateProps {
  job: IJob;
  displayHours?: boolean;
  displayStartingSoonBadge?: boolean;
}

export const ShiftDate = ({
  job,
  displayHours = false,
  displayStartingSoonBadge = false,
}: IShiftDateProps): ReactElement => {
  const date = new MedelyDateTime(job.starts_date, {
    tz: job.location?.timezone_lookup ?? 'America/Los_Angeles',
    keepLocalTime: true,
  }).humanizedDate;
  const hours = DisplayHelpers.job.time(job, {
    scheduledShiftStyle: true,
  });
  return (
    <Box testId="shift-date">
      <Text size="m">{`${date} ${displayHours && hours ? ` • ${hours}` : ``}`}</Text>
      <>
        {displayStartingSoonBadge && (
          <Box pt={0.5}>
            <CountdownBadge job={job} />
          </Box>
        )}
      </>
    </Box>
  );
};
