import { TaskCard } from '@medely/ui-kit';
import React from 'react';
import { WhatToExpectModal } from '../whatToExpectModal/WhatToExpectModal';
import useDialog from '@src/hooks/useDialog';
import { TaskCardLayout } from './TaskCard';

export const GetReady = () => {
  const { open, closeDialog, openDialog } = useDialog();

  return (
    <>
      <TaskCardLayout>
        <TaskCard
          iconProps={{ name: 'calendar-check', color: 'primary' }}
          title="Get ready for your shift"
          body="Congratulations on booking a shift with Medely! Learn what to expect from here on out."
          onClick={openDialog}
        />
      </TaskCardLayout>
      <WhatToExpectModal open={open} onClose={closeDialog} />
    </>
  );
};
