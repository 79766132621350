import React from 'react';

import { Box, Heading, Label, List, Text, VStack } from '@medely/ui-kit';
import DisplayHelpers from '../../../utils/displayHelpers';
import { AddressLink } from '../AddressLink';
import { CompletedShiftGeneralInfoProps } from './interface';
import { DescriptionListItem } from '../../DescriptionListItem';
import { ShiftInReviewAlert } from './ShiftInReviewAlert';

const { date, positions, shiftType, time } = DisplayHelpers.job;

export const CompletedShiftGeneralInfo = ({ job }: CompletedShiftGeneralInfoProps) => {
  const jbSums = job.job_billing_sums;
  const isInReview = job.status === 'held_for_dispute_review';

  return (
    <VStack gap={2}>
      <Heading size="s">Shift details</Heading>
      <VStack>
        <Label size="m">{positions(job)}</Label>
        <Box py={1}>
          <Text size="m">{job.location.name}</Text>
          <AddressLink location={job.location} />
        </Box>
      </VStack>
      <Box borderBottomWidth={1} borderColor="controls.backing+line" />
      {isInReview && <ShiftInReviewAlert jobId={job.id} />}
      <List
        items={[
          { label: 'Date', value: date(job, 'short') },
          { label: 'Hours worked', value: jbSums.total_hours },
          {
            label: 'Clock in / clock out',
            value: time(job, { withShiftLength: false, scheduledShiftStyle: true }),
          },
          {
            label: 'Meal break',
            value: !!jbSums.total_breaks_minutes
              ? `${jbSums.total_breaks_minutes} min`
              : 'Not taken',
          },
          { label: 'Shift type', value: shiftType(job) },
          { label: 'Tax type', value: job.is_w2 ? 'W-2' : '1099' },
          { label: 'Job ID', value: job.id },
        ]}
        keyFn={(item) => item.label}
        render={DescriptionListItem}
      />
    </VStack>
  );
};
