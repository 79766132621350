import useProfessionalQualifications from 'hooks/useProfessionalQualifications';
import { sortQualificationAttributes } from 'utils/professionalQualifications';
import type { IQualification } from '@medely/types';
import type { MissingConditionOption } from 'components/credentials/conditionDialogs/useMissingConditionOptions';

const NON_FILLABLE_FIELD_TYPES = ['expiration_date'];

const useMissingConditionFormValues = (option: MissingConditionOption | undefined) => {
  const { allProfessionalQualifications } = useProfessionalQualifications();

  const findExistingQualification = (qualification?: IQualification) =>
    [...(allProfessionalQualifications || [])]
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .find((pq) => pq.qualification.id === qualification?.id);

  if (!option) {
    return { values: option, findExistingQualification };
  }

  const qualifications = option.qualifications.map((q) => {
    const existingProfessionalQualification = findExistingQualification(q);
    if (!existingProfessionalQualification) {
      return q;
    }
    const qualificationAttributes = q.qualification_attributes.map((attribute) => {
      const pqAttribute =
        existingProfessionalQualification.professional_qualification_attributes.find(
          (pqa) => pqa.qualification_attribute_id === attribute.id,
        );
      const value = NON_FILLABLE_FIELD_TYPES.includes(attribute.field_type)
        ? undefined
        : pqAttribute?.value;
      return {
        ...attribute,
        value,
      };
    });
    const sortedQualificationAttributes =
      q.qualification_type === 'q_and_a'
        ? qualificationAttributes
        : qualificationAttributes.sort(sortQualificationAttributes);
    return {
      ...q,
      qualification_attributes: sortedQualificationAttributes,
    };
  });

  return { values: { ...option, qualifications }, findExistingQualification };
};

export default useMissingConditionFormValues;
