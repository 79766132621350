import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useProfessionalQualifications } from '../../../hooks/useProfessionalQualifications';
import { ConditionSatisfactionHelper, SatisfactionOption } from '@medely/credentials-tools';
import type { ICondition, IProfessionalQualification } from '@medely/types';

export type ConditionOption = {
  description: string;
  willBeSatisfied: boolean;
  condition: { qualifications: SatisfactionOption['qualifications'] };
  qualificationStatus: Record<number, IProfessionalQualification['status']>;
};

const calculateMissingOptions = ({
  condition,
  professionalQualifications,
  satisfiedConditionIds,
}: {
  condition: ICondition;
  professionalQualifications: IProfessionalQualification[];
  satisfiedConditionIds: number[];
}): ConditionOption[] => {
  const conditionHelper = new ConditionSatisfactionHelper({
    condition,
    professionalQualifications,
    satisfiedConditionIds,
  });

  return conditionHelper.availableSatisfactionOptions.reduce(
    (acct: any, option: SatisfactionOption) => {
      const qualifications = option.qualifications.map((q) => ({
        ...q.qualification,
        // @ts-ignore
        description: q.description,
      }));
      if (!qualifications.length) {
        return acct;
      }
      const willBeSatisfied = option.qualifications.every((q) => !q.isMissing);
      const qualificationStatus = option.qualifications.reduce<
        Record<number, IProfessionalQualification['status']>
      >((acct, item) => {
        if (!item.satisfyingProfessionalQualifications.length) {
          return acct;
        }

        if (item.satisfyingProfessionalQualifications.some((pq) => pq.status === 'expired')) {
          return { ...acct, [item.qualification.id]: 'expired' };
        }

        if (item.satisfyingProfessionalQualifications.some((pq) => pq.status === 'rejected')) {
          return { ...acct, [item.qualification.id]: 'rejected' };
        }

        if (item.satisfyingProfessionalQualifications.some((pq) => pq.status === 'pending')) {
          return { ...acct, [item.qualification.id]: 'pending' };
        }

        if (item.satisfyingProfessionalQualifications.some((pq) => pq.status === 'review')) {
          return { ...acct, [item.qualification.id]: 'review' };
        }

        return { ...acct, [item.qualification.id]: 'approved' };
      }, {});
      return [
        ...acct,
        {
          description: option.description,
          willBeSatisfied,
          qualificationStatus,
          condition: { qualifications },
        },
      ];
    },
    [],
  );
};

const useMissingConditionOptions = (condition: ICondition) => {
  const { currentUser } = useCurrentUser();
  const { allProfessionalQualifications } = useProfessionalQualifications();

  const options = calculateMissingOptions({
    condition,
    professionalQualifications: allProfessionalQualifications,
    satisfiedConditionIds: currentUser?.professional?.satisfied_condition_ids ?? [],
  });

  return options;
};

export default useMissingConditionOptions;
