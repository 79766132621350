import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { decodeInviteToken } from 'utils/token';
import { useFirebaseContext } from '@medely/ui-kit';
import useCurrentUser from 'hooks/useCurrentUser';

export const useInviteRedirect = () => {
  const { logout } = useFirebaseContext();
  const [{ token }] = useQueryParams({
    token: withDefault(StringParam, ''),
  });
  const location = useLocation();
  const { currentUser } = useCurrentUser();
  const isInvite = useRouteMatch('/invite');

  useEffect(() => {
    if (token && currentUser?.email && isInvite) {
      const { email: inviteEmail } = decodeInviteToken(token);
      if (currentUser.email !== inviteEmail) {
        logout(`/invite?token=${token}`);
      }
    }
  }, [currentUser?.email, location?.pathname, token, isInvite]);
};
