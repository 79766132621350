import { IProfessionalQualification } from '@medely/types';
import useI9 from 'hooks/useI9';
import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RichText,
  Typography,
} from '@medely/web-components';

import {
  FileSignature,
  FileUpload,
  ShieldChecked,
  UserFriends,
} from '@medely/web-components/icons';

import { STATUS_MAP } from 'utils/credentialUtils';

export type I9ModalArgs = {
  onClose?: () => void;
  professionalQualification: IProfessionalQualification;
};

const I9DialogContent = ({ professionalQualification, onClose }: I9ModalArgs) => {
  const { isLoading, showI9Page } = useI9(
    professionalQualification.professional_qualification_attributes[0].id,
  );

  return (
    <>
      <DialogContent>
        <Box mb={2} data-testid="i9-modal">
          <Typography variant="h6" data-testid="i9-modal-qualification-name">
            I-9 Verification
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ color: STATUS_MAP[professionalQualification.status].color }}
            data-testid="i9-modal-qualification-subheading"
          >
            Complete your I-9 paperwork before working W2 jobs at Medely.
          </Typography>
        </Box>
        <Box mb={2} data-testid="i9-modal-instructions">
          <List>
            <ListItem>
              <ListItemIcon>
                <Box pr={3}>
                  <FileUpload size={40} />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <Typography gutterBottom weight="medium">
                  Complete I-9 form and upload documents
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Upload documents and complete the I-9 form for verification.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box pr={3}>
                  <UserFriends size={40} />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <Typography gutterBottom weight="medium">
                  Identify Authorized Agent
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  You will add someone you trust who is over 18 years old who can physically examine
                  your documents, sign, and attest to their validity.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box pr={3}>
                  <FileSignature size={40} />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <Typography gutterBottom weight="medium">
                  Authorized agent review and signs the I-9 form
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Individual you've identified reviews and signs the I-9 form.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Box pr={3}>
                  <ShieldChecked size={40} />
                </Box>
              </ListItemIcon>
              <ListItemText>
                <Typography gutterBottom weight="medium">
                  E-Verify
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  The I-9 will be automatically submitted for E-Verify verification.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>
        <Box mt={2} data-testid="qualification-form-instructions">
          <RichText value={JSON.stringify(professionalQualification.qualification.instructions)} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} data-testid="i9-modal-cancel-button">
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={showI9Page}
          loading={isLoading}
          disabled={isLoading}
          data-testid="i9-modal-sign-button"
        >
          Click here to complete I-9
        </Button>
      </DialogActions>
    </>
  );
};

export default I9DialogContent;
