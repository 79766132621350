import React from 'react';
import { Box, Button } from '@medely/ui-kit';
import useDialog from '@src/hooks/useDialog';
import { PositionsDetailsModal } from '../../../../components/positionsDetailsModal/PositionsDetailsModal';
import { IPosition } from '@medely/types';

export type PositionsDetailsModalButtonProps = {
  positions: IPosition[];
};

export const PositionsDetailsModalButton = ({ positions }: PositionsDetailsModalButtonProps) => {
  const { open, openDialog, closeDialog } = useDialog();

  return (
    <Box>
      <Button variant="text" onClick={() => openDialog()} testId="positions-details-modal-button">
        Learn more about each position
      </Button>
      <PositionsDetailsModal positions={positions} open={open} onClose={closeDialog} />
    </Box>
  );
};
