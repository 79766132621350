import axios from 'axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { type GeocoderResult } from '../constants/places';
const GOOGLE_API = 'https://maps.googleapis.com/maps/api/geocode/json';

type PredictionsQueryResult = {
  data: { results: GeocoderResult[] };
};

type IUseGeocodeRequest = {
  apiKey: string;
  placeId: string;
};

const fetchGeocode = async ({
  queryKey: [_key, { apiKey, placeId }],
}: QueryFunctionContext<[unknown, IUseGeocodeRequest]>) => {
  const {
    data: { results },
  }: PredictionsQueryResult = await axios.get(GOOGLE_API, {
    params: {
      place_id: placeId,
      key: apiKey,
    },
  });

  return results;
};

export const useGeocodeRequest = ({
  apiKey,
  placeId,
}: IUseGeocodeRequest): { results?: GeocoderResult[]; isLoading: boolean } => {
  const enabled = !isEmpty(placeId);

  const {
    data: results,
    isInitialLoading,
    isLoading,
  } = useQuery(['locationSearch', { apiKey, placeId }], fetchGeocode, {
    enabled,
  });

  return { results, isLoading: enabled ? isLoading : isInitialLoading };
};

export default useGeocodeRequest;
