import { IProfessionalQualificationAttribute } from '@medely/types';
import React from 'react';
import { Typography } from '@medely/web-components';
import useFetchState from 'hooks/useFetchState';

type StateProps = {
  attribute: IProfessionalQualificationAttribute;
};

const State = ({ attribute }: StateProps) => {
  const { state } = useFetchState(+attribute.value);
  return (
    <Typography variant="body2" color="textSecondary">
      {state?.name}
    </Typography>
  );
};

export default State;
