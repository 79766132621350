import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Link,
  Alert,
  AlertTitle,
} from '@medely/web-components';
import { Alert as UIKitAlert, Typography } from '@medely/ui-kit/web';
import { useFirebaseContext, useConfigurationContext } from '@medely/ui-kit';
import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router';
import { compose, email, password, required } from 'utils/validation';
import { Logo } from 'components/Logo';
import { StringParam, useQueryParams } from 'use-query-params';

const emailValidator = compose(required, email);

export default function Login() {
  const { login } = useFirebaseContext();
  const history = useHistory();
  const handleForgot = () => history.push(`/reset-password${history.location.search}`);
  const config = useConfigurationContext();
  const [error, setError] = useState<string | undefined>(undefined);
  const [{ mobileToken }] = useQueryParams({
    mobileToken: StringParam,
  });
  const hasInvalidMobileToken = !!mobileToken;

  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      setError(undefined);
      await login(values);
      const urlParams = new URLSearchParams(history.location.search);
      const hasRedirect = urlParams.has('redirect');

      if (hasRedirect) {
        const params = urlParams.get('params');
        const url = params
          ? `${urlParams.get('redirect').concat(params)}`
          : `${urlParams.get('redirect')}`;
        location.replace(url);

        return;
      }

      location.replace('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box pt={2} pb={2}>
          <Link href={`${config.landingsUrl}/login`} target="_blank" rel="noopener noreferrer">
            <Logo width="130px" height="43px" />
          </Link>
        </Box>
      </Container>
      <Box mt={5} mb={5} textAlign={'center'}>
        <Typography variant="heading" size="l" color="text.primary">
          Professional Log In
        </Typography>
      </Box>
      <Container maxWidth="xs">
        {hasInvalidMobileToken && (
          <Box pb={2}>
            <UIKitAlert
              title="Session expired"
              severity="warning"
              variant="filled"
              description="Please log in again to continue."
            />
          </Box>
        )}
        <Card variant="outlined">
          <Box p={2}>
            <Form onSubmit={handleSubmit}>
              {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid data-testid="login-email" item xs={12}>
                      <Field name="email" validate={emailValidator}>
                        {({ input, meta }) => (
                          <TextField
                            type="email"
                            label="Email"
                            fullWidth
                            {...input}
                            error={meta.touched && meta.invalid}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid data-testid="login-password" item xs={12}>
                      <Field name="password" validate={password}>
                        {({ input, meta }) => (
                          <TextField
                            label="Password"
                            type="password"
                            fullWidth
                            {...input}
                            error={meta.touched && meta.invalid}
                            helperText={meta.touched && meta.error}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field type="checkbox" name="person[remember_me]">
                        {({ input }) => (
                          <FormControlLabel
                            control={<Checkbox color="primary" {...input} />}
                            label="Remember&nbsp;me"
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Box textAlign="right">
                        <Button
                          data-testid="login-forgot-password-button"
                          onClick={handleForgot}
                          size="small"
                        >
                          Forgot&nbsp;Password?
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="large"
                        fullWidth
                        disabled={submitting}
                      >
                        Log in
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
            {Boolean(error) && (
              <Box pt={2}>
                <Alert sx={{ backgroundColor: '#FFDAD4', color: '#85251C' }} severity="error">
                  <AlertTitle>{error}</AlertTitle>
                </Alert>
              </Box>
            )}
          </Box>
        </Card>
      </Container>
    </>
  );
}
