import { IProfessionalQualificationAttribute } from '@medely/types';
import React from 'react';
import { Typography } from '@medely/web-components';

type DefaultAttributeProps = {
  attribute: Partial<IProfessionalQualificationAttribute>;
};

const DefaultAttribute = ({ attribute }: DefaultAttributeProps) => (
  <Typography variant="body2" color="textSecondary" data-testid="default-attribute-value">
    {attribute.value}
  </Typography>
);

export default DefaultAttribute;
