import { Box, Button, Dialog, DialogProps, Typography } from '@medely/web-components';
import React from 'react';

type Props = DialogProps & {
  qualificationName: string;
};

const AdminOnlyQualificationDialog = ({ open, onClose, qualificationName }: Props) => (
  <Dialog
    open={open}
    onClose={onClose}
    title="Missing Credential"
    content={
      <>
        <Typography gutterBottom>{qualificationName}</Typography>
        <Typography variant="body2">
          This credential will be verified by Medely. Please contact support if you have any
          questions.
        </Typography>
      </>
    }
    actions={
      <Box display="flex" mt={2}>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </Box>
    }
  />
);

export default AdminOnlyQualificationDialog;
