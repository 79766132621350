export const EMPLOYMENT_TYPE_OPTIONS = [
  { value: 0, label: 'Per diem' },
  { value: 1, label: 'Full Time' },
  { value: 2, label: 'Part Time' },
  { value: 3, label: 'Travel contract' },
  { value: 4, label: 'Internship' },
];

export const PATIENT_CARE_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
