import React from 'react';

import { EmptyState } from '../EmptyState';
import { LearnAboutPayoutsLink } from './LearnAboutPayoutsLink.web';

type PayoutsListProps = {
  month: Date;
};

export const PayoutsListEmptyState = ({ month }: PayoutsListProps) => {
  return (
    <EmptyState
      title={`No ${month.toLocaleString('default', { month: 'long' })} payouts`}
      description={
        'Per diem payouts are processed on Mondays, Wednesdays, or Fridays unless held for review. Assignment timesheets are typically processed on Tuesday for shifts in the previous week.'
      }
      linkComponent={<LearnAboutPayoutsLink />}
    />
  );
};
