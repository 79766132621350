import { gql } from 'graphql-request';

export const GET_TODOS = gql`
  query ProfessionalTodos {
    professionalTodos {
      campaigns_status
      available_for_extensions
      active_jobs_requesting_to_work_facility_ids
      has_position_with_completed_onboarding
      assignments_missing_conditions
      assignment_offers
      extension_offers
      days_until_debit_card_expires
      booked_jobs_count
      finished_jobs_count
      unsigned_addendum_assignment_ids
      missing_premier_credentials
      missing_w2_per_diem_onboarding
      disputes_waiting_on_professional
    }
  }
`;
