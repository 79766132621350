import { CircularProgress, HStack } from '@medely/ui-kit';
import React from 'react';

export const LoadingState = () => {
  return (
    <HStack justifyContent="center" alignItems="center">
      <CircularProgress />
    </HStack>
  );
};
