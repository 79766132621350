import React from 'react';
import moment from 'moment';
import { DxGyFinishCard } from './DxGyFinishCard';
import { DxGyInProgressCard } from './DxGyInProgressCard';
import { DxGyPreStartCard } from './DxGyPreStartCard';
import { centsToCurrency } from '../../../utils';

type DataProps = {
  slug: string;
  starts_time: string | Date;
  ends_time: string | Date;
  amount_cents: number;
  goal: {
    per_diem_shifts: number;
  };
  progress: {
    per_diem_shifts: number;
  };
};

type DxGyCardProps = {
  data: DataProps;
};

export const DxGyCard = ({ data }: DxGyCardProps) => {
  const today = moment();
  const startShowCards = moment(data.starts_time).subtract(3, 'days');
  const stopShowInProgressCards = moment(data.ends_time).add(1, 'days');
  const stopShowFinishCard = moment(data.ends_time).add(3, 'days');
  const completedCampaign = data.progress?.per_diem_shifts >= data.goal.per_diem_shifts;
  const isInProgress = data.progress?.per_diem_shifts >= 1;
  const startTime = moment(data.starts_time).format('MM/DD');
  const endTime = moment(data.ends_time).format('MM/DD');
  const amountCents = centsToCurrency(data.amount_cents);
  const goal = data.goal.per_diem_shifts.toString();
  const progress = data.progress.per_diem_shifts.toString();

  if (completedCampaign && today.isSameOrBefore(stopShowFinishCard, 'day')) {
    return <DxGyFinishCard amount={amountCents} goal={goal} />;
  }
  if (
    today.isSameOrAfter(startShowCards, 'day') &&
    today.isSameOrBefore(stopShowInProgressCards, 'day')
  ) {
    return isInProgress ? (
      <DxGyInProgressCard endTime={endTime} goal={goal} amount={amountCents} progress={progress} />
    ) : (
      <DxGyPreStartCard startTime={startTime} endTime={endTime} amount={amountCents} goal={goal} />
    );
  } else {
    return null;
  }
};
