import { Box, Button, DialogActions, DialogContent, Typography } from '@medely/web-components';
import { IProfessionalQualification, IQualification } from '@medely/types';
import React, { ReactElement, useRef } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { ProfessionalQualificationDialogMode } from './ProfessionalQualificationModal';
import QualificationFormFields from '../QualificationFormFields';
import { sortQualificationAttributes } from 'utils/professionalQualifications';
import { STATUS_MAP } from 'utils/credentialUtils';
import { FieldQualificationAttribute } from '../attributeFields';
import { getValidQualificationAttributes } from '../utils';

type InitialValues = {
  id: number;
  qualification: Partial<IQualification> & {
    qualification_attributes: FieldQualificationAttribute[];
  };
};

export const buildInitialValues = (
  professionalQualification: IProfessionalQualification,
): InitialValues => {
  const attributes = getValidQualificationAttributes(
    professionalQualification.qualification.qualification_attributes,
  )
    .map((qa) => {
      const pqAttribute = professionalQualification.professional_qualification_attributes?.find(
        (pqa) => pqa.qualification_attribute_id === qa.id,
      );

      return {
        ...qa,
        professional_qualification_attribute_id: pqAttribute?.id,
        value: pqAttribute?.value,
      };
    })
    .sort(sortQualificationAttributes);

  return {
    qualification: {
      ...professionalQualification.qualification,
      qualification_attributes: attributes,
    },
    id: professionalQualification.id,
  };
};

type Props = {
  professionalQualification: IProfessionalQualification;
  setMode: (mode: ProfessionalQualificationDialogMode) => void;
  onSubmit: (values: unknown) => void;
  isLoading: boolean;
  updateForm?: boolean;
};

const ProfessionalQualificationModalEditContent = ({
  professionalQualification,
  setMode,
  onSubmit,
  isLoading,
  updateForm = false,
}: Props): ReactElement => {
  const initialValues = useRef(buildInitialValues(professionalQualification));

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues.current} mutators={{ ...arrayMutators }}>
      {({ handleSubmit, submitting, invalid }) => (
        <>
          <DialogContent>
            <Box mb={2} data-testid="credential-modal-edit-content">
              <Typography
                gutterBottom
                weight="medium"
                data-testid="credential-modal-edit-content-qualification-name"
              >
                {professionalQualification.qualification.long_name}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  color:
                    STATUS_MAP[professionalQualification?.status]?.color ??
                    STATUS_MAP['loading'].color,
                }}
                data-testid="credential-modal-edit-content-qualification-status"
              >
                {STATUS_MAP[professionalQualification?.status]?.label ??
                  STATUS_MAP['loading'].label}
              </Typography>
            </Box>
            <QualificationFormFields
              name="qualification"
              qualificationId={professionalQualification.qualification.id}
              preserveInitialValues
              updateForm={updateForm}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setMode('view');
              }}
              data-testid="credential-modal-edit-content-cancel-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              loading={isLoading}
              disabled={submitting || invalid || isLoading}
              data-testid="credential-modal-edit-content-save-button"
            >
              Save
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
};

export default ProfessionalQualificationModalEditContent;
