import { OptionType } from '@medely/ui-kit';
import { graphql } from '../../../../graphql/generated';
import { useGraphQLQuery } from '../../../../hooks/useGraphQLRequest';
import { type ResultOf } from '@graphql-typed-document-node/core';

const LocationTypesGql = graphql(`
  query GetLocationTypesForWorkExperience($input: LocationTypeQueryInput) {
    locationTypes(input: $input) {
      id
      label
    }
  }
`);

const CurrentUserGql = graphql(`
  query GetCurrentUserForWorkExperience($id: Int!, $location_ids: [Int!]!) {
    me {
      id
      professional {
        professional_positions {
          position {
            id
            display_name
          }
          has_recent_experience
          years_of_experience
        }
        markets {
          id
        }
      }
      ...WorkExperiencePrimaryFormCurrentUser
      ...AddMoreMonthsOfWorkExperienceCurrentUser
      ...WorkExperienceFormCurrentUser
      ...UseWorkExperienceCurrentUser
      ...EditWorkExperienceFormModalInfoCurrentUser
    }
  }
`);

const WorkHistoriesGql = graphql(`
  query GetWorkHistoryForWorkExperience($input: WorkHistoryQueryInput) {
    workHistories(input: $input) {
      id
      bedside_care
      currently_work_here
      employment_type
      location_type_id
      end_date
      bedside_care
      start_date
      bedside_care
      position {
        id
        display_name
      }
      wage_logs {
        hourly_rate_cents
      }
      ...AddMoreMonthsOfWorkExperienceWorkHistory
      ...UseWorkExperienceWorkHistory
    }
  }
`);

export const useGetWorkExperienceInitialData = () => {
  const { data: locationTypesData, isLoading: locationTypesLoading } = useGraphQLQuery<
    ResultOf<typeof LocationTypesGql>
  >({
    operationName: 'GetLocationTypesForWorkExperience',
    query: LocationTypesGql,
    variables: {
      input: {
        filter: {
          active: true,
          admin_only: false,
        },
        orderBy: {
          rank: true,
        },
      },
    },
  });

  const locationTypes = locationTypesData?.locationTypes ?? [];

  const locationTypeOptions: OptionType[] = locationTypes.map((location) => ({
    value: location.id,
    label: location.label ?? String(location.id),
  }));

  const { data: currentUserData, isLoading: currentUserLoading } = useGraphQLQuery<
    ResultOf<typeof CurrentUserGql>
  >({
    operationName: 'GetCurrentUserForWorkExperience',
    query: CurrentUserGql,
    variables: {},
  });

  const currentUser = currentUserData?.me;

  const { data: workHistoryData, isLoading: workHistoryLoading } = useGraphQLQuery<
    ResultOf<typeof WorkHistoriesGql>
  >({
    operationName: 'GetWorkHistoryForWorkExperience',
    query: WorkHistoriesGql,
    variables: {},
  });

  const workHistories = workHistoryData?.workHistories ?? [];

  return {
    currentUser,
    locationTypeOptions,
    workHistories,
    locationTypesLoading,
    currentUserLoading,
    workHistoryLoading,
  };
};
