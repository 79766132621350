import { Field } from 'react-final-form';
import React from 'react';
import StateIdSelect from 'components/StateIdSelect';
import { Typography } from '@medely/web-components';
import { required } from 'core/utils/validation';
import useStates from 'hooks/useStates';

const State = ({
  name,
  isRequired,
  withNameLabel,
  label,
}: {
  name: string;
  isRequired: boolean;
  withNameLabel?: boolean;
  label: string;
}): React.ReactElement | null => {
  const { states } = useStates();
  const options = states.map(({ id, name }) => ({ value: id, label: name }));
  if (!options.length) {
    return null;
  }
  return (
    <>
      {withNameLabel && <Typography gutterBottom>{label}</Typography>}
      <Field name={`${name}.value`} validate={isRequired ? required : undefined}>
        {({ input, meta }) => (
          <StateIdSelect
            value={input.value}
            onChange={input.onChange}
            error={meta.touched && meta.invalid}
            helperText={meta.touched && meta.error}
          />
        )}
      </Field>
    </>
  );
};

export default State;
