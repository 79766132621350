import React from 'react';

import { IExpenseAdjustment } from '@medely/types';
import { HStack, Icon, Label, Text, VStack } from '@medely/ui-kit';
import DisplayHelpers from '../../utils/displayHelpers';
import { centsToCurrency } from '../../utils';

const { taxableTypeAsTitle } = DisplayHelpers.expenseAdjustment;

type AdjustmentListItemProps = {
  adjustment: Pick<IExpenseAdjustment, 'amount_cents' | 'created_at' | 'id' | 'taxable_type'>;
  onClick?: () => void;
};

export const AdjustmentListItem = ({ adjustment, onClick }: AdjustmentListItemProps) => {
  return (
    <HStack py={2} alignItems="center" justifyContent="space-between" flex={1} onClick={onClick}>
      <VStack>
        <Label size="m">{DisplayHelpers.humanizeDate(adjustment.created_at)}</Label>
        <Text size="m" color="text.secondary">
          {taxableTypeAsTitle(adjustment.taxable_type)}
        </Text>
      </VStack>
      <HStack ml={2} alignItems="center">
        <Text size="m" color="text.primary">
          {centsToCurrency(adjustment.amount_cents)}
        </Text>
        <Icon name="chevron-right" />
      </HStack>
    </HStack>
  );
};
