import React from 'react';
import { DxGyCampaignContainer } from './DxGyCampaignContainer';

type DxGyPreStartCardProps = {
  startTime: string;
  endTime: string;
  amount: string;
  goal: string;
};

export const DxGyPreStartCard = ({ startTime, endTime, amount, goal }: DxGyPreStartCardProps) => {
  return (
    <DxGyCampaignContainer
      title={`Complete ${goal} shifts, earn ${amount}!`}
      body={`Complete ${goal} shifts from ${startTime} to ${endTime} and receive an extra ${amount}.`}
    />
  );
};
