import { StringValueNode } from 'graphql/language/ast';
import jwtDecode from 'jwt-decode';

export const decodeInviteToken = (token: string) =>
  jwtDecode<{
    company_name: string;
    email: string;
    inviter_first_name: StringValueNode;
    inviter_last_name: string;
    new_account_entity_type: string;
    new_account_first_name: string;
    new_account_last_name: string;
    token: string;
  }>(token);
