import React from 'react';
import { LoadingState } from '../../../components/LoadingState';
import { Box, Form, Heading, SubmitButton } from '@medely/ui-kit';
import { useWorkExperience } from '../useWorkExperience';
import { useGetWorkExperienceInitialData } from '../useGetWorkExperienceInitialData';
import { createWorkExperienceEditValidation } from '../workExperienceSchema';
import { WorkExperienceForm } from '../WorkExperienceForm';
import useWorkExperienceInfoMutations from '../../../../../hooks/useWorkExperienceInfoMutations';
import { FragmentType, graphql, parseFragmentData } from '../../../../../graphql/generated';
import { ValuesProps } from '../types';

const EditWorkExperienceFormModalInfoCurrentUserGql = graphql(`
  fragment EditWorkExperienceFormModalInfoCurrentUser on Account {
    id
    professional {
      professional_positions {
        has_recent_experience
        position {
          display_name
          id
        }
      }
    }
    ...WorkExperienceFormCurrentUser
    ...UseWorkExperienceCurrentUser
  }
`);

type EditWorkExperienceFormInfoProps = {
  currentUser?: FragmentType<typeof EditWorkExperienceFormModalInfoCurrentUserGql>;
  onClose: () => void;
  workHistoryId: number | undefined;
};

export const EditWorkExperienceFormInfo = ({
  currentUser: inputCurrentUser,
  onClose,
  workHistoryId,
}: EditWorkExperienceFormInfoProps) => {
  const currentUser = parseFragmentData(
    EditWorkExperienceFormModalInfoCurrentUserGql,
    inputCurrentUser,
  );

  const { locationTypesLoading, workHistories } = useGetWorkExperienceInitialData();

  const workHistory = workHistories?.find((w) => w.id === workHistoryId);

  const { editWorkExperienceInfo } = useWorkExperienceInfoMutations();

  const { createEditPayload, getInitialWorkExperienceValues, totalYearsOfExperience } =
    useWorkExperience({ currentUser });

  const baseValuesLoading = locationTypesLoading;

  const positionId = workHistory?.position?.id;

  const positionName = workHistory?.position?.display_name;

  // @ts-ignore
  const defaultValues = getInitialWorkExperienceValues(workHistory);

  const handleSubmit = (values: ValuesProps) => {
    const parsedValues = createEditPayload({
      ...values,
      position_id: positionId,
      id: workHistory?.id,
    });
    editWorkExperienceInfo(parsedValues, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  if (baseValuesLoading) {
    return <LoadingState />;
  }

  return (
    <>
      <Form
        handleSubmit={handleSubmit}
        schema={createWorkExperienceEditValidation(totalYearsOfExperience)}
        defaultValues={defaultValues}
      >
        <Box pb={2}>
          <Heading size="s" color="text.primary">
            Edit {positionName} experience
          </Heading>
        </Box>
        <WorkExperienceForm
          currentUser={currentUser}
          positionId={positionId}
          hidePositionField={true}
          hideButtonToSummary={true}
        />
        <SubmitButton fullWidth>Save</SubmitButton>
      </Form>
    </>
  );
};
